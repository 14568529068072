.card.card-work {
 border-radius: $border-radius * 2;
 min-height: 324px;
 > a {
 background-position: center;
 background-size: cover;
 background-repeat: no-repeat;
 display: flex;
 flex: 1;
 &:hover {
 text-decoration: none;
 opacity: 0.7;
 }
 }
 .card-content {
 flex: 0;
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 align-items: flex-start;
 padding: 20px;
 @include media-breakpoint-up(md) {
 justify-content: space-between;
 align-items: center;
 flex-direction: row;
 }
 .card-title {
 margin-bottom: 5px;
 }
 .card-client {
 font-weight: 700;
 color: var(--color-base-bg-2);
 }
 }
}
