.card {
 display: flex;
 flex-direction: column;
 height: 100%;
 padding: 0;
 overflow: hidden;
 position: relative;
 .card-thumbnail {
 border-radius: $card-border-radius;
 overflow: hidden;
 display: inline-flex;
 a {
 display: inline-flex;
 }
 img {
 aspect-ratio: 16 / 9;
 width: 100%;
 height: auto;
 object-fit: contain;
 &:hover {
 opacity: 0.8;
 }
 }
 &.card-thumbnail-large {
 @include media-breakpoint-up(md) {
 max-height: 260px;
 }
 @include media-breakpoint-up(xl) {
 max-height: 300px;
 }
 }
 }
 .card-icon {
 width: 40px;
 margin-bottom: 10px;
 display: flex;
 justify-content: center;
 i {
 font-size: 40px;
 color: white;
 }
 img {
 width: 100%;
 height: auto;
 }
 }
 &.card-has-thumbnail.card-has-icon {
 .card-icon {
 position: absolute;
 top: 20px;
 right: 30px;
 img {
 filter: brightness(0) invert(1);
 }
 }
 }
 .card-content {
 padding-top: 20px;
 display: flex;
 flex: 1;
 flex-direction: column;
 }
 .card-client {
 font-weight: 700;
 color: var(--color-base-bg-2);
 }
 .card-title {
 margin-bottom: 10px;
 h3 {
 font-weight: 500;
 font-size: 24px;
 }
 }
 .card-description {
 margin: 10px 0 10px 0;
 p {
 margin: 0;
 line-height: 1.5;
 color: var(--color-text-2);
 }
 }
 .card-title + .card-description {
 margin-top: 0;
 }
 .card-readmore {
 margin-top: auto;
 line-height: 1;
 i {
 margin-left: 5px;
 margin-top: 4px;
 }
 }
 .card-date {
 display: flex;
 justify-content: flex-start;
 align-items: center;
 font-size: 14px;
 font-weight: bold;
 }
 .card-categories {
 display: inline-flex;
 font-size: 12px;
 font-family: $font-family-base;
 a {
 color: var(--color-primary-text);
 padding: 4px 8px;
 border-radius: 3px;
 margin-right: 10px;
 background-color: var(--color-primary-bg);
 display: inline-block;
 }
 }
 .card-image {
 width: 60px;
 margin-bottom: 10px;
 img {
 max-width: 100%;
 height: auto;
 }
 }
 .card-meta {
 display: flex;
 .on {
 margin: 0 4px 0 4px;
 }
 }
 .card-authors {
 margin-right: 7px;
 display: flex;
 }
 // Tweaks for dark mode
 html[data-bs-theme="dark"] & {
 background-color: var(--color-base-bg);
 .card-icon {
 img {
 filter: invert(1);
 }
 }
 }
}
.card.card-row {
 border-radius: 0;
 box-shadow: none;
 @include media-breakpoint-up(md) {
 flex-direction: row;
 }
 .card-thumbnail {
 @include media-breakpoint-up(md) {
 min-height: auto;
 padding: 0;
 margin-bottom: 10px;
 width: 200px;
 margin-right: 30px;
 margin-bottom: 0;
 a {
 background-color: var(--color-base-bg);
 }
 img {
 border-radius: $card-border-radius;
 }
 }
 }
 .card-content {
 @include media-breakpoint-up(md) {
 padding: 0;
 }
 }
}
