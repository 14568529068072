.pagination {
 display: flex;
 justify-content: center;
 list-style: none;
 margin: 10px 0 10px 0;
 padding: 0;
 ul {
 display: flex;
 li {
 list-style: none;
 text-decoration: none;
 border: 1px solid var(--color-primary-bg);
 border-right: none;
 text-align: center;
 vertical-align: middle;
 a,
 span {
 padding: 8px 12px;
 display: block;
 color: var(--color-primary-bg);
 text-decoration: none;
 &:hover {
 background-color: var(--color-primary-bg);
 opacity: 0.8;
 color: var(--color-primary-text);
 }
 }
 &.active {
 a,
 span {
 background-color: var(--color-primary-bg);
 color: var(--color-primary-text);
 }
 }
 &:last-of-type {
 border-top-right-radius: 3px;
 border-bottom-right-radius: 3px;
 border-right: 1px solid var(--color-primary-bg);
 }
 &:first-of-type {
 border-top-left-radius: 3px;
 border-bottom-left-radius: 3px;
 }
 }
 }
}
.pagination-count {
 margin-bottom: 20px;
 h3 {
 font-size: 16px;
 font-weight: 700;
 line-height: 1.3;
 letter-spacing: -0.2px;
 margin-bottom: 6px;
 }
}
