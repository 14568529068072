// scss-docs-start stacks
.hstack {
 display: flex;
 flex-direction: row;
 align-items: center;
 align-self: stretch;
}

.vstack {
 display: flex;
 flex: 1 1 auto;
 flex-direction: column;
 align-self: stretch;
}
// scss-docs-end stacks
