form {

 legend.form-required,
 label.form-required {
 &::after {
 content: "*";
 color: red;
 margin-left: 0.1rem;
 }
 }

 &.loan-calculator {
 label {
 &.monthly-cost {
 font-weight: $font-weight-semibold;
 font-family: $headings-font-family;
 font-style: $headings-font-style;
 margin-bottom: 0;
 }
 }

 input {
 &.form-control-plaintext {
 &.centered {
 text-align: center;
 }
 }
 }

 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
 /* display: none; <- Crashes Chrome on hover */
 -webkit-appearance: none;
 margin: 0;
 /* <-- Apparently some margin are still there even though it's hidden */
 }

 input[type=number] {
 -moz-appearance: textfield;
 /* Firefox */
 }

 input[type="range"]::-webkit-slider-thumb {
 background: var(--color-primary-bg);
 -webkit-transition: all 0.15s ease;
 transition: all 0.15s ease;
 }

 input[type="range"]::-ms-thumb {
 background: var(--color-primary-bg);
 -webkit-transition: all 0.15s ease;
 transition: all 0.15s ease;
 }

 input[type="range"]::-moz-range-thumb {
 background: var(--color-primary-bg);
 -webkit-transition: all 0.15s ease;
 transition: all 0.15s ease;
 }

 // Make contact information part fields of form 100% width
 .contact-information {
 display: none;

 input[type=text],
 input[type=tel],
 input[type=email] {
 width: 100%;
 }
 }
 }
}