.plans {
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 justify-content: space-evenly;
 border: 1px solid var(--color-base-bg-3);
 border-radius: 20px;
 @include media-breakpoint-up(md) {
 }
}
.plan {
 font-family: var(--font-family-heading);
 border: 1px solid var(--color-base-bg-3);
 margin-bottom: 40px;
 @include media-breakpoint-up(md) {
 flex: 1;
 margin-bottom: 0;
 border: none;
 border-right: 1px solid var(--color-base-bg-3);
 }
 &:last-of-type {
 border-right: none;
 }
 .plan-main {
 padding: 30px 10px;
 text-align: center;
 border-bottom: 1px solid var(--color-base-bg-3);
 .plan-name {
 font-size: 30px;
 line-height: 40px;
 font-weight: 400;
 margin-bottom: 16px;
 }
 .plan-description {
 font-size: 16px;
 line-height: 26px;
 color: var(--color-base-text-2);
 margin-bottom: 20px;
 @include media-breakpoint-up(md) {
 height: 110px;
 }
 }
 .plan-price {
 margin-bottom: 10px;
 @include media-breakpoint-up(md) {
 min-height: 90px;
 }
 .plan-price-currency-symbol {
 font-size: 24px;
 line-height: 28px;
 vertical-align: top;
 display: inline-block;
 }
 .plan-price-number {
 font-size: 42px;
 line-height: 50px;
 letter-spacing: -1px;
 display: inline-block;
 }
 .plan-price-currency {
 font-size: 20px;
 line-height: 26px;
 display: inline-block;
 }
 .plan-price-hint {
 display: block;
 color: var(--color-base-bg-2);
 font-size: 14px;
 }
 }
 }
 .plan-features {
 padding: 30px 20px;
 text-align: left;
 .plan-features-heading {
 font-size: 20px;
 line-height: 1.3;
 margin-bottom: 14px;
 }
 .plan-features-list {
 color: var(--color-base-text-2);
 ul {
 li {
 padding: 6px 0 6px 0;
 vertical-align: middle;
 line-height: 22px;
 svg {
 width: 16px;
 display: inline;
 vertical-align: middle;
 }
 span {
 padding-left: 10px;
 display: inline;
 vertical-align: middle;
 }
 }
 }
 }
 }
 &.plan-featured {
 background: var(--color-primary-bg-2);
 color: var(--color-primary-text);
 .plan-main {
 .plan-description {
 color: var(--color-primary-text-2);
 }
 }
 .plan-features {
 color: var(--color-primary-text-2);
 .plan-features-list {
 color: var(--color-primary-text-2);
 }
 }
 }
}
