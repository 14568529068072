:root {
 --font-family-base: 'Proxima Nova', sans-serif;
 --font-family-heading: Proxima Nova, sans-serif;
 --font-family-monospace: 'Proxima Nova', monospace;
 --font-family-logo: Proxima Nova, sans-serif;

 --color-primary-bg: #525b92;
 --color-primary-bg-2: #96c3bb;
 --color-primary-bg-3: #96c3bb;
 --color-primary-text: #fdfcf9;
 --color-base-bg: #ffffff;
 --color-base-bg-2: #f8f9ff;
 --color-base-bg-3: #eef2f9;
 --color-base-text: #202020;
 --color-base-text-2: #4b5563;
 --color-header-text: #0d042b;
 
 --logo-text-font-size: 28px;
 --logo-text-font-weight: 500;

 --menu-font-family: 'Proxima Nova', sans-serif;
 --menu-font-size: ;
 --menu-text-transform: "Capitalize";
 --menu-font-weight: 400;

 --header-height: 60px;
 --section-padding: 80px;
 --card-border-radius: 5px;
 --button-border-radius: 5px;
}

html[data-bs-theme='dark'] {
 --color-primary-bg: #2b3af7;
 --color-primary-bg-2: #424ff8;
 --color-primary-bg-3: #702bf7;
 --color-primary-text: #fdfcf9;
 --color-base-bg: #0f0f10;
 --color-base-bg-2: #1a191c;
 --color-base-bg-3: #222124;
 --color-base-text: #F4F4F5;
 --color-base-text-2: #D1D5DB;
 --color-logo-text: #2b3af7;
 --color-header-text: #f5f5ec;
}

@import 'style';