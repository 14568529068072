.partners {
 img {
 filter: grayscale(100%);
 max-width: 100%;
 max-height: 30px;
 @include media-breakpoint-up(md) {
 width: auto;
 max-width: 120px;
 max-height: 50px;
 }
 html[data-bs-theme="dark"] & {
 filter: grayscale(100%) invert(100%);
 }
 }
}
