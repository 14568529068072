h1,
.h1 {
 font-family: var(--font-family-heading);
 font-size: 42px;
 line-height: 1.2;
 font-weight: normal;
 margin: 0;
 @include media-breakpoint-up(md) {
 font-weight: normal;
 font-size: 48px;
 }
}
h2,
.h2 {
 font-family: var(--font-family-heading);
 font-size: 32px;
 line-height: 1.2;
 font-weight: normal;
 margin: 0;
 @include media-breakpoint-up(md) {
 font-size: 34px;
 line-height: 1.4;
 font-weight: normal;
 }
}
h3,
.h3 {
 font-family: var(--font-family-heading);
 font-size: 24px;
 line-height: 1.4;
 font-weight: normal;
 margin: 0;
 @include media-breakpoint-up(md) {
 font-size: 24px;
 }
}
h4,
.h4 {
 font-family: var(--font-family-heading);
 font-size: 20px;
 line-height: 1.4;
 font-weight: normal;
 margin: 0;
 @include media-breakpoint-up(md) {
 font-size: 20px;
 }
}
h5,
.h5 {
 font-family: var(--font-family-heading);
 font-size: 18px;
 line-height: 1.3;
 font-weight: bold;
 margin: 0;
 @include media-breakpoint-up(md) {
 font-size: 18px;
 }
}
p {
 font-family: var(--font-family-base);
 line-height: 1.5;
}
a {
 text-decoration: none;
 color: var(--color-primary-bg);
 &:hover {
 text-decoration: underline;
 }
}
ul,
ol {
 list-style: none;
 margin: 0;
 padding: 0;
 li {
 padding: 0;
 margin: 0;
 }
}
img {
 margin: 0;
 padding: 0;
 vertical-align: bottom;
}
