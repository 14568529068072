.bottom {
 background-color: var(--color-base-bg-2);
 color: var(--color-base-text);
 padding-top: calc(var(--section-padding) * 0.5);
 padding-bottom: calc(var(--section-padding) * 0.5);
 font-size: 13px;
 a.feed {
 display: inline-block;
 border-radius: var(--button-border-radius);
 &:hover {

 }
 }
 a {
 display: inline-block;
 color: inherit;
 text-decoration: underline;
 padding: 3px 0;
 @include media-breakpoint-up(md) {
 padding: 6px;
 }
 &:hover {
 text-decoration: underline;
 }
 }
 .copyright {
 padding: 3px 0;
 @include media-breakpoint-up(md) {
 display: inline-block;
 padding: 0;
 }
 }
 .menu-bottom {
 @include media-breakpoint-up(md) {
 display: inline-block;
 }
 ul {
 list-style: none;
 margin: 0;
 padding: 0;
 li {
 margin: 0;
 padding: 0;
 display: block;
 @include media-breakpoint-up(md) {
 display: inline-block;
 }
 a {
 display: inline-block;
 color: inherit;
 text-decoration: underline;
 padding: 3px 0;
 @include media-breakpoint-up(md) {
 padding: 6px;
 }
 &:hover {
 text-decoration: underline;
 }
 }
 }
 }
 }
}
