@import "forms";
@import "card-panels";

.hero {
 .hero-text h1 {
 @include media-breakpoint-down(md) {
 font-size: 44px;
 }
 }
}

.main-menu {
 @include media-breakpoint-up(lg) {
 display: block;
 }
}