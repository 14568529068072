// variables
// --------------------------

$fa-css-prefix : fa !default;
$fa-style : 900 !default;
$fa-style-family : "Font Awesome 6 Free" !default;

$fa-display : inline-block !default;

$fa-fw-width : fa-divide(20em, 16);
$fa-inverse : #fff !default;

$fa-border-color : #eee !default;
$fa-border-padding : .2em .25em .15em !default;
$fa-border-radius : .1em !default;
$fa-border-style : solid !default;
$fa-border-width : .08em !default;

$fa-size-scale-2xs : 10 !default;
$fa-size-scale-xs : 12 !default;
$fa-size-scale-sm : 14 !default;
$fa-size-scale-base : 16 !default;
$fa-size-scale-lg : 20 !default;
$fa-size-scale-xl : 24 !default;
$fa-size-scale-2xl : 32 !default;

$fa-sizes: (
 "2xs" : $fa-size-scale-2xs,
 "xs" : $fa-size-scale-xs,
 "sm" : $fa-size-scale-sm,
 "lg" : $fa-size-scale-lg,
 "xl" : $fa-size-scale-xl,
 "2xl" : $fa-size-scale-2xl
) !default;

$fa-li-width : 2em !default;
$fa-li-margin : $fa-li-width * fa-divide(5, 4) !default;

$fa-pull-margin : .3em !default;

$fa-primary-opacity : 1 !default;
$fa-secondary-opacity : .4 !default;

$fa-stack-vertical-align: middle !default;
$fa-stack-width : ($fa-fw-width * 2) !default;
$fa-stack-z-index : auto !default;

$fa-font-display : block !default;
$fa-font-path : "../webfonts" !default;

$fa-var-0: \30;
$fa-var-1: \31;
$fa-var-2: \32;
$fa-var-3: \33;
$fa-var-4: \34;
$fa-var-5: \35;
$fa-var-6: \36;
$fa-var-7: \37;
$fa-var-8: \38;
$fa-var-9: \39;
$fa-var-a: \41;
$fa-var-address-book: \f2b9;
$fa-var-contact-book: \f2b9;
$fa-var-address-card: \f2bb;
$fa-var-contact-card: \f2bb;
$fa-var-vcard: \f2bb;
$fa-var-align-center: \f037;
$fa-var-align-justify: \f039;
$fa-var-align-left: \f036;
$fa-var-align-right: \f038;
$fa-var-anchor: \f13d;
$fa-var-anchor-circle-check: \e4aa;
$fa-var-anchor-circle-exclamation: \e4ab;
$fa-var-anchor-circle-xmark: \e4ac;
$fa-var-anchor-lock: \e4ad;
$fa-var-angle-down: \f107;
$fa-var-angle-left: \f104;
$fa-var-angle-right: \f105;
$fa-var-angle-up: \f106;
$fa-var-angles-down: \f103;
$fa-var-angle-double-down: \f103;
$fa-var-angles-left: \f100;
$fa-var-angle-double-left: \f100;
$fa-var-angles-right: \f101;
$fa-var-angle-double-right: \f101;
$fa-var-angles-up: \f102;
$fa-var-angle-double-up: \f102;
$fa-var-ankh: \f644;
$fa-var-apple-whole: \f5d1;
$fa-var-apple-alt: \f5d1;
$fa-var-archway: \f557;
$fa-var-arrow-down: \f063;
$fa-var-arrow-down-1-9: \f162;
$fa-var-sort-numeric-asc: \f162;
$fa-var-sort-numeric-down: \f162;
$fa-var-arrow-down-9-1: \f886;
$fa-var-sort-numeric-desc: \f886;
$fa-var-sort-numeric-down-alt: \f886;
$fa-var-arrow-down-a-z: \f15d;
$fa-var-sort-alpha-asc: \f15d;
$fa-var-sort-alpha-down: \f15d;
$fa-var-arrow-down-long: \f175;
$fa-var-long-arrow-down: \f175;
$fa-var-arrow-down-short-wide: \f884;
$fa-var-sort-amount-desc: \f884;
$fa-var-sort-amount-down-alt: \f884;
$fa-var-arrow-down-up-across-line: \e4af;
$fa-var-arrow-down-up-lock: \e4b0;
$fa-var-arrow-down-wide-short: \f160;
$fa-var-sort-amount-asc: \f160;
$fa-var-sort-amount-down: \f160;
$fa-var-arrow-down-z-a: \f881;
$fa-var-sort-alpha-desc: \f881;
$fa-var-sort-alpha-down-alt: \f881;
$fa-var-arrow-left: \f060;
$fa-var-arrow-left-long: \f177;
$fa-var-long-arrow-left: \f177;
$fa-var-arrow-pointer: \f245;
$fa-var-mouse-pointer: \f245;
$fa-var-arrow-right: \f061;
$fa-var-arrow-right-arrow-left: \f0ec;
$fa-var-exchange: \f0ec;
$fa-var-arrow-right-from-bracket: \f08b;
$fa-var-sign-out: \f08b;
$fa-var-arrow-right-long: \f178;
$fa-var-long-arrow-right: \f178;
$fa-var-arrow-right-to-bracket: \f090;
$fa-var-sign-in: \f090;
$fa-var-arrow-right-to-city: \e4b3;
$fa-var-arrow-rotate-left: \f0e2;
$fa-var-arrow-left-rotate: \f0e2;
$fa-var-arrow-rotate-back: \f0e2;
$fa-var-arrow-rotate-backward: \f0e2;
$fa-var-undo: \f0e2;
$fa-var-arrow-rotate-right: \f01e;
$fa-var-arrow-right-rotate: \f01e;
$fa-var-arrow-rotate-forward: \f01e;
$fa-var-redo: \f01e;
$fa-var-arrow-trend-down: \e097;
$fa-var-arrow-trend-up: \e098;
$fa-var-arrow-turn-down: \f149;
$fa-var-level-down: \f149;
$fa-var-arrow-turn-up: \f148;
$fa-var-level-up: \f148;
$fa-var-arrow-up: \f062;
$fa-var-arrow-up-1-9: \f163;
$fa-var-sort-numeric-up: \f163;
$fa-var-arrow-up-9-1: \f887;
$fa-var-sort-numeric-up-alt: \f887;
$fa-var-arrow-up-a-z: \f15e;
$fa-var-sort-alpha-up: \f15e;
$fa-var-arrow-up-from-bracket: \e09a;
$fa-var-arrow-up-from-ground-water: \e4b5;
$fa-var-arrow-up-from-water-pump: \e4b6;
$fa-var-arrow-up-long: \f176;
$fa-var-long-arrow-up: \f176;
$fa-var-arrow-up-right-dots: \e4b7;
$fa-var-arrow-up-right-from-square: \f08e;
$fa-var-external-link: \f08e;
$fa-var-arrow-up-short-wide: \f885;
$fa-var-sort-amount-up-alt: \f885;
$fa-var-arrow-up-wide-short: \f161;
$fa-var-sort-amount-up: \f161;
$fa-var-arrow-up-z-a: \f882;
$fa-var-sort-alpha-up-alt: \f882;
$fa-var-arrows-down-to-line: \e4b8;
$fa-var-arrows-down-to-people: \e4b9;
$fa-var-arrows-left-right: \f07e;
$fa-var-arrows-h: \f07e;
$fa-var-arrows-left-right-to-line: \e4ba;
$fa-var-arrows-rotate: \f021;
$fa-var-refresh: \f021;
$fa-var-sync: \f021;
$fa-var-arrows-spin: \e4bb;
$fa-var-arrows-split-up-and-left: \e4bc;
$fa-var-arrows-to-circle: \e4bd;
$fa-var-arrows-to-dot: \e4be;
$fa-var-arrows-to-eye: \e4bf;
$fa-var-arrows-turn-right: \e4c0;
$fa-var-arrows-turn-to-dots: \e4c1;
$fa-var-arrows-up-down: \f07d;
$fa-var-arrows-v: \f07d;
$fa-var-arrows-up-down-left-right: \f047;
$fa-var-arrows: \f047;
$fa-var-arrows-up-to-line: \e4c2;
$fa-var-asterisk: \2a;
$fa-var-at: \40;
$fa-var-atom: \f5d2;
$fa-var-audio-description: \f29e;
$fa-var-austral-sign: \e0a9;
$fa-var-award: \f559;
$fa-var-b: \42;
$fa-var-baby: \f77c;
$fa-var-baby-carriage: \f77d;
$fa-var-carriage-baby: \f77d;
$fa-var-backward: \f04a;
$fa-var-backward-fast: \f049;
$fa-var-fast-backward: \f049;
$fa-var-backward-step: \f048;
$fa-var-step-backward: \f048;
$fa-var-bacon: \f7e5;
$fa-var-bacteria: \e059;
$fa-var-bacterium: \e05a;
$fa-var-bag-shopping: \f290;
$fa-var-shopping-bag: \f290;
$fa-var-bahai: \f666;
$fa-var-baht-sign: \e0ac;
$fa-var-ban: \f05e;
$fa-var-cancel: \f05e;
$fa-var-ban-smoking: \f54d;
$fa-var-smoking-ban: \f54d;
$fa-var-bandage: \f462;
$fa-var-band-aid: \f462;
$fa-var-barcode: \f02a;
$fa-var-bars: \f0c9;
$fa-var-navicon: \f0c9;
$fa-var-bars-progress: \f828;
$fa-var-tasks-alt: \f828;
$fa-var-bars-staggered: \f550;
$fa-var-reorder: \f550;
$fa-var-stream: \f550;
$fa-var-baseball: \f433;
$fa-var-baseball-ball: \f433;
$fa-var-baseball-bat-ball: \f432;
$fa-var-basket-shopping: \f291;
$fa-var-shopping-basket: \f291;
$fa-var-basketball: \f434;
$fa-var-basketball-ball: \f434;
$fa-var-bath: \f2cd;
$fa-var-bathtub: \f2cd;
$fa-var-battery-empty: \f244;
$fa-var-battery-0: \f244;
$fa-var-battery-full: \f240;
$fa-var-battery: \f240;
$fa-var-battery-5: \f240;
$fa-var-battery-half: \f242;
$fa-var-battery-3: \f242;
$fa-var-battery-quarter: \f243;
$fa-var-battery-2: \f243;
$fa-var-battery-three-quarters: \f241;
$fa-var-battery-4: \f241;
$fa-var-bed: \f236;
$fa-var-bed-pulse: \f487;
$fa-var-procedures: \f487;
$fa-var-beer-mug-empty: \f0fc;
$fa-var-beer: \f0fc;
$fa-var-bell: \f0f3;
$fa-var-bell-concierge: \f562;
$fa-var-concierge-bell: \f562;
$fa-var-bell-slash: \f1f6;
$fa-var-bezier-curve: \f55b;
$fa-var-bicycle: \f206;
$fa-var-binoculars: \f1e5;
$fa-var-biohazard: \f780;
$fa-var-bitcoin-sign: \e0b4;
$fa-var-blender: \f517;
$fa-var-blender-phone: \f6b6;
$fa-var-blog: \f781;
$fa-var-bold: \f032;
$fa-var-bolt: \f0e7;
$fa-var-zap: \f0e7;
$fa-var-bolt-lightning: \e0b7;
$fa-var-bomb: \f1e2;
$fa-var-bone: \f5d7;
$fa-var-bong: \f55c;
$fa-var-book: \f02d;
$fa-var-book-atlas: \f558;
$fa-var-atlas: \f558;
$fa-var-book-bible: \f647;
$fa-var-bible: \f647;
$fa-var-book-bookmark: \e0bb;
$fa-var-book-journal-whills: \f66a;
$fa-var-journal-whills: \f66a;
$fa-var-book-medical: \f7e6;
$fa-var-book-open: \f518;
$fa-var-book-open-reader: \f5da;
$fa-var-book-reader: \f5da;
$fa-var-book-quran: \f687;
$fa-var-quran: \f687;
$fa-var-book-skull: \f6b7;
$fa-var-book-dead: \f6b7;
$fa-var-bookmark: \f02e;
$fa-var-border-all: \f84c;
$fa-var-border-none: \f850;
$fa-var-border-top-left: \f853;
$fa-var-border-style: \f853;
$fa-var-bore-hole: \e4c3;
$fa-var-bottle-droplet: \e4c4;
$fa-var-bottle-water: \e4c5;
$fa-var-bowl-food: \e4c6;
$fa-var-bowl-rice: \e2eb;
$fa-var-bowling-ball: \f436;
$fa-var-box: \f466;
$fa-var-box-archive: \f187;
$fa-var-archive: \f187;
$fa-var-box-open: \f49e;
$fa-var-box-tissue: \e05b;
$fa-var-boxes-packing: \e4c7;
$fa-var-boxes-stacked: \f468;
$fa-var-boxes: \f468;
$fa-var-boxes-alt: \f468;
$fa-var-braille: \f2a1;
$fa-var-brain: \f5dc;
$fa-var-brazilian-real-sign: \e46c;
$fa-var-bread-slice: \f7ec;
$fa-var-bridge: \e4c8;
$fa-var-bridge-circle-check: \e4c9;
$fa-var-bridge-circle-exclamation: \e4ca;
$fa-var-bridge-circle-xmark: \e4cb;
$fa-var-bridge-lock: \e4cc;
$fa-var-bridge-water: \e4ce;
$fa-var-briefcase: \f0b1;
$fa-var-briefcase-medical: \f469;
$fa-var-broom: \f51a;
$fa-var-broom-ball: \f458;
$fa-var-quidditch: \f458;
$fa-var-quidditch-broom-ball: \f458;
$fa-var-brush: \f55d;
$fa-var-bucket: \e4cf;
$fa-var-bug: \f188;
$fa-var-bug-slash: \e490;
$fa-var-bugs: \e4d0;
$fa-var-building: \f1ad;
$fa-var-building-circle-arrow-right: \e4d1;
$fa-var-building-circle-check: \e4d2;
$fa-var-building-circle-exclamation: \e4d3;
$fa-var-building-circle-xmark: \e4d4;
$fa-var-building-columns: \f19c;
$fa-var-bank: \f19c;
$fa-var-institution: \f19c;
$fa-var-museum: \f19c;
$fa-var-university: \f19c;
$fa-var-building-flag: \e4d5;
$fa-var-building-lock: \e4d6;
$fa-var-building-ngo: \e4d7;
$fa-var-building-shield: \e4d8;
$fa-var-building-un: \e4d9;
$fa-var-building-user: \e4da;
$fa-var-building-wheat: \e4db;
$fa-var-bullhorn: \f0a1;
$fa-var-bullseye: \f140;
$fa-var-burger: \f805;
$fa-var-hamburger: \f805;
$fa-var-burst: \e4dc;
$fa-var-bus: \f207;
$fa-var-bus-simple: \f55e;
$fa-var-bus-alt: \f55e;
$fa-var-business-time: \f64a;
$fa-var-briefcase-clock: \f64a;
$fa-var-c: \43;
$fa-var-cake-candles: \f1fd;
$fa-var-birthday-cake: \f1fd;
$fa-var-cake: \f1fd;
$fa-var-calculator: \f1ec;
$fa-var-calendar: \f133;
$fa-var-calendar-check: \f274;
$fa-var-calendar-day: \f783;
$fa-var-calendar-days: \f073;
$fa-var-calendar-alt: \f073;
$fa-var-calendar-minus: \f272;
$fa-var-calendar-plus: \f271;
$fa-var-calendar-week: \f784;
$fa-var-calendar-xmark: \f273;
$fa-var-calendar-times: \f273;
$fa-var-camera: \f030;
$fa-var-camera-alt: \f030;
$fa-var-camera-retro: \f083;
$fa-var-camera-rotate: \e0d8;
$fa-var-campground: \f6bb;
$fa-var-candy-cane: \f786;
$fa-var-cannabis: \f55f;
$fa-var-capsules: \f46b;
$fa-var-car: \f1b9;
$fa-var-automobile: \f1b9;
$fa-var-car-battery: \f5df;
$fa-var-battery-car: \f5df;
$fa-var-car-burst: \f5e1;
$fa-var-car-crash: \f5e1;
$fa-var-car-on: \e4dd;
$fa-var-car-rear: \f5de;
$fa-var-car-alt: \f5de;
$fa-var-car-side: \f5e4;
$fa-var-car-tunnel: \e4de;
$fa-var-caravan: \f8ff;
$fa-var-caret-down: \f0d7;
$fa-var-caret-left: \f0d9;
$fa-var-caret-right: \f0da;
$fa-var-caret-up: \f0d8;
$fa-var-carrot: \f787;
$fa-var-cart-arrow-down: \f218;
$fa-var-cart-flatbed: \f474;
$fa-var-dolly-flatbed: \f474;
$fa-var-cart-flatbed-suitcase: \f59d;
$fa-var-luggage-cart: \f59d;
$fa-var-cart-plus: \f217;
$fa-var-cart-shopping: \f07a;
$fa-var-shopping-cart: \f07a;
$fa-var-cash-register: \f788;
$fa-var-cat: \f6be;
$fa-var-cedi-sign: \e0df;
$fa-var-cent-sign: \e3f5;
$fa-var-certificate: \f0a3;
$fa-var-chair: \f6c0;
$fa-var-chalkboard: \f51b;
$fa-var-blackboard: \f51b;
$fa-var-chalkboard-user: \f51c;
$fa-var-chalkboard-teacher: \f51c;
$fa-var-champagne-glasses: \f79f;
$fa-var-glass-cheers: \f79f;
$fa-var-charging-station: \f5e7;
$fa-var-chart-area: \f1fe;
$fa-var-area-chart: \f1fe;
$fa-var-chart-bar: \f080;
$fa-var-bar-chart: \f080;
$fa-var-chart-column: \e0e3;
$fa-var-chart-gantt: \e0e4;
$fa-var-chart-line: \f201;
$fa-var-line-chart: \f201;
$fa-var-chart-pie: \f200;
$fa-var-pie-chart: \f200;
$fa-var-chart-simple: \e473;
$fa-var-check: \f00c;
$fa-var-check-double: \f560;
$fa-var-check-to-slot: \f772;
$fa-var-vote-yea: \f772;
$fa-var-cheese: \f7ef;
$fa-var-chess: \f439;
$fa-var-chess-bishop: \f43a;
$fa-var-chess-board: \f43c;
$fa-var-chess-king: \f43f;
$fa-var-chess-knight: \f441;
$fa-var-chess-pawn: \f443;
$fa-var-chess-queen: \f445;
$fa-var-chess-rook: \f447;
$fa-var-chevron-down: \f078;
$fa-var-chevron-left: \f053;
$fa-var-chevron-right: \f054;
$fa-var-chevron-up: \f077;
$fa-var-child: \f1ae;
$fa-var-child-dress: \e59c;
$fa-var-child-reaching: \e59d;
$fa-var-child-rifle: \e4e0;
$fa-var-children: \e4e1;
$fa-var-church: \f51d;
$fa-var-circle: \f111;
$fa-var-circle-arrow-down: \f0ab;
$fa-var-arrow-circle-down: \f0ab;
$fa-var-circle-arrow-left: \f0a8;
$fa-var-arrow-circle-left: \f0a8;
$fa-var-circle-arrow-right: \f0a9;
$fa-var-arrow-circle-right: \f0a9;
$fa-var-circle-arrow-up: \f0aa;
$fa-var-arrow-circle-up: \f0aa;
$fa-var-circle-check: \f058;
$fa-var-check-circle: \f058;
$fa-var-circle-chevron-down: \f13a;
$fa-var-chevron-circle-down: \f13a;
$fa-var-circle-chevron-left: \f137;
$fa-var-chevron-circle-left: \f137;
$fa-var-circle-chevron-right: \f138;
$fa-var-chevron-circle-right: \f138;
$fa-var-circle-chevron-up: \f139;
$fa-var-chevron-circle-up: \f139;
$fa-var-circle-dollar-to-slot: \f4b9;
$fa-var-donate: \f4b9;
$fa-var-circle-dot: \f192;
$fa-var-dot-circle: \f192;
$fa-var-circle-down: \f358;
$fa-var-arrow-alt-circle-down: \f358;
$fa-var-circle-exclamation: \f06a;
$fa-var-exclamation-circle: \f06a;
$fa-var-circle-h: \f47e;
$fa-var-hospital-symbol: \f47e;
$fa-var-circle-half-stroke: \f042;
$fa-var-adjust: \f042;
$fa-var-circle-info: \f05a;
$fa-var-info-circle: \f05a;
$fa-var-circle-left: \f359;
$fa-var-arrow-alt-circle-left: \f359;
$fa-var-circle-minus: \f056;
$fa-var-minus-circle: \f056;
$fa-var-circle-nodes: \e4e2;
$fa-var-circle-notch: \f1ce;
$fa-var-circle-pause: \f28b;
$fa-var-pause-circle: \f28b;
$fa-var-circle-play: \f144;
$fa-var-play-circle: \f144;
$fa-var-circle-plus: \f055;
$fa-var-plus-circle: \f055;
$fa-var-circle-question: \f059;
$fa-var-question-circle: \f059;
$fa-var-circle-radiation: \f7ba;
$fa-var-radiation-alt: \f7ba;
$fa-var-circle-right: \f35a;
$fa-var-arrow-alt-circle-right: \f35a;
$fa-var-circle-stop: \f28d;
$fa-var-stop-circle: \f28d;
$fa-var-circle-up: \f35b;
$fa-var-arrow-alt-circle-up: \f35b;
$fa-var-circle-user: \f2bd;
$fa-var-user-circle: \f2bd;
$fa-var-circle-xmark: \f057;
$fa-var-times-circle: \f057;
$fa-var-xmark-circle: \f057;
$fa-var-city: \f64f;
$fa-var-clapperboard: \e131;
$fa-var-clipboard: \f328;
$fa-var-clipboard-check: \f46c;
$fa-var-clipboard-list: \f46d;
$fa-var-clipboard-question: \e4e3;
$fa-var-clipboard-user: \f7f3;
$fa-var-clock: \f017;
$fa-var-clock-four: \f017;
$fa-var-clock-rotate-left: \f1da;
$fa-var-history: \f1da;
$fa-var-clone: \f24d;
$fa-var-closed-captioning: \f20a;
$fa-var-cloud: \f0c2;
$fa-var-cloud-arrow-down: \f0ed;
$fa-var-cloud-download: \f0ed;
$fa-var-cloud-download-alt: \f0ed;
$fa-var-cloud-arrow-up: \f0ee;
$fa-var-cloud-upload: \f0ee;
$fa-var-cloud-upload-alt: \f0ee;
$fa-var-cloud-bolt: \f76c;
$fa-var-thunderstorm: \f76c;
$fa-var-cloud-meatball: \f73b;
$fa-var-cloud-moon: \f6c3;
$fa-var-cloud-moon-rain: \f73c;
$fa-var-cloud-rain: \f73d;
$fa-var-cloud-showers-heavy: \f740;
$fa-var-cloud-showers-water: \e4e4;
$fa-var-cloud-sun: \f6c4;
$fa-var-cloud-sun-rain: \f743;
$fa-var-clover: \e139;
$fa-var-code: \f121;
$fa-var-code-branch: \f126;
$fa-var-code-commit: \f386;
$fa-var-code-compare: \e13a;
$fa-var-code-fork: \e13b;
$fa-var-code-merge: \f387;
$fa-var-code-pull-request: \e13c;
$fa-var-coins: \f51e;
$fa-var-colon-sign: \e140;
$fa-var-comment: \f075;
$fa-var-comment-dollar: \f651;
$fa-var-comment-dots: \f4ad;
$fa-var-commenting: \f4ad;
$fa-var-comment-medical: \f7f5;
$fa-var-comment-slash: \f4b3;
$fa-var-comment-sms: \f7cd;
$fa-var-sms: \f7cd;
$fa-var-comments: \f086;
$fa-var-comments-dollar: \f653;
$fa-var-compact-disc: \f51f;
$fa-var-compass: \f14e;
$fa-var-compass-drafting: \f568;
$fa-var-drafting-compass: \f568;
$fa-var-compress: \f066;
$fa-var-computer: \e4e5;
$fa-var-computer-mouse: \f8cc;
$fa-var-mouse: \f8cc;
$fa-var-cookie: \f563;
$fa-var-cookie-bite: \f564;
$fa-var-copy: \f0c5;
$fa-var-copyright: \f1f9;
$fa-var-couch: \f4b8;
$fa-var-cow: \f6c8;
$fa-var-credit-card: \f09d;
$fa-var-credit-card-alt: \f09d;
$fa-var-crop: \f125;
$fa-var-crop-simple: \f565;
$fa-var-crop-alt: \f565;
$fa-var-cross: \f654;
$fa-var-crosshairs: \f05b;
$fa-var-crow: \f520;
$fa-var-crown: \f521;
$fa-var-crutch: \f7f7;
$fa-var-cruzeiro-sign: \e152;
$fa-var-cube: \f1b2;
$fa-var-cubes: \f1b3;
$fa-var-cubes-stacked: \e4e6;
$fa-var-d: \44;
$fa-var-database: \f1c0;
$fa-var-delete-left: \f55a;
$fa-var-backspace: \f55a;
$fa-var-democrat: \f747;
$fa-var-desktop: \f390;
$fa-var-desktop-alt: \f390;
$fa-var-dharmachakra: \f655;
$fa-var-diagram-next: \e476;
$fa-var-diagram-predecessor: \e477;
$fa-var-diagram-project: \f542;
$fa-var-project-diagram: \f542;
$fa-var-diagram-successor: \e47a;
$fa-var-diamond: \f219;
$fa-var-diamond-turn-right: \f5eb;
$fa-var-directions: \f5eb;
$fa-var-dice: \f522;
$fa-var-dice-d20: \f6cf;
$fa-var-dice-d6: \f6d1;
$fa-var-dice-five: \f523;
$fa-var-dice-four: \f524;
$fa-var-dice-one: \f525;
$fa-var-dice-six: \f526;
$fa-var-dice-three: \f527;
$fa-var-dice-two: \f528;
$fa-var-disease: \f7fa;
$fa-var-display: \e163;
$fa-var-divide: \f529;
$fa-var-dna: \f471;
$fa-var-dog: \f6d3;
$fa-var-dollar-sign: \24;
$fa-var-dollar: \24;
$fa-var-usd: \24;
$fa-var-dolly: \f472;
$fa-var-dolly-box: \f472;
$fa-var-dong-sign: \e169;
$fa-var-door-closed: \f52a;
$fa-var-door-open: \f52b;
$fa-var-dove: \f4ba;
$fa-var-down-left-and-up-right-to-center: \f422;
$fa-var-compress-alt: \f422;
$fa-var-down-long: \f309;
$fa-var-long-arrow-alt-down: \f309;
$fa-var-download: \f019;
$fa-var-dragon: \f6d5;
$fa-var-draw-polygon: \f5ee;
$fa-var-droplet: \f043;
$fa-var-tint: \f043;
$fa-var-droplet-slash: \f5c7;
$fa-var-tint-slash: \f5c7;
$fa-var-drum: \f569;
$fa-var-drum-steelpan: \f56a;
$fa-var-drumstick-bite: \f6d7;
$fa-var-dumbbell: \f44b;
$fa-var-dumpster: \f793;
$fa-var-dumpster-fire: \f794;
$fa-var-dungeon: \f6d9;
$fa-var-e: \45;
$fa-var-ear-deaf: \f2a4;
$fa-var-deaf: \f2a4;
$fa-var-deafness: \f2a4;
$fa-var-hard-of-hearing: \f2a4;
$fa-var-ear-listen: \f2a2;
$fa-var-assistive-listening-systems: \f2a2;
$fa-var-earth-africa: \f57c;
$fa-var-globe-africa: \f57c;
$fa-var-earth-americas: \f57d;
$fa-var-earth: \f57d;
$fa-var-earth-america: \f57d;
$fa-var-globe-americas: \f57d;
$fa-var-earth-asia: \f57e;
$fa-var-globe-asia: \f57e;
$fa-var-earth-europe: \f7a2;
$fa-var-globe-europe: \f7a2;
$fa-var-earth-oceania: \e47b;
$fa-var-globe-oceania: \e47b;
$fa-var-egg: \f7fb;
$fa-var-eject: \f052;
$fa-var-elevator: \e16d;
$fa-var-ellipsis: \f141;
$fa-var-ellipsis-h: \f141;
$fa-var-ellipsis-vertical: \f142;
$fa-var-ellipsis-v: \f142;
$fa-var-envelope: \f0e0;
$fa-var-envelope-circle-check: \e4e8;
$fa-var-envelope-open: \f2b6;
$fa-var-envelope-open-text: \f658;
$fa-var-envelopes-bulk: \f674;
$fa-var-mail-bulk: \f674;
$fa-var-equals: \3d;
$fa-var-eraser: \f12d;
$fa-var-ethernet: \f796;
$fa-var-euro-sign: \f153;
$fa-var-eur: \f153;
$fa-var-euro: \f153;
$fa-var-exclamation: \21;
$fa-var-expand: \f065;
$fa-var-explosion: \e4e9;
$fa-var-eye: \f06e;
$fa-var-eye-dropper: \f1fb;
$fa-var-eye-dropper-empty: \f1fb;
$fa-var-eyedropper: \f1fb;
$fa-var-eye-low-vision: \f2a8;
$fa-var-low-vision: \f2a8;
$fa-var-eye-slash: \f070;
$fa-var-f: \46;
$fa-var-face-angry: \f556;
$fa-var-angry: \f556;
$fa-var-face-dizzy: \f567;
$fa-var-dizzy: \f567;
$fa-var-face-flushed: \f579;
$fa-var-flushed: \f579;
$fa-var-face-frown: \f119;
$fa-var-frown: \f119;
$fa-var-face-frown-open: \f57a;
$fa-var-frown-open: \f57a;
$fa-var-face-grimace: \f57f;
$fa-var-grimace: \f57f;
$fa-var-face-grin: \f580;
$fa-var-grin: \f580;
$fa-var-face-grin-beam: \f582;
$fa-var-grin-beam: \f582;
$fa-var-face-grin-beam-sweat: \f583;
$fa-var-grin-beam-sweat: \f583;
$fa-var-face-grin-hearts: \f584;
$fa-var-grin-hearts: \f584;
$fa-var-face-grin-squint: \f585;
$fa-var-grin-squint: \f585;
$fa-var-face-grin-squint-tears: \f586;
$fa-var-grin-squint-tears: \f586;
$fa-var-face-grin-stars: \f587;
$fa-var-grin-stars: \f587;
$fa-var-face-grin-tears: \f588;
$fa-var-grin-tears: \f588;
$fa-var-face-grin-tongue: \f589;
$fa-var-grin-tongue: \f589;
$fa-var-face-grin-tongue-squint: \f58a;
$fa-var-grin-tongue-squint: \f58a;
$fa-var-face-grin-tongue-wink: \f58b;
$fa-var-grin-tongue-wink: \f58b;
$fa-var-face-grin-wide: \f581;
$fa-var-grin-alt: \f581;
$fa-var-face-grin-wink: \f58c;
$fa-var-grin-wink: \f58c;
$fa-var-face-kiss: \f596;
$fa-var-kiss: \f596;
$fa-var-face-kiss-beam: \f597;
$fa-var-kiss-beam: \f597;
$fa-var-face-kiss-wink-heart: \f598;
$fa-var-kiss-wink-heart: \f598;
$fa-var-face-laugh: \f599;
$fa-var-laugh: \f599;
$fa-var-face-laugh-beam: \f59a;
$fa-var-laugh-beam: \f59a;
$fa-var-face-laugh-squint: \f59b;
$fa-var-laugh-squint: \f59b;
$fa-var-face-laugh-wink: \f59c;
$fa-var-laugh-wink: \f59c;
$fa-var-face-meh: \f11a;
$fa-var-meh: \f11a;
$fa-var-face-meh-blank: \f5a4;
$fa-var-meh-blank: \f5a4;
$fa-var-face-rolling-eyes: \f5a5;
$fa-var-meh-rolling-eyes: \f5a5;
$fa-var-face-sad-cry: \f5b3;
$fa-var-sad-cry: \f5b3;
$fa-var-face-sad-tear: \f5b4;
$fa-var-sad-tear: \f5b4;
$fa-var-face-smile: \f118;
$fa-var-smile: \f118;
$fa-var-face-smile-beam: \f5b8;
$fa-var-smile-beam: \f5b8;
$fa-var-face-smile-wink: \f4da;
$fa-var-smile-wink: \f4da;
$fa-var-face-surprise: \f5c2;
$fa-var-surprise: \f5c2;
$fa-var-face-tired: \f5c8;
$fa-var-tired: \f5c8;
$fa-var-fan: \f863;
$fa-var-faucet: \e005;
$fa-var-faucet-drip: \e006;
$fa-var-fax: \f1ac;
$fa-var-feather: \f52d;
$fa-var-feather-pointed: \f56b;
$fa-var-feather-alt: \f56b;
$fa-var-ferry: \e4ea;
$fa-var-file: \f15b;
$fa-var-file-arrow-down: \f56d;
$fa-var-file-download: \f56d;
$fa-var-file-arrow-up: \f574;
$fa-var-file-upload: \f574;
$fa-var-file-audio: \f1c7;
$fa-var-file-circle-check: \e493;
$fa-var-file-circle-exclamation: \e4eb;
$fa-var-file-circle-minus: \e4ed;
$fa-var-file-circle-plus: \e4ee;
$fa-var-file-circle-question: \e4ef;
$fa-var-file-circle-xmark: \e494;
$fa-var-file-code: \f1c9;
$fa-var-file-contract: \f56c;
$fa-var-file-csv: \f6dd;
$fa-var-file-excel: \f1c3;
$fa-var-file-export: \f56e;
$fa-var-arrow-right-from-file: \f56e;
$fa-var-file-image: \f1c5;
$fa-var-file-import: \f56f;
$fa-var-arrow-right-to-file: \f56f;
$fa-var-file-invoice: \f570;
$fa-var-file-invoice-dollar: \f571;
$fa-var-file-lines: \f15c;
$fa-var-file-alt: \f15c;
$fa-var-file-text: \f15c;
$fa-var-file-medical: \f477;
$fa-var-file-pdf: \f1c1;
$fa-var-file-pen: \f31c;
$fa-var-file-edit: \f31c;
$fa-var-file-powerpoint: \f1c4;
$fa-var-file-prescription: \f572;
$fa-var-file-shield: \e4f0;
$fa-var-file-signature: \f573;
$fa-var-file-video: \f1c8;
$fa-var-file-waveform: \f478;
$fa-var-file-medical-alt: \f478;
$fa-var-file-word: \f1c2;
$fa-var-file-zipper: \f1c6;
$fa-var-file-archive: \f1c6;
$fa-var-fill: \f575;
$fa-var-fill-drip: \f576;
$fa-var-film: \f008;
$fa-var-filter: \f0b0;
$fa-var-filter-circle-dollar: \f662;
$fa-var-funnel-dollar: \f662;
$fa-var-filter-circle-xmark: \e17b;
$fa-var-fingerprint: \f577;
$fa-var-fire: \f06d;
$fa-var-fire-burner: \e4f1;
$fa-var-fire-extinguisher: \f134;
$fa-var-fire-flame-curved: \f7e4;
$fa-var-fire-alt: \f7e4;
$fa-var-fire-flame-simple: \f46a;
$fa-var-burn: \f46a;
$fa-var-fish: \f578;
$fa-var-fish-fins: \e4f2;
$fa-var-flag: \f024;
$fa-var-flag-checkered: \f11e;
$fa-var-flag-usa: \f74d;
$fa-var-flask: \f0c3;
$fa-var-flask-vial: \e4f3;
$fa-var-floppy-disk: \f0c7;
$fa-var-save: \f0c7;
$fa-var-florin-sign: \e184;
$fa-var-folder: \f07b;
$fa-var-folder-blank: \f07b;
$fa-var-folder-closed: \e185;
$fa-var-folder-minus: \f65d;
$fa-var-folder-open: \f07c;
$fa-var-folder-plus: \f65e;
$fa-var-folder-tree: \f802;
$fa-var-font: \f031;
$fa-var-football: \f44e;
$fa-var-football-ball: \f44e;
$fa-var-forward: \f04e;
$fa-var-forward-fast: \f050;
$fa-var-fast-forward: \f050;
$fa-var-forward-step: \f051;
$fa-var-step-forward: \f051;
$fa-var-franc-sign: \e18f;
$fa-var-frog: \f52e;
$fa-var-futbol: \f1e3;
$fa-var-futbol-ball: \f1e3;
$fa-var-soccer-ball: \f1e3;
$fa-var-g: \47;
$fa-var-gamepad: \f11b;
$fa-var-gas-pump: \f52f;
$fa-var-gauge: \f624;
$fa-var-dashboard: \f624;
$fa-var-gauge-med: \f624;
$fa-var-tachometer-alt-average: \f624;
$fa-var-gauge-high: \f625;
$fa-var-tachometer-alt: \f625;
$fa-var-tachometer-alt-fast: \f625;
$fa-var-gauge-simple: \f629;
$fa-var-gauge-simple-med: \f629;
$fa-var-tachometer-average: \f629;
$fa-var-gauge-simple-high: \f62a;
$fa-var-tachometer: \f62a;
$fa-var-tachometer-fast: \f62a;
$fa-var-gavel: \f0e3;
$fa-var-legal: \f0e3;
$fa-var-gear: \f013;
$fa-var-cog: \f013;
$fa-var-gears: \f085;
$fa-var-cogs: \f085;
$fa-var-gem: \f3a5;
$fa-var-genderless: \f22d;
$fa-var-ghost: \f6e2;
$fa-var-gift: \f06b;
$fa-var-gifts: \f79c;
$fa-var-glass-water: \e4f4;
$fa-var-glass-water-droplet: \e4f5;
$fa-var-glasses: \f530;
$fa-var-globe: \f0ac;
$fa-var-golf-ball-tee: \f450;
$fa-var-golf-ball: \f450;
$fa-var-gopuram: \f664;
$fa-var-graduation-cap: \f19d;
$fa-var-mortar-board: \f19d;
$fa-var-greater-than: \3e;
$fa-var-greater-than-equal: \f532;
$fa-var-grip: \f58d;
$fa-var-grip-horizontal: \f58d;
$fa-var-grip-lines: \f7a4;
$fa-var-grip-lines-vertical: \f7a5;
$fa-var-grip-vertical: \f58e;
$fa-var-group-arrows-rotate: \e4f6;
$fa-var-guarani-sign: \e19a;
$fa-var-guitar: \f7a6;
$fa-var-gun: \e19b;
$fa-var-h: \48;
$fa-var-hammer: \f6e3;
$fa-var-hamsa: \f665;
$fa-var-hand: \f256;
$fa-var-hand-paper: \f256;
$fa-var-hand-back-fist: \f255;
$fa-var-hand-rock: \f255;
$fa-var-hand-dots: \f461;
$fa-var-allergies: \f461;
$fa-var-hand-fist: \f6de;
$fa-var-fist-raised: \f6de;
$fa-var-hand-holding: \f4bd;
$fa-var-hand-holding-dollar: \f4c0;
$fa-var-hand-holding-usd: \f4c0;
$fa-var-hand-holding-droplet: \f4c1;
$fa-var-hand-holding-water: \f4c1;
$fa-var-hand-holding-hand: \e4f7;
$fa-var-hand-holding-heart: \f4be;
$fa-var-hand-holding-medical: \e05c;
$fa-var-hand-lizard: \f258;
$fa-var-hand-middle-finger: \f806;
$fa-var-hand-peace: \f25b;
$fa-var-hand-point-down: \f0a7;
$fa-var-hand-point-left: \f0a5;
$fa-var-hand-point-right: \f0a4;
$fa-var-hand-point-up: \f0a6;
$fa-var-hand-pointer: \f25a;
$fa-var-hand-scissors: \f257;
$fa-var-hand-sparkles: \e05d;
$fa-var-hand-spock: \f259;
$fa-var-handcuffs: \e4f8;
$fa-var-hands: \f2a7;
$fa-var-sign-language: \f2a7;
$fa-var-signing: \f2a7;
$fa-var-hands-asl-interpreting: \f2a3;
$fa-var-american-sign-language-interpreting: \f2a3;
$fa-var-asl-interpreting: \f2a3;
$fa-var-hands-american-sign-language-interpreting: \f2a3;
$fa-var-hands-bound: \e4f9;
$fa-var-hands-bubbles: \e05e;
$fa-var-hands-wash: \e05e;
$fa-var-hands-clapping: \e1a8;
$fa-var-hands-holding: \f4c2;
$fa-var-hands-holding-child: \e4fa;
$fa-var-hands-holding-circle: \e4fb;
$fa-var-hands-praying: \f684;
$fa-var-praying-hands: \f684;
$fa-var-handshake: \f2b5;
$fa-var-handshake-angle: \f4c4;
$fa-var-hands-helping: \f4c4;
$fa-var-handshake-simple: \f4c6;
$fa-var-handshake-alt: \f4c6;
$fa-var-handshake-simple-slash: \e05f;
$fa-var-handshake-alt-slash: \e05f;
$fa-var-handshake-slash: \e060;
$fa-var-hanukiah: \f6e6;
$fa-var-hard-drive: \f0a0;
$fa-var-hdd: \f0a0;
$fa-var-hashtag: \23;
$fa-var-hat-cowboy: \f8c0;
$fa-var-hat-cowboy-side: \f8c1;
$fa-var-hat-wizard: \f6e8;
$fa-var-head-side-cough: \e061;
$fa-var-head-side-cough-slash: \e062;
$fa-var-head-side-mask: \e063;
$fa-var-head-side-virus: \e064;
$fa-var-heading: \f1dc;
$fa-var-header: \f1dc;
$fa-var-headphones: \f025;
$fa-var-headphones-simple: \f58f;
$fa-var-headphones-alt: \f58f;
$fa-var-headset: \f590;
$fa-var-heart: \f004;
$fa-var-heart-circle-bolt: \e4fc;
$fa-var-heart-circle-check: \e4fd;
$fa-var-heart-circle-exclamation: \e4fe;
$fa-var-heart-circle-minus: \e4ff;
$fa-var-heart-circle-plus: \e500;
$fa-var-heart-circle-xmark: \e501;
$fa-var-heart-crack: \f7a9;
$fa-var-heart-broken: \f7a9;
$fa-var-heart-pulse: \f21e;
$fa-var-heartbeat: \f21e;
$fa-var-helicopter: \f533;
$fa-var-helicopter-symbol: \e502;
$fa-var-helmet-safety: \f807;
$fa-var-hard-hat: \f807;
$fa-var-hat-hard: \f807;
$fa-var-helmet-un: \e503;
$fa-var-highlighter: \f591;
$fa-var-hill-avalanche: \e507;
$fa-var-hill-rockslide: \e508;
$fa-var-hippo: \f6ed;
$fa-var-hockey-puck: \f453;
$fa-var-holly-berry: \f7aa;
$fa-var-horse: \f6f0;
$fa-var-horse-head: \f7ab;
$fa-var-hospital: \f0f8;
$fa-var-hospital-alt: \f0f8;
$fa-var-hospital-wide: \f0f8;
$fa-var-hospital-user: \f80d;
$fa-var-hot-tub-person: \f593;
$fa-var-hot-tub: \f593;
$fa-var-hotdog: \f80f;
$fa-var-hotel: \f594;
$fa-var-hourglass: \f254;
$fa-var-hourglass-2: \f254;
$fa-var-hourglass-half: \f254;
$fa-var-hourglass-empty: \f252;
$fa-var-hourglass-end: \f253;
$fa-var-hourglass-3: \f253;
$fa-var-hourglass-start: \f251;
$fa-var-hourglass-1: \f251;
$fa-var-house: \f015;
$fa-var-home: \f015;
$fa-var-home-alt: \f015;
$fa-var-home-lg-alt: \f015;
$fa-var-house-chimney: \e3af;
$fa-var-home-lg: \e3af;
$fa-var-house-chimney-crack: \f6f1;
$fa-var-house-damage: \f6f1;
$fa-var-house-chimney-medical: \f7f2;
$fa-var-clinic-medical: \f7f2;
$fa-var-house-chimney-user: \e065;
$fa-var-house-chimney-window: \e00d;
$fa-var-house-circle-check: \e509;
$fa-var-house-circle-exclamation: \e50a;
$fa-var-house-circle-xmark: \e50b;
$fa-var-house-crack: \e3b1;
$fa-var-house-fire: \e50c;
$fa-var-house-flag: \e50d;
$fa-var-house-flood-water: \e50e;
$fa-var-house-flood-water-circle-arrow-right: \e50f;
$fa-var-house-laptop: \e066;
$fa-var-laptop-house: \e066;
$fa-var-house-lock: \e510;
$fa-var-house-medical: \e3b2;
$fa-var-house-medical-circle-check: \e511;
$fa-var-house-medical-circle-exclamation: \e512;
$fa-var-house-medical-circle-xmark: \e513;
$fa-var-house-medical-flag: \e514;
$fa-var-house-signal: \e012;
$fa-var-house-tsunami: \e515;
$fa-var-house-user: \e1b0;
$fa-var-home-user: \e1b0;
$fa-var-hryvnia-sign: \f6f2;
$fa-var-hryvnia: \f6f2;
$fa-var-hurricane: \f751;
$fa-var-i: \49;
$fa-var-i-cursor: \f246;
$fa-var-ice-cream: \f810;
$fa-var-icicles: \f7ad;
$fa-var-icons: \f86d;
$fa-var-heart-music-camera-bolt: \f86d;
$fa-var-id-badge: \f2c1;
$fa-var-id-card: \f2c2;
$fa-var-drivers-license: \f2c2;
$fa-var-id-card-clip: \f47f;
$fa-var-id-card-alt: \f47f;
$fa-var-igloo: \f7ae;
$fa-var-image: \f03e;
$fa-var-image-portrait: \f3e0;
$fa-var-portrait: \f3e0;
$fa-var-images: \f302;
$fa-var-inbox: \f01c;
$fa-var-indent: \f03c;
$fa-var-indian-rupee-sign: \e1bc;
$fa-var-indian-rupee: \e1bc;
$fa-var-inr: \e1bc;
$fa-var-industry: \f275;
$fa-var-infinity: \f534;
$fa-var-info: \f129;
$fa-var-italic: \f033;
$fa-var-j: \4a;
$fa-var-jar: \e516;
$fa-var-jar-wheat: \e517;
$fa-var-jedi: \f669;
$fa-var-jet-fighter: \f0fb;
$fa-var-fighter-jet: \f0fb;
$fa-var-jet-fighter-up: \e518;
$fa-var-joint: \f595;
$fa-var-jug-detergent: \e519;
$fa-var-k: \4b;
$fa-var-kaaba: \f66b;
$fa-var-key: \f084;
$fa-var-keyboard: \f11c;
$fa-var-khanda: \f66d;
$fa-var-kip-sign: \e1c4;
$fa-var-kit-medical: \f479;
$fa-var-first-aid: \f479;
$fa-var-kitchen-set: \e51a;
$fa-var-kiwi-bird: \f535;
$fa-var-l: \4c;
$fa-var-land-mine-on: \e51b;
$fa-var-landmark: \f66f;
$fa-var-landmark-dome: \f752;
$fa-var-landmark-alt: \f752;
$fa-var-landmark-flag: \e51c;
$fa-var-language: \f1ab;
$fa-var-laptop: \f109;
$fa-var-laptop-code: \f5fc;
$fa-var-laptop-file: \e51d;
$fa-var-laptop-medical: \f812;
$fa-var-lari-sign: \e1c8;
$fa-var-layer-group: \f5fd;
$fa-var-leaf: \f06c;
$fa-var-left-long: \f30a;
$fa-var-long-arrow-alt-left: \f30a;
$fa-var-left-right: \f337;
$fa-var-arrows-alt-h: \f337;
$fa-var-lemon: \f094;
$fa-var-less-than: \3c;
$fa-var-less-than-equal: \f537;
$fa-var-life-ring: \f1cd;
$fa-var-lightbulb: \f0eb;
$fa-var-lines-leaning: \e51e;
$fa-var-link: \f0c1;
$fa-var-chain: \f0c1;
$fa-var-link-slash: \f127;
$fa-var-chain-broken: \f127;
$fa-var-chain-slash: \f127;
$fa-var-unlink: \f127;
$fa-var-lira-sign: \f195;
$fa-var-list: \f03a;
$fa-var-list-squares: \f03a;
$fa-var-list-check: \f0ae;
$fa-var-tasks: \f0ae;
$fa-var-list-ol: \f0cb;
$fa-var-list-1-2: \f0cb;
$fa-var-list-numeric: \f0cb;
$fa-var-list-ul: \f0ca;
$fa-var-list-dots: \f0ca;
$fa-var-litecoin-sign: \e1d3;
$fa-var-location-arrow: \f124;
$fa-var-location-crosshairs: \f601;
$fa-var-location: \f601;
$fa-var-location-dot: \f3c5;
$fa-var-map-marker-alt: \f3c5;
$fa-var-location-pin: \f041;
$fa-var-map-marker: \f041;
$fa-var-location-pin-lock: \e51f;
$fa-var-lock: \f023;
$fa-var-lock-open: \f3c1;
$fa-var-locust: \e520;
$fa-var-lungs: \f604;
$fa-var-lungs-virus: \e067;
$fa-var-m: \4d;
$fa-var-magnet: \f076;
$fa-var-magnifying-glass: \f002;
$fa-var-search: \f002;
$fa-var-magnifying-glass-arrow-right: \e521;
$fa-var-magnifying-glass-chart: \e522;
$fa-var-magnifying-glass-dollar: \f688;
$fa-var-search-dollar: \f688;
$fa-var-magnifying-glass-location: \f689;
$fa-var-search-location: \f689;
$fa-var-magnifying-glass-minus: \f010;
$fa-var-search-minus: \f010;
$fa-var-magnifying-glass-plus: \f00e;
$fa-var-search-plus: \f00e;
$fa-var-manat-sign: \e1d5;
$fa-var-map: \f279;
$fa-var-map-location: \f59f;
$fa-var-map-marked: \f59f;
$fa-var-map-location-dot: \f5a0;
$fa-var-map-marked-alt: \f5a0;
$fa-var-map-pin: \f276;
$fa-var-marker: \f5a1;
$fa-var-mars: \f222;
$fa-var-mars-and-venus: \f224;
$fa-var-mars-and-venus-burst: \e523;
$fa-var-mars-double: \f227;
$fa-var-mars-stroke: \f229;
$fa-var-mars-stroke-right: \f22b;
$fa-var-mars-stroke-h: \f22b;
$fa-var-mars-stroke-up: \f22a;
$fa-var-mars-stroke-v: \f22a;
$fa-var-martini-glass: \f57b;
$fa-var-glass-martini-alt: \f57b;
$fa-var-martini-glass-citrus: \f561;
$fa-var-cocktail: \f561;
$fa-var-martini-glass-empty: \f000;
$fa-var-glass-martini: \f000;
$fa-var-mask: \f6fa;
$fa-var-mask-face: \e1d7;
$fa-var-mask-ventilator: \e524;
$fa-var-masks-theater: \f630;
$fa-var-theater-masks: \f630;
$fa-var-mattress-pillow: \e525;
$fa-var-maximize: \f31e;
$fa-var-expand-arrows-alt: \f31e;
$fa-var-medal: \f5a2;
$fa-var-memory: \f538;
$fa-var-menorah: \f676;
$fa-var-mercury: \f223;
$fa-var-message: \f27a;
$fa-var-comment-alt: \f27a;
$fa-var-meteor: \f753;
$fa-var-microchip: \f2db;
$fa-var-microphone: \f130;
$fa-var-microphone-lines: \f3c9;
$fa-var-microphone-alt: \f3c9;
$fa-var-microphone-lines-slash: \f539;
$fa-var-microphone-alt-slash: \f539;
$fa-var-microphone-slash: \f131;
$fa-var-microscope: \f610;
$fa-var-mill-sign: \e1ed;
$fa-var-minimize: \f78c;
$fa-var-compress-arrows-alt: \f78c;
$fa-var-minus: \f068;
$fa-var-subtract: \f068;
$fa-var-mitten: \f7b5;
$fa-var-mobile: \f3ce;
$fa-var-mobile-android: \f3ce;
$fa-var-mobile-phone: \f3ce;
$fa-var-mobile-button: \f10b;
$fa-var-mobile-retro: \e527;
$fa-var-mobile-screen: \f3cf;
$fa-var-mobile-android-alt: \f3cf;
$fa-var-mobile-screen-button: \f3cd;
$fa-var-mobile-alt: \f3cd;
$fa-var-money-bill: \f0d6;
$fa-var-money-bill-1: \f3d1;
$fa-var-money-bill-alt: \f3d1;
$fa-var-money-bill-1-wave: \f53b;
$fa-var-money-bill-wave-alt: \f53b;
$fa-var-money-bill-transfer: \e528;
$fa-var-money-bill-trend-up: \e529;
$fa-var-money-bill-wave: \f53a;
$fa-var-money-bill-wheat: \e52a;
$fa-var-money-bills: \e1f3;
$fa-var-money-check: \f53c;
$fa-var-money-check-dollar: \f53d;
$fa-var-money-check-alt: \f53d;
$fa-var-monument: \f5a6;
$fa-var-moon: \f186;
$fa-var-mortar-pestle: \f5a7;
$fa-var-mosque: \f678;
$fa-var-mosquito: \e52b;
$fa-var-mosquito-net: \e52c;
$fa-var-motorcycle: \f21c;
$fa-var-mound: \e52d;
$fa-var-mountain: \f6fc;
$fa-var-mountain-city: \e52e;
$fa-var-mountain-sun: \e52f;
$fa-var-mug-hot: \f7b6;
$fa-var-mug-saucer: \f0f4;
$fa-var-coffee: \f0f4;
$fa-var-music: \f001;
$fa-var-n: \4e;
$fa-var-naira-sign: \e1f6;
$fa-var-network-wired: \f6ff;
$fa-var-neuter: \f22c;
$fa-var-newspaper: \f1ea;
$fa-var-not-equal: \f53e;
$fa-var-note-sticky: \f249;
$fa-var-sticky-note: \f249;
$fa-var-notes-medical: \f481;
$fa-var-o: \4f;
$fa-var-object-group: \f247;
$fa-var-object-ungroup: \f248;
$fa-var-oil-can: \f613;
$fa-var-oil-well: \e532;
$fa-var-om: \f679;
$fa-var-otter: \f700;
$fa-var-outdent: \f03b;
$fa-var-dedent: \f03b;
$fa-var-p: \50;
$fa-var-pager: \f815;
$fa-var-paint-roller: \f5aa;
$fa-var-paintbrush: \f1fc;
$fa-var-paint-brush: \f1fc;
$fa-var-palette: \f53f;
$fa-var-pallet: \f482;
$fa-var-panorama: \e209;
$fa-var-paper-plane: \f1d8;
$fa-var-paperclip: \f0c6;
$fa-var-parachute-box: \f4cd;
$fa-var-paragraph: \f1dd;
$fa-var-passport: \f5ab;
$fa-var-paste: \f0ea;
$fa-var-file-clipboard: \f0ea;
$fa-var-pause: \f04c;
$fa-var-paw: \f1b0;
$fa-var-peace: \f67c;
$fa-var-pen: \f304;
$fa-var-pen-clip: \f305;
$fa-var-pen-alt: \f305;
$fa-var-pen-fancy: \f5ac;
$fa-var-pen-nib: \f5ad;
$fa-var-pen-ruler: \f5ae;
$fa-var-pencil-ruler: \f5ae;
$fa-var-pen-to-square: \f044;
$fa-var-edit: \f044;
$fa-var-pencil: \f303;
$fa-var-pencil-alt: \f303;
$fa-var-people-arrows-left-right: \e068;
$fa-var-people-arrows: \e068;
$fa-var-people-carry-box: \f4ce;
$fa-var-people-carry: \f4ce;
$fa-var-people-group: \e533;
$fa-var-people-line: \e534;
$fa-var-people-pulling: \e535;
$fa-var-people-robbery: \e536;
$fa-var-people-roof: \e537;
$fa-var-pepper-hot: \f816;
$fa-var-percent: \25;
$fa-var-percentage: \25;
$fa-var-person: \f183;
$fa-var-male: \f183;
$fa-var-person-arrow-down-to-line: \e538;
$fa-var-person-arrow-up-from-line: \e539;
$fa-var-person-biking: \f84a;
$fa-var-biking: \f84a;
$fa-var-person-booth: \f756;
$fa-var-person-breastfeeding: \e53a;
$fa-var-person-burst: \e53b;
$fa-var-person-cane: \e53c;
$fa-var-person-chalkboard: \e53d;
$fa-var-person-circle-check: \e53e;
$fa-var-person-circle-exclamation: \e53f;
$fa-var-person-circle-minus: \e540;
$fa-var-person-circle-plus: \e541;
$fa-var-person-circle-question: \e542;
$fa-var-person-circle-xmark: \e543;
$fa-var-person-digging: \f85e;
$fa-var-digging: \f85e;
$fa-var-person-dots-from-line: \f470;
$fa-var-diagnoses: \f470;
$fa-var-person-dress: \f182;
$fa-var-female: \f182;
$fa-var-person-dress-burst: \e544;
$fa-var-person-drowning: \e545;
$fa-var-person-falling: \e546;
$fa-var-person-falling-burst: \e547;
$fa-var-person-half-dress: \e548;
$fa-var-person-harassing: \e549;
$fa-var-person-hiking: \f6ec;
$fa-var-hiking: \f6ec;
$fa-var-person-military-pointing: \e54a;
$fa-var-person-military-rifle: \e54b;
$fa-var-person-military-to-person: \e54c;
$fa-var-person-praying: \f683;
$fa-var-pray: \f683;
$fa-var-person-pregnant: \e31e;
$fa-var-person-rays: \e54d;
$fa-var-person-rifle: \e54e;
$fa-var-person-running: \f70c;
$fa-var-running: \f70c;
$fa-var-person-shelter: \e54f;
$fa-var-person-skating: \f7c5;
$fa-var-skating: \f7c5;
$fa-var-person-skiing: \f7c9;
$fa-var-skiing: \f7c9;
$fa-var-person-skiing-nordic: \f7ca;
$fa-var-skiing-nordic: \f7ca;
$fa-var-person-snowboarding: \f7ce;
$fa-var-snowboarding: \f7ce;
$fa-var-person-swimming: \f5c4;
$fa-var-swimmer: \f5c4;
$fa-var-person-through-window: \e433;
$fa-var-person-walking: \f554;
$fa-var-walking: \f554;
$fa-var-person-walking-arrow-loop-left: \e551;
$fa-var-person-walking-arrow-right: \e552;
$fa-var-person-walking-dashed-line-arrow-right: \e553;
$fa-var-person-walking-luggage: \e554;
$fa-var-person-walking-with-cane: \f29d;
$fa-var-blind: \f29d;
$fa-var-peseta-sign: \e221;
$fa-var-peso-sign: \e222;
$fa-var-phone: \f095;
$fa-var-phone-flip: \f879;
$fa-var-phone-alt: \f879;
$fa-var-phone-slash: \f3dd;
$fa-var-phone-volume: \f2a0;
$fa-var-volume-control-phone: \f2a0;
$fa-var-photo-film: \f87c;
$fa-var-photo-video: \f87c;
$fa-var-piggy-bank: \f4d3;
$fa-var-pills: \f484;
$fa-var-pizza-slice: \f818;
$fa-var-place-of-worship: \f67f;
$fa-var-plane: \f072;
$fa-var-plane-arrival: \f5af;
$fa-var-plane-circle-check: \e555;
$fa-var-plane-circle-exclamation: \e556;
$fa-var-plane-circle-xmark: \e557;
$fa-var-plane-departure: \f5b0;
$fa-var-plane-lock: \e558;
$fa-var-plane-slash: \e069;
$fa-var-plane-up: \e22d;
$fa-var-plant-wilt: \e43b;
$fa-var-plate-wheat: \e55a;
$fa-var-play: \f04b;
$fa-var-plug: \f1e6;
$fa-var-plug-circle-bolt: \e55b;
$fa-var-plug-circle-check: \e55c;
$fa-var-plug-circle-exclamation: \e55d;
$fa-var-plug-circle-minus: \e55e;
$fa-var-plug-circle-plus: \e55f;
$fa-var-plug-circle-xmark: \e560;
$fa-var-plus: \2b;
$fa-var-add: \2b;
$fa-var-plus-minus: \e43c;
$fa-var-podcast: \f2ce;
$fa-var-poo: \f2fe;
$fa-var-poo-storm: \f75a;
$fa-var-poo-bolt: \f75a;
$fa-var-poop: \f619;
$fa-var-power-off: \f011;
$fa-var-prescription: \f5b1;
$fa-var-prescription-bottle: \f485;
$fa-var-prescription-bottle-medical: \f486;
$fa-var-prescription-bottle-alt: \f486;
$fa-var-print: \f02f;
$fa-var-pump-medical: \e06a;
$fa-var-pump-soap: \e06b;
$fa-var-puzzle-piece: \f12e;
$fa-var-q: \51;
$fa-var-qrcode: \f029;
$fa-var-question: \3f;
$fa-var-quote-left: \f10d;
$fa-var-quote-left-alt: \f10d;
$fa-var-quote-right: \f10e;
$fa-var-quote-right-alt: \f10e;
$fa-var-r: \52;
$fa-var-radiation: \f7b9;
$fa-var-radio: \f8d7;
$fa-var-rainbow: \f75b;
$fa-var-ranking-star: \e561;
$fa-var-receipt: \f543;
$fa-var-record-vinyl: \f8d9;
$fa-var-rectangle-ad: \f641;
$fa-var-ad: \f641;
$fa-var-rectangle-list: \f022;
$fa-var-list-alt: \f022;
$fa-var-rectangle-xmark: \f410;
$fa-var-rectangle-times: \f410;
$fa-var-times-rectangle: \f410;
$fa-var-window-close: \f410;
$fa-var-recycle: \f1b8;
$fa-var-registered: \f25d;
$fa-var-repeat: \f363;
$fa-var-reply: \f3e5;
$fa-var-mail-reply: \f3e5;
$fa-var-reply-all: \f122;
$fa-var-mail-reply-all: \f122;
$fa-var-republican: \f75e;
$fa-var-restroom: \f7bd;
$fa-var-retweet: \f079;
$fa-var-ribbon: \f4d6;
$fa-var-right-from-bracket: \f2f5;
$fa-var-sign-out-alt: \f2f5;
$fa-var-right-left: \f362;
$fa-var-exchange-alt: \f362;
$fa-var-right-long: \f30b;
$fa-var-long-arrow-alt-right: \f30b;
$fa-var-right-to-bracket: \f2f6;
$fa-var-sign-in-alt: \f2f6;
$fa-var-ring: \f70b;
$fa-var-road: \f018;
$fa-var-road-barrier: \e562;
$fa-var-road-bridge: \e563;
$fa-var-road-circle-check: \e564;
$fa-var-road-circle-exclamation: \e565;
$fa-var-road-circle-xmark: \e566;
$fa-var-road-lock: \e567;
$fa-var-road-spikes: \e568;
$fa-var-robot: \f544;
$fa-var-rocket: \f135;
$fa-var-rotate: \f2f1;
$fa-var-sync-alt: \f2f1;
$fa-var-rotate-left: \f2ea;
$fa-var-rotate-back: \f2ea;
$fa-var-rotate-backward: \f2ea;
$fa-var-undo-alt: \f2ea;
$fa-var-rotate-right: \f2f9;
$fa-var-redo-alt: \f2f9;
$fa-var-rotate-forward: \f2f9;
$fa-var-route: \f4d7;
$fa-var-rss: \f09e;
$fa-var-feed: \f09e;
$fa-var-ruble-sign: \f158;
$fa-var-rouble: \f158;
$fa-var-rub: \f158;
$fa-var-ruble: \f158;
$fa-var-rug: \e569;
$fa-var-ruler: \f545;
$fa-var-ruler-combined: \f546;
$fa-var-ruler-horizontal: \f547;
$fa-var-ruler-vertical: \f548;
$fa-var-rupee-sign: \f156;
$fa-var-rupee: \f156;
$fa-var-rupiah-sign: \e23d;
$fa-var-s: \53;
$fa-var-sack-dollar: \f81d;
$fa-var-sack-xmark: \e56a;
$fa-var-sailboat: \e445;
$fa-var-satellite: \f7bf;
$fa-var-satellite-dish: \f7c0;
$fa-var-scale-balanced: \f24e;
$fa-var-balance-scale: \f24e;
$fa-var-scale-unbalanced: \f515;
$fa-var-balance-scale-left: \f515;
$fa-var-scale-unbalanced-flip: \f516;
$fa-var-balance-scale-right: \f516;
$fa-var-school: \f549;
$fa-var-school-circle-check: \e56b;
$fa-var-school-circle-exclamation: \e56c;
$fa-var-school-circle-xmark: \e56d;
$fa-var-school-flag: \e56e;
$fa-var-school-lock: \e56f;
$fa-var-scissors: \f0c4;
$fa-var-cut: \f0c4;
$fa-var-screwdriver: \f54a;
$fa-var-screwdriver-wrench: \f7d9;
$fa-var-tools: \f7d9;
$fa-var-scroll: \f70e;
$fa-var-scroll-torah: \f6a0;
$fa-var-torah: \f6a0;
$fa-var-sd-card: \f7c2;
$fa-var-section: \e447;
$fa-var-seedling: \f4d8;
$fa-var-sprout: \f4d8;
$fa-var-server: \f233;
$fa-var-shapes: \f61f;
$fa-var-triangle-circle-square: \f61f;
$fa-var-share: \f064;
$fa-var-arrow-turn-right: \f064;
$fa-var-mail-forward: \f064;
$fa-var-share-from-square: \f14d;
$fa-var-share-square: \f14d;
$fa-var-share-nodes: \f1e0;
$fa-var-share-alt: \f1e0;
$fa-var-sheet-plastic: \e571;
$fa-var-shekel-sign: \f20b;
$fa-var-ils: \f20b;
$fa-var-shekel: \f20b;
$fa-var-sheqel: \f20b;
$fa-var-sheqel-sign: \f20b;
$fa-var-shield: \f132;
$fa-var-shield-blank: \f132;
$fa-var-shield-cat: \e572;
$fa-var-shield-dog: \e573;
$fa-var-shield-halved: \f3ed;
$fa-var-shield-alt: \f3ed;
$fa-var-shield-heart: \e574;
$fa-var-shield-virus: \e06c;
$fa-var-ship: \f21a;
$fa-var-shirt: \f553;
$fa-var-t-shirt: \f553;
$fa-var-tshirt: \f553;
$fa-var-shoe-prints: \f54b;
$fa-var-shop: \f54f;
$fa-var-store-alt: \f54f;
$fa-var-shop-lock: \e4a5;
$fa-var-shop-slash: \e070;
$fa-var-store-alt-slash: \e070;
$fa-var-shower: \f2cc;
$fa-var-shrimp: \e448;
$fa-var-shuffle: \f074;
$fa-var-random: \f074;
$fa-var-shuttle-space: \f197;
$fa-var-space-shuttle: \f197;
$fa-var-sign-hanging: \f4d9;
$fa-var-sign: \f4d9;
$fa-var-signal: \f012;
$fa-var-signal-5: \f012;
$fa-var-signal-perfect: \f012;
$fa-var-signature: \f5b7;
$fa-var-signs-post: \f277;
$fa-var-map-signs: \f277;
$fa-var-sim-card: \f7c4;
$fa-var-sink: \e06d;
$fa-var-sitemap: \f0e8;
$fa-var-skull: \f54c;
$fa-var-skull-crossbones: \f714;
$fa-var-slash: \f715;
$fa-var-sleigh: \f7cc;
$fa-var-sliders: \f1de;
$fa-var-sliders-h: \f1de;
$fa-var-smog: \f75f;
$fa-var-smoking: \f48d;
$fa-var-snowflake: \f2dc;
$fa-var-snowman: \f7d0;
$fa-var-snowplow: \f7d2;
$fa-var-soap: \e06e;
$fa-var-socks: \f696;
$fa-var-solar-panel: \f5ba;
$fa-var-sort: \f0dc;
$fa-var-unsorted: \f0dc;
$fa-var-sort-down: \f0dd;
$fa-var-sort-desc: \f0dd;
$fa-var-sort-up: \f0de;
$fa-var-sort-asc: \f0de;
$fa-var-spa: \f5bb;
$fa-var-spaghetti-monster-flying: \f67b;
$fa-var-pastafarianism: \f67b;
$fa-var-spell-check: \f891;
$fa-var-spider: \f717;
$fa-var-spinner: \f110;
$fa-var-splotch: \f5bc;
$fa-var-spoon: \f2e5;
$fa-var-utensil-spoon: \f2e5;
$fa-var-spray-can: \f5bd;
$fa-var-spray-can-sparkles: \f5d0;
$fa-var-air-freshener: \f5d0;
$fa-var-square: \f0c8;
$fa-var-square-arrow-up-right: \f14c;
$fa-var-external-link-square: \f14c;
$fa-var-square-caret-down: \f150;
$fa-var-caret-square-down: \f150;
$fa-var-square-caret-left: \f191;
$fa-var-caret-square-left: \f191;
$fa-var-square-caret-right: \f152;
$fa-var-caret-square-right: \f152;
$fa-var-square-caret-up: \f151;
$fa-var-caret-square-up: \f151;
$fa-var-square-check: \f14a;
$fa-var-check-square: \f14a;
$fa-var-square-envelope: \f199;
$fa-var-envelope-square: \f199;
$fa-var-square-full: \f45c;
$fa-var-square-h: \f0fd;
$fa-var-h-square: \f0fd;
$fa-var-square-minus: \f146;
$fa-var-minus-square: \f146;
$fa-var-square-nfi: \e576;
$fa-var-square-parking: \f540;
$fa-var-parking: \f540;
$fa-var-square-pen: \f14b;
$fa-var-pen-square: \f14b;
$fa-var-pencil-square: \f14b;
$fa-var-square-person-confined: \e577;
$fa-var-square-phone: \f098;
$fa-var-phone-square: \f098;
$fa-var-square-phone-flip: \f87b;
$fa-var-phone-square-alt: \f87b;
$fa-var-square-plus: \f0fe;
$fa-var-plus-square: \f0fe;
$fa-var-square-poll-horizontal: \f682;
$fa-var-poll-h: \f682;
$fa-var-square-poll-vertical: \f681;
$fa-var-poll: \f681;
$fa-var-square-root-variable: \f698;
$fa-var-square-root-alt: \f698;
$fa-var-square-rss: \f143;
$fa-var-rss-square: \f143;
$fa-var-square-share-nodes: \f1e1;
$fa-var-share-alt-square: \f1e1;
$fa-var-square-up-right: \f360;
$fa-var-external-link-square-alt: \f360;
$fa-var-square-virus: \e578;
$fa-var-square-xmark: \f2d3;
$fa-var-times-square: \f2d3;
$fa-var-xmark-square: \f2d3;
$fa-var-staff-aesculapius: \e579;
$fa-var-rod-asclepius: \e579;
$fa-var-rod-snake: \e579;
$fa-var-staff-snake: \e579;
$fa-var-stairs: \e289;
$fa-var-stamp: \f5bf;
$fa-var-star: \f005;
$fa-var-star-and-crescent: \f699;
$fa-var-star-half: \f089;
$fa-var-star-half-stroke: \f5c0;
$fa-var-star-half-alt: \f5c0;
$fa-var-star-of-david: \f69a;
$fa-var-star-of-life: \f621;
$fa-var-sterling-sign: \f154;
$fa-var-gbp: \f154;
$fa-var-pound-sign: \f154;
$fa-var-stethoscope: \f0f1;
$fa-var-stop: \f04d;
$fa-var-stopwatch: \f2f2;
$fa-var-stopwatch-20: \e06f;
$fa-var-store: \f54e;
$fa-var-store-slash: \e071;
$fa-var-street-view: \f21d;
$fa-var-strikethrough: \f0cc;
$fa-var-stroopwafel: \f551;
$fa-var-subscript: \f12c;
$fa-var-suitcase: \f0f2;
$fa-var-suitcase-medical: \f0fa;
$fa-var-medkit: \f0fa;
$fa-var-suitcase-rolling: \f5c1;
$fa-var-sun: \f185;
$fa-var-sun-plant-wilt: \e57a;
$fa-var-superscript: \f12b;
$fa-var-swatchbook: \f5c3;
$fa-var-synagogue: \f69b;
$fa-var-syringe: \f48e;
$fa-var-t: \54;
$fa-var-table: \f0ce;
$fa-var-table-cells: \f00a;
$fa-var-th: \f00a;
$fa-var-table-cells-large: \f009;
$fa-var-th-large: \f009;
$fa-var-table-columns: \f0db;
$fa-var-columns: \f0db;
$fa-var-table-list: \f00b;
$fa-var-th-list: \f00b;
$fa-var-table-tennis-paddle-ball: \f45d;
$fa-var-ping-pong-paddle-ball: \f45d;
$fa-var-table-tennis: \f45d;
$fa-var-tablet: \f3fb;
$fa-var-tablet-android: \f3fb;
$fa-var-tablet-button: \f10a;
$fa-var-tablet-screen-button: \f3fa;
$fa-var-tablet-alt: \f3fa;
$fa-var-tablets: \f490;
$fa-var-tachograph-digital: \f566;
$fa-var-digital-tachograph: \f566;
$fa-var-tag: \f02b;
$fa-var-tags: \f02c;
$fa-var-tape: \f4db;
$fa-var-tarp: \e57b;
$fa-var-tarp-droplet: \e57c;
$fa-var-taxi: \f1ba;
$fa-var-cab: \f1ba;
$fa-var-teeth: \f62e;
$fa-var-teeth-open: \f62f;
$fa-var-temperature-arrow-down: \e03f;
$fa-var-temperature-down: \e03f;
$fa-var-temperature-arrow-up: \e040;
$fa-var-temperature-up: \e040;
$fa-var-temperature-empty: \f2cb;
$fa-var-temperature-0: \f2cb;
$fa-var-thermometer-0: \f2cb;
$fa-var-thermometer-empty: \f2cb;
$fa-var-temperature-full: \f2c7;
$fa-var-temperature-4: \f2c7;
$fa-var-thermometer-4: \f2c7;
$fa-var-thermometer-full: \f2c7;
$fa-var-temperature-half: \f2c9;
$fa-var-temperature-2: \f2c9;
$fa-var-thermometer-2: \f2c9;
$fa-var-thermometer-half: \f2c9;
$fa-var-temperature-high: \f769;
$fa-var-temperature-low: \f76b;
$fa-var-temperature-quarter: \f2ca;
$fa-var-temperature-1: \f2ca;
$fa-var-thermometer-1: \f2ca;
$fa-var-thermometer-quarter: \f2ca;
$fa-var-temperature-three-quarters: \f2c8;
$fa-var-temperature-3: \f2c8;
$fa-var-thermometer-3: \f2c8;
$fa-var-thermometer-three-quarters: \f2c8;
$fa-var-tenge-sign: \f7d7;
$fa-var-tenge: \f7d7;
$fa-var-tent: \e57d;
$fa-var-tent-arrow-down-to-line: \e57e;
$fa-var-tent-arrow-left-right: \e57f;
$fa-var-tent-arrow-turn-left: \e580;
$fa-var-tent-arrows-down: \e581;
$fa-var-tents: \e582;
$fa-var-terminal: \f120;
$fa-var-text-height: \f034;
$fa-var-text-slash: \f87d;
$fa-var-remove-format: \f87d;
$fa-var-text-width: \f035;
$fa-var-thermometer: \f491;
$fa-var-thumbs-down: \f165;
$fa-var-thumbs-up: \f164;
$fa-var-thumbtack: \f08d;
$fa-var-thumb-tack: \f08d;
$fa-var-ticket: \f145;
$fa-var-ticket-simple: \f3ff;
$fa-var-ticket-alt: \f3ff;
$fa-var-timeline: \e29c;
$fa-var-toggle-off: \f204;
$fa-var-toggle-on: \f205;
$fa-var-toilet: \f7d8;
$fa-var-toilet-paper: \f71e;
$fa-var-toilet-paper-slash: \e072;
$fa-var-toilet-portable: \e583;
$fa-var-toilets-portable: \e584;
$fa-var-toolbox: \f552;
$fa-var-tooth: \f5c9;
$fa-var-torii-gate: \f6a1;
$fa-var-tornado: \f76f;
$fa-var-tower-broadcast: \f519;
$fa-var-broadcast-tower: \f519;
$fa-var-tower-cell: \e585;
$fa-var-tower-observation: \e586;
$fa-var-tractor: \f722;
$fa-var-trademark: \f25c;
$fa-var-traffic-light: \f637;
$fa-var-trailer: \e041;
$fa-var-train: \f238;
$fa-var-train-subway: \f239;
$fa-var-subway: \f239;
$fa-var-train-tram: \f7da;
$fa-var-tram: \f7da;
$fa-var-transgender: \f225;
$fa-var-transgender-alt: \f225;
$fa-var-trash: \f1f8;
$fa-var-trash-arrow-up: \f829;
$fa-var-trash-restore: \f829;
$fa-var-trash-can: \f2ed;
$fa-var-trash-alt: \f2ed;
$fa-var-trash-can-arrow-up: \f82a;
$fa-var-trash-restore-alt: \f82a;
$fa-var-tree: \f1bb;
$fa-var-tree-city: \e587;
$fa-var-triangle-exclamation: \f071;
$fa-var-exclamation-triangle: \f071;
$fa-var-warning: \f071;
$fa-var-trophy: \f091;
$fa-var-trowel: \e589;
$fa-var-trowel-bricks: \e58a;
$fa-var-truck: \f0d1;
$fa-var-truck-arrow-right: \e58b;
$fa-var-truck-droplet: \e58c;
$fa-var-truck-fast: \f48b;
$fa-var-shipping-fast: \f48b;
$fa-var-truck-field: \e58d;
$fa-var-truck-field-un: \e58e;
$fa-var-truck-front: \e2b7;
$fa-var-truck-medical: \f0f9;
$fa-var-ambulance: \f0f9;
$fa-var-truck-monster: \f63b;
$fa-var-truck-moving: \f4df;
$fa-var-truck-pickup: \f63c;
$fa-var-truck-plane: \e58f;
$fa-var-truck-ramp-box: \f4de;
$fa-var-truck-loading: \f4de;
$fa-var-tty: \f1e4;
$fa-var-teletype: \f1e4;
$fa-var-turkish-lira-sign: \e2bb;
$fa-var-try: \e2bb;
$fa-var-turkish-lira: \e2bb;
$fa-var-turn-down: \f3be;
$fa-var-level-down-alt: \f3be;
$fa-var-turn-up: \f3bf;
$fa-var-level-up-alt: \f3bf;
$fa-var-tv: \f26c;
$fa-var-television: \f26c;
$fa-var-tv-alt: \f26c;
$fa-var-u: \55;
$fa-var-umbrella: \f0e9;
$fa-var-umbrella-beach: \f5ca;
$fa-var-underline: \f0cd;
$fa-var-universal-access: \f29a;
$fa-var-unlock: \f09c;
$fa-var-unlock-keyhole: \f13e;
$fa-var-unlock-alt: \f13e;
$fa-var-up-down: \f338;
$fa-var-arrows-alt-v: \f338;
$fa-var-up-down-left-right: \f0b2;
$fa-var-arrows-alt: \f0b2;
$fa-var-up-long: \f30c;
$fa-var-long-arrow-alt-up: \f30c;
$fa-var-up-right-and-down-left-from-center: \f424;
$fa-var-expand-alt: \f424;
$fa-var-up-right-from-square: \f35d;
$fa-var-external-link-alt: \f35d;
$fa-var-upload: \f093;
$fa-var-user: \f007;
$fa-var-user-astronaut: \f4fb;
$fa-var-user-check: \f4fc;
$fa-var-user-clock: \f4fd;
$fa-var-user-doctor: \f0f0;
$fa-var-user-md: \f0f0;
$fa-var-user-gear: \f4fe;
$fa-var-user-cog: \f4fe;
$fa-var-user-graduate: \f501;
$fa-var-user-group: \f500;
$fa-var-user-friends: \f500;
$fa-var-user-injured: \f728;
$fa-var-user-large: \f406;
$fa-var-user-alt: \f406;
$fa-var-user-large-slash: \f4fa;
$fa-var-user-alt-slash: \f4fa;
$fa-var-user-lock: \f502;
$fa-var-user-minus: \f503;
$fa-var-user-ninja: \f504;
$fa-var-user-nurse: \f82f;
$fa-var-user-pen: \f4ff;
$fa-var-user-edit: \f4ff;
$fa-var-user-plus: \f234;
$fa-var-user-secret: \f21b;
$fa-var-user-shield: \f505;
$fa-var-user-slash: \f506;
$fa-var-user-tag: \f507;
$fa-var-user-tie: \f508;
$fa-var-user-xmark: \f235;
$fa-var-user-times: \f235;
$fa-var-users: \f0c0;
$fa-var-users-between-lines: \e591;
$fa-var-users-gear: \f509;
$fa-var-users-cog: \f509;
$fa-var-users-line: \e592;
$fa-var-users-rays: \e593;
$fa-var-users-rectangle: \e594;
$fa-var-users-slash: \e073;
$fa-var-users-viewfinder: \e595;
$fa-var-utensils: \f2e7;
$fa-var-cutlery: \f2e7;
$fa-var-v: \56;
$fa-var-van-shuttle: \f5b6;
$fa-var-shuttle-van: \f5b6;
$fa-var-vault: \e2c5;
$fa-var-vector-square: \f5cb;
$fa-var-venus: \f221;
$fa-var-venus-double: \f226;
$fa-var-venus-mars: \f228;
$fa-var-vest: \e085;
$fa-var-vest-patches: \e086;
$fa-var-vial: \f492;
$fa-var-vial-circle-check: \e596;
$fa-var-vial-virus: \e597;
$fa-var-vials: \f493;
$fa-var-video: \f03d;
$fa-var-video-camera: \f03d;
$fa-var-video-slash: \f4e2;
$fa-var-vihara: \f6a7;
$fa-var-virus: \e074;
$fa-var-virus-covid: \e4a8;
$fa-var-virus-covid-slash: \e4a9;
$fa-var-virus-slash: \e075;
$fa-var-viruses: \e076;
$fa-var-voicemail: \f897;
$fa-var-volcano: \f770;
$fa-var-volleyball: \f45f;
$fa-var-volleyball-ball: \f45f;
$fa-var-volume-high: \f028;
$fa-var-volume-up: \f028;
$fa-var-volume-low: \f027;
$fa-var-volume-down: \f027;
$fa-var-volume-off: \f026;
$fa-var-volume-xmark: \f6a9;
$fa-var-volume-mute: \f6a9;
$fa-var-volume-times: \f6a9;
$fa-var-vr-cardboard: \f729;
$fa-var-w: \57;
$fa-var-walkie-talkie: \f8ef;
$fa-var-wallet: \f555;
$fa-var-wand-magic: \f0d0;
$fa-var-magic: \f0d0;
$fa-var-wand-magic-sparkles: \e2ca;
$fa-var-magic-wand-sparkles: \e2ca;
$fa-var-wand-sparkles: \f72b;
$fa-var-warehouse: \f494;
$fa-var-water: \f773;
$fa-var-water-ladder: \f5c5;
$fa-var-ladder-water: \f5c5;
$fa-var-swimming-pool: \f5c5;
$fa-var-wave-square: \f83e;
$fa-var-weight-hanging: \f5cd;
$fa-var-weight-scale: \f496;
$fa-var-weight: \f496;
$fa-var-wheat-awn: \e2cd;
$fa-var-wheat-alt: \e2cd;
$fa-var-wheat-awn-circle-exclamation: \e598;
$fa-var-wheelchair: \f193;
$fa-var-wheelchair-move: \e2ce;
$fa-var-wheelchair-alt: \e2ce;
$fa-var-whiskey-glass: \f7a0;
$fa-var-glass-whiskey: \f7a0;
$fa-var-wifi: \f1eb;
$fa-var-wifi-3: \f1eb;
$fa-var-wifi-strong: \f1eb;
$fa-var-wind: \f72e;
$fa-var-window-maximize: \f2d0;
$fa-var-window-minimize: \f2d1;
$fa-var-window-restore: \f2d2;
$fa-var-wine-bottle: \f72f;
$fa-var-wine-glass: \f4e3;
$fa-var-wine-glass-empty: \f5ce;
$fa-var-wine-glass-alt: \f5ce;
$fa-var-won-sign: \f159;
$fa-var-krw: \f159;
$fa-var-won: \f159;
$fa-var-worm: \e599;
$fa-var-wrench: \f0ad;
$fa-var-x: \58;
$fa-var-x-ray: \f497;
$fa-var-xmark: \f00d;
$fa-var-close: \f00d;
$fa-var-multiply: \f00d;
$fa-var-remove: \f00d;
$fa-var-times: \f00d;
$fa-var-xmarks-lines: \e59a;
$fa-var-y: \59;
$fa-var-yen-sign: \f157;
$fa-var-cny: \f157;
$fa-var-jpy: \f157;
$fa-var-rmb: \f157;
$fa-var-yen: \f157;
$fa-var-yin-yang: \f6ad;
$fa-var-z: \5a;

$fa-var-42-group: \e080;
$fa-var-innosoft: \e080;
$fa-var-500px: \f26e;
$fa-var-accessible-icon: \f368;
$fa-var-accusoft: \f369;
$fa-var-adn: \f170;
$fa-var-adversal: \f36a;
$fa-var-affiliatetheme: \f36b;
$fa-var-airbnb: \f834;
$fa-var-algolia: \f36c;
$fa-var-alipay: \f642;
$fa-var-amazon: \f270;
$fa-var-amazon-pay: \f42c;
$fa-var-amilia: \f36d;
$fa-var-android: \f17b;
$fa-var-angellist: \f209;
$fa-var-angrycreative: \f36e;
$fa-var-angular: \f420;
$fa-var-app-store: \f36f;
$fa-var-app-store-ios: \f370;
$fa-var-apper: \f371;
$fa-var-apple: \f179;
$fa-var-apple-pay: \f415;
$fa-var-artstation: \f77a;
$fa-var-asymmetrik: \f372;
$fa-var-atlassian: \f77b;
$fa-var-audible: \f373;
$fa-var-autoprefixer: \f41c;
$fa-var-avianex: \f374;
$fa-var-aviato: \f421;
$fa-var-aws: \f375;
$fa-var-bandcamp: \f2d5;
$fa-var-battle-net: \f835;
$fa-var-behance: \f1b4;
$fa-var-behance-square: \f1b5;
$fa-var-bilibili: \e3d9;
$fa-var-bimobject: \f378;
$fa-var-bitbucket: \f171;
$fa-var-bitcoin: \f379;
$fa-var-bity: \f37a;
$fa-var-black-tie: \f27e;
$fa-var-blackberry: \f37b;
$fa-var-blogger: \f37c;
$fa-var-blogger-b: \f37d;
$fa-var-bluetooth: \f293;
$fa-var-bluetooth-b: \f294;
$fa-var-bootstrap: \f836;
$fa-var-bots: \e340;
$fa-var-btc: \f15a;
$fa-var-buffer: \f837;
$fa-var-buromobelexperte: \f37f;
$fa-var-buy-n-large: \f8a6;
$fa-var-buysellads: \f20d;
$fa-var-canadian-maple-leaf: \f785;
$fa-var-cc-amazon-pay: \f42d;
$fa-var-cc-amex: \f1f3;
$fa-var-cc-apple-pay: \f416;
$fa-var-cc-diners-club: \f24c;
$fa-var-cc-discover: \f1f2;
$fa-var-cc-jcb: \f24b;
$fa-var-cc-mastercard: \f1f1;
$fa-var-cc-paypal: \f1f4;
$fa-var-cc-stripe: \f1f5;
$fa-var-cc-visa: \f1f0;
$fa-var-centercode: \f380;
$fa-var-centos: \f789;
$fa-var-chrome: \f268;
$fa-var-chromecast: \f838;
$fa-var-cloudflare: \e07d;
$fa-var-cloudscale: \f383;
$fa-var-cloudsmith: \f384;
$fa-var-cloudversify: \f385;
$fa-var-cmplid: \e360;
$fa-var-codepen: \f1cb;
$fa-var-codiepie: \f284;
$fa-var-confluence: \f78d;
$fa-var-connectdevelop: \f20e;
$fa-var-contao: \f26d;
$fa-var-cotton-bureau: \f89e;
$fa-var-cpanel: \f388;
$fa-var-creative-commons: \f25e;
$fa-var-creative-commons-by: \f4e7;
$fa-var-creative-commons-nc: \f4e8;
$fa-var-creative-commons-nc-eu: \f4e9;
$fa-var-creative-commons-nc-jp: \f4ea;
$fa-var-creative-commons-nd: \f4eb;
$fa-var-creative-commons-pd: \f4ec;
$fa-var-creative-commons-pd-alt: \f4ed;
$fa-var-creative-commons-remix: \f4ee;
$fa-var-creative-commons-sa: \f4ef;
$fa-var-creative-commons-sampling: \f4f0;
$fa-var-creative-commons-sampling-plus: \f4f1;
$fa-var-creative-commons-share: \f4f2;
$fa-var-creative-commons-zero: \f4f3;
$fa-var-critical-role: \f6c9;
$fa-var-css3: \f13c;
$fa-var-css3-alt: \f38b;
$fa-var-cuttlefish: \f38c;
$fa-var-d-and-d: \f38d;
$fa-var-d-and-d-beyond: \f6ca;
$fa-var-dailymotion: \e052;
$fa-var-dashcube: \f210;
$fa-var-deezer: \e077;
$fa-var-delicious: \f1a5;
$fa-var-deploydog: \f38e;
$fa-var-deskpro: \f38f;
$fa-var-dev: \f6cc;
$fa-var-deviantart: \f1bd;
$fa-var-dhl: \f790;
$fa-var-diaspora: \f791;
$fa-var-digg: \f1a6;
$fa-var-digital-ocean: \f391;
$fa-var-discord: \f392;
$fa-var-discourse: \f393;
$fa-var-dochub: \f394;
$fa-var-docker: \f395;
$fa-var-draft2digital: \f396;
$fa-var-dribbble: \f17d;
$fa-var-dribbble-square: \f397;
$fa-var-dropbox: \f16b;
$fa-var-drupal: \f1a9;
$fa-var-dyalog: \f399;
$fa-var-earlybirds: \f39a;
$fa-var-ebay: \f4f4;
$fa-var-edge: \f282;
$fa-var-edge-legacy: \e078;
$fa-var-elementor: \f430;
$fa-var-ello: \f5f1;
$fa-var-ember: \f423;
$fa-var-empire: \f1d1;
$fa-var-envira: \f299;
$fa-var-erlang: \f39d;
$fa-var-ethereum: \f42e;
$fa-var-etsy: \f2d7;
$fa-var-evernote: \f839;
$fa-var-expeditedssl: \f23e;
$fa-var-facebook: \f09a;
$fa-var-facebook-f: \f39e;
$fa-var-facebook-messenger: \f39f;
$fa-var-facebook-square: \f082;
$fa-var-fantasy-flight-games: \f6dc;
$fa-var-fedex: \f797;
$fa-var-fedora: \f798;
$fa-var-figma: \f799;
$fa-var-firefox: \f269;
$fa-var-firefox-browser: \e007;
$fa-var-first-order: \f2b0;
$fa-var-first-order-alt: \f50a;
$fa-var-firstdraft: \f3a1;
$fa-var-flickr: \f16e;
$fa-var-flipboard: \f44d;
$fa-var-fly: \f417;
$fa-var-font-awesome: \f2b4;
$fa-var-font-awesome-flag: \f2b4;
$fa-var-font-awesome-logo-full: \f2b4;
$fa-var-fonticons: \f280;
$fa-var-fonticons-fi: \f3a2;
$fa-var-fort-awesome: \f286;
$fa-var-fort-awesome-alt: \f3a3;
$fa-var-forumbee: \f211;
$fa-var-foursquare: \f180;
$fa-var-free-code-camp: \f2c5;
$fa-var-freebsd: \f3a4;
$fa-var-fulcrum: \f50b;
$fa-var-galactic-republic: \f50c;
$fa-var-galactic-senate: \f50d;
$fa-var-get-pocket: \f265;
$fa-var-gg: \f260;
$fa-var-gg-circle: \f261;
$fa-var-git: \f1d3;
$fa-var-git-alt: \f841;
$fa-var-git-square: \f1d2;
$fa-var-github: \f09b;
$fa-var-github-alt: \f113;
$fa-var-github-square: \f092;
$fa-var-gitkraken: \f3a6;
$fa-var-gitlab: \f296;
$fa-var-gitter: \f426;
$fa-var-glide: \f2a5;
$fa-var-glide-g: \f2a6;
$fa-var-gofore: \f3a7;
$fa-var-golang: \e40f;
$fa-var-goodreads: \f3a8;
$fa-var-goodreads-g: \f3a9;
$fa-var-google: \f1a0;
$fa-var-google-drive: \f3aa;
$fa-var-google-pay: \e079;
$fa-var-google-play: \f3ab;
$fa-var-google-plus: \f2b3;
$fa-var-google-plus-g: \f0d5;
$fa-var-google-plus-square: \f0d4;
$fa-var-google-wallet: \f1ee;
$fa-var-gratipay: \f184;
$fa-var-grav: \f2d6;
$fa-var-gripfire: \f3ac;
$fa-var-grunt: \f3ad;
$fa-var-guilded: \e07e;
$fa-var-gulp: \f3ae;
$fa-var-hacker-news: \f1d4;
$fa-var-hacker-news-square: \f3af;
$fa-var-hackerrank: \f5f7;
$fa-var-hashnode: \e499;
$fa-var-hips: \f452;
$fa-var-hire-a-helper: \f3b0;
$fa-var-hive: \e07f;
$fa-var-hooli: \f427;
$fa-var-hornbill: \f592;
$fa-var-hotjar: \f3b1;
$fa-var-houzz: \f27c;
$fa-var-html5: \f13b;
$fa-var-hubspot: \f3b2;
$fa-var-ideal: \e013;
$fa-var-imdb: \f2d8;
$fa-var-instagram: \f16d;
$fa-var-instagram-square: \e055;
$fa-var-instalod: \e081;
$fa-var-intercom: \f7af;
$fa-var-internet-explorer: \f26b;
$fa-var-invision: \f7b0;
$fa-var-ioxhost: \f208;
$fa-var-itch-io: \f83a;
$fa-var-itunes: \f3b4;
$fa-var-itunes-note: \f3b5;
$fa-var-java: \f4e4;
$fa-var-jedi-order: \f50e;
$fa-var-jenkins: \f3b6;
$fa-var-jira: \f7b1;
$fa-var-joget: \f3b7;
$fa-var-joomla: \f1aa;
$fa-var-js: \f3b8;
$fa-var-js-square: \f3b9;
$fa-var-jsfiddle: \f1cc;
$fa-var-kaggle: \f5fa;
$fa-var-keybase: \f4f5;
$fa-var-keycdn: \f3ba;
$fa-var-kickstarter: \f3bb;
$fa-var-kickstarter-k: \f3bc;
$fa-var-korvue: \f42f;
$fa-var-laravel: \f3bd;
$fa-var-lastfm: \f202;
$fa-var-lastfm-square: \f203;
$fa-var-leanpub: \f212;
$fa-var-less: \f41d;
$fa-var-line: \f3c0;
$fa-var-linkedin: \f08c;
$fa-var-linkedin-in: \f0e1;
$fa-var-linode: \f2b8;
$fa-var-linux: \f17c;
$fa-var-lyft: \f3c3;
$fa-var-magento: \f3c4;
$fa-var-mailchimp: \f59e;
$fa-var-mandalorian: \f50f;
$fa-var-markdown: \f60f;
$fa-var-mastodon: \f4f6;
$fa-var-maxcdn: \f136;
$fa-var-mdb: \f8ca;
$fa-var-medapps: \f3c6;
$fa-var-medium: \f23a;
$fa-var-medium-m: \f23a;
$fa-var-medrt: \f3c8;
$fa-var-meetup: \f2e0;
$fa-var-megaport: \f5a3;
$fa-var-mendeley: \f7b3;
$fa-var-microblog: \e01a;
$fa-var-microsoft: \f3ca;
$fa-var-mix: \f3cb;
$fa-var-mixcloud: \f289;
$fa-var-mixer: \e056;
$fa-var-mizuni: \f3cc;
$fa-var-modx: \f285;
$fa-var-monero: \f3d0;
$fa-var-napster: \f3d2;
$fa-var-neos: \f612;
$fa-var-nfc-directional: \e530;
$fa-var-nfc-symbol: \e531;
$fa-var-nimblr: \f5a8;
$fa-var-node: \f419;
$fa-var-node-js: \f3d3;
$fa-var-npm: \f3d4;
$fa-var-ns8: \f3d5;
$fa-var-nutritionix: \f3d6;
$fa-var-octopus-deploy: \e082;
$fa-var-odnoklassniki: \f263;
$fa-var-odnoklassniki-square: \f264;
$fa-var-old-republic: \f510;
$fa-var-opencart: \f23d;
$fa-var-openid: \f19b;
$fa-var-opera: \f26a;
$fa-var-optin-monster: \f23c;
$fa-var-orcid: \f8d2;
$fa-var-osi: \f41a;
$fa-var-padlet: \e4a0;
$fa-var-page4: \f3d7;
$fa-var-pagelines: \f18c;
$fa-var-palfed: \f3d8;
$fa-var-patreon: \f3d9;
$fa-var-paypal: \f1ed;
$fa-var-perbyte: \e083;
$fa-var-periscope: \f3da;
$fa-var-phabricator: \f3db;
$fa-var-phoenix-framework: \f3dc;
$fa-var-phoenix-squadron: \f511;
$fa-var-php: \f457;
$fa-var-pied-piper: \f2ae;
$fa-var-pied-piper-alt: \f1a8;
$fa-var-pied-piper-hat: \f4e5;
$fa-var-pied-piper-pp: \f1a7;
$fa-var-pied-piper-square: \e01e;
$fa-var-pinterest: \f0d2;
$fa-var-pinterest-p: \f231;
$fa-var-pinterest-square: \f0d3;
$fa-var-pix: \e43a;
$fa-var-playstation: \f3df;
$fa-var-product-hunt: \f288;
$fa-var-pushed: \f3e1;
$fa-var-python: \f3e2;
$fa-var-qq: \f1d6;
$fa-var-quinscape: \f459;
$fa-var-quora: \f2c4;
$fa-var-r-project: \f4f7;
$fa-var-raspberry-pi: \f7bb;
$fa-var-ravelry: \f2d9;
$fa-var-react: \f41b;
$fa-var-reacteurope: \f75d;
$fa-var-readme: \f4d5;
$fa-var-rebel: \f1d0;
$fa-var-red-river: \f3e3;
$fa-var-reddit: \f1a1;
$fa-var-reddit-alien: \f281;
$fa-var-reddit-square: \f1a2;
$fa-var-redhat: \f7bc;
$fa-var-renren: \f18b;
$fa-var-replyd: \f3e6;
$fa-var-researchgate: \f4f8;
$fa-var-resolving: \f3e7;
$fa-var-rev: \f5b2;
$fa-var-rocketchat: \f3e8;
$fa-var-rockrms: \f3e9;
$fa-var-rust: \e07a;
$fa-var-safari: \f267;
$fa-var-salesforce: \f83b;
$fa-var-sass: \f41e;
$fa-var-schlix: \f3ea;
$fa-var-screenpal: \e570;
$fa-var-scribd: \f28a;
$fa-var-searchengin: \f3eb;
$fa-var-sellcast: \f2da;
$fa-var-sellsy: \f213;
$fa-var-servicestack: \f3ec;
$fa-var-shirtsinbulk: \f214;
$fa-var-shopify: \e057;
$fa-var-shopware: \f5b5;
$fa-var-simplybuilt: \f215;
$fa-var-sistrix: \f3ee;
$fa-var-sith: \f512;
$fa-var-sitrox: \e44a;
$fa-var-sketch: \f7c6;
$fa-var-skyatlas: \f216;
$fa-var-skype: \f17e;
$fa-var-slack: \f198;
$fa-var-slack-hash: \f198;
$fa-var-slideshare: \f1e7;
$fa-var-snapchat: \f2ab;
$fa-var-snapchat-ghost: \f2ab;
$fa-var-snapchat-square: \f2ad;
$fa-var-soundcloud: \f1be;
$fa-var-sourcetree: \f7d3;
$fa-var-speakap: \f3f3;
$fa-var-speaker-deck: \f83c;
$fa-var-spotify: \f1bc;
$fa-var-square-font-awesome: \f425;
$fa-var-square-font-awesome-stroke: \f35c;
$fa-var-font-awesome-alt: \f35c;
$fa-var-squarespace: \f5be;
$fa-var-stack-exchange: \f18d;
$fa-var-stack-overflow: \f16c;
$fa-var-stackpath: \f842;
$fa-var-staylinked: \f3f5;
$fa-var-steam: \f1b6;
$fa-var-steam-square: \f1b7;
$fa-var-steam-symbol: \f3f6;
$fa-var-sticker-mule: \f3f7;
$fa-var-strava: \f428;
$fa-var-stripe: \f429;
$fa-var-stripe-s: \f42a;
$fa-var-studiovinari: \f3f8;
$fa-var-stumbleupon: \f1a4;
$fa-var-stumbleupon-circle: \f1a3;
$fa-var-superpowers: \f2dd;
$fa-var-supple: \f3f9;
$fa-var-suse: \f7d6;
$fa-var-swift: \f8e1;
$fa-var-symfony: \f83d;
$fa-var-teamspeak: \f4f9;
$fa-var-telegram: \f2c6;
$fa-var-telegram-plane: \f2c6;
$fa-var-tencent-weibo: \f1d5;
$fa-var-the-red-yeti: \f69d;
$fa-var-themeco: \f5c6;
$fa-var-themeisle: \f2b2;
$fa-var-think-peaks: \f731;
$fa-var-tiktok: \e07b;
$fa-var-trade-federation: \f513;
$fa-var-trello: \f181;
$fa-var-tumblr: \f173;
$fa-var-tumblr-square: \f174;
$fa-var-twitch: \f1e8;
$fa-var-twitter: \f099;
$fa-var-twitter-square: \f081;
$fa-var-typo3: \f42b;
$fa-var-uber: \f402;
$fa-var-ubuntu: \f7df;
$fa-var-uikit: \f403;
$fa-var-umbraco: \f8e8;
$fa-var-uncharted: \e084;
$fa-var-uniregistry: \f404;
$fa-var-unity: \e049;
$fa-var-unsplash: \e07c;
$fa-var-untappd: \f405;
$fa-var-ups: \f7e0;
$fa-var-usb: \f287;
$fa-var-usps: \f7e1;
$fa-var-ussunnah: \f407;
$fa-var-vaadin: \f408;
$fa-var-viacoin: \f237;
$fa-var-viadeo: \f2a9;
$fa-var-viadeo-square: \f2aa;
$fa-var-viber: \f409;
$fa-var-vimeo: \f40a;
$fa-var-vimeo-square: \f194;
$fa-var-vimeo-v: \f27d;
$fa-var-vine: \f1ca;
$fa-var-vk: \f189;
$fa-var-vnv: \f40b;
$fa-var-vuejs: \f41f;
$fa-var-watchman-monitoring: \e087;
$fa-var-waze: \f83f;
$fa-var-weebly: \f5cc;
$fa-var-weibo: \f18a;
$fa-var-weixin: \f1d7;
$fa-var-whatsapp: \f232;
$fa-var-whatsapp-square: \f40c;
$fa-var-whmcs: \f40d;
$fa-var-wikipedia-w: \f266;
$fa-var-windows: \f17a;
$fa-var-wirsindhandwerk: \e2d0;
$fa-var-wsh: \e2d0;
$fa-var-wix: \f5cf;
$fa-var-wizards-of-the-coast: \f730;
$fa-var-wodu: \e088;
$fa-var-wolf-pack-battalion: \f514;
$fa-var-wordpress: \f19a;
$fa-var-wordpress-simple: \f411;
$fa-var-wpbeginner: \f297;
$fa-var-wpexplorer: \f2de;
$fa-var-wpforms: \f298;
$fa-var-wpressr: \f3e4;
$fa-var-xbox: \f412;
$fa-var-xing: \f168;
$fa-var-xing-square: \f169;
$fa-var-y-combinator: \f23b;
$fa-var-yahoo: \f19e;
$fa-var-yammer: \f840;
$fa-var-yandex: \f413;
$fa-var-yandex-international: \f414;
$fa-var-yarn: \f7e3;
$fa-var-yelp: \f1e9;
$fa-var-yoast: \f2b1;
$fa-var-youtube: \f167;
$fa-var-youtube-square: \f431;
$fa-var-zhihu: \f63f;

$fa-icons: (
 "0": $fa-var-0,
 "1": $fa-var-1,
 "2": $fa-var-2,
 "3": $fa-var-3,
 "4": $fa-var-4,
 "5": $fa-var-5,
 "6": $fa-var-6,
 "7": $fa-var-7,
 "8": $fa-var-8,
 "9": $fa-var-9,
 "a": $fa-var-a,
 "address-book": $fa-var-address-book,
 "contact-book": $fa-var-contact-book,
 "address-card": $fa-var-address-card,
 "contact-card": $fa-var-contact-card,
 "vcard": $fa-var-vcard,
 "align-center": $fa-var-align-center,
 "align-justify": $fa-var-align-justify,
 "align-left": $fa-var-align-left,
 "align-right": $fa-var-align-right,
 "anchor": $fa-var-anchor,
 "anchor-circle-check": $fa-var-anchor-circle-check,
 "anchor-circle-exclamation": $fa-var-anchor-circle-exclamation,
 "anchor-circle-xmark": $fa-var-anchor-circle-xmark,
 "anchor-lock": $fa-var-anchor-lock,
 "angle-down": $fa-var-angle-down,
 "angle-left": $fa-var-angle-left,
 "angle-right": $fa-var-angle-right,
 "angle-up": $fa-var-angle-up,
 "angles-down": $fa-var-angles-down,
 "angle-double-down": $fa-var-angle-double-down,
 "angles-left": $fa-var-angles-left,
 "angle-double-left": $fa-var-angle-double-left,
 "angles-right": $fa-var-angles-right,
 "angle-double-right": $fa-var-angle-double-right,
 "angles-up": $fa-var-angles-up,
 "angle-double-up": $fa-var-angle-double-up,
 "ankh": $fa-var-ankh,
 "apple-whole": $fa-var-apple-whole,
 "apple-alt": $fa-var-apple-alt,
 "archway": $fa-var-archway,
 "arrow-down": $fa-var-arrow-down,
 "arrow-down-1-9": $fa-var-arrow-down-1-9,
 "sort-numeric-asc": $fa-var-sort-numeric-asc,
 "sort-numeric-down": $fa-var-sort-numeric-down,
 "arrow-down-9-1": $fa-var-arrow-down-9-1,
 "sort-numeric-desc": $fa-var-sort-numeric-desc,
 "sort-numeric-down-alt": $fa-var-sort-numeric-down-alt,
 "arrow-down-a-z": $fa-var-arrow-down-a-z,
 "sort-alpha-asc": $fa-var-sort-alpha-asc,
 "sort-alpha-down": $fa-var-sort-alpha-down,
 "arrow-down-long": $fa-var-arrow-down-long,
 "long-arrow-down": $fa-var-long-arrow-down,
 "arrow-down-short-wide": $fa-var-arrow-down-short-wide,
 "sort-amount-desc": $fa-var-sort-amount-desc,
 "sort-amount-down-alt": $fa-var-sort-amount-down-alt,
 "arrow-down-up-across-line": $fa-var-arrow-down-up-across-line,
 "arrow-down-up-lock": $fa-var-arrow-down-up-lock,
 "arrow-down-wide-short": $fa-var-arrow-down-wide-short,
 "sort-amount-asc": $fa-var-sort-amount-asc,
 "sort-amount-down": $fa-var-sort-amount-down,
 "arrow-down-z-a": $fa-var-arrow-down-z-a,
 "sort-alpha-desc": $fa-var-sort-alpha-desc,
 "sort-alpha-down-alt": $fa-var-sort-alpha-down-alt,
 "arrow-left": $fa-var-arrow-left,
 "arrow-left-long": $fa-var-arrow-left-long,
 "long-arrow-left": $fa-var-long-arrow-left,
 "arrow-pointer": $fa-var-arrow-pointer,
 "mouse-pointer": $fa-var-mouse-pointer,
 "arrow-right": $fa-var-arrow-right,
 "arrow-right-arrow-left": $fa-var-arrow-right-arrow-left,
 "exchange": $fa-var-exchange,
 "arrow-right-from-bracket": $fa-var-arrow-right-from-bracket,
 "sign-out": $fa-var-sign-out,
 "arrow-right-long": $fa-var-arrow-right-long,
 "long-arrow-right": $fa-var-long-arrow-right,
 "arrow-right-to-bracket": $fa-var-arrow-right-to-bracket,
 "sign-in": $fa-var-sign-in,
 "arrow-right-to-city": $fa-var-arrow-right-to-city,
 "arrow-rotate-left": $fa-var-arrow-rotate-left,
 "arrow-left-rotate": $fa-var-arrow-left-rotate,
 "arrow-rotate-back": $fa-var-arrow-rotate-back,
 "arrow-rotate-backward": $fa-var-arrow-rotate-backward,
 "undo": $fa-var-undo,
 "arrow-rotate-right": $fa-var-arrow-rotate-right,
 "arrow-right-rotate": $fa-var-arrow-right-rotate,
 "arrow-rotate-forward": $fa-var-arrow-rotate-forward,
 "redo": $fa-var-redo,
 "arrow-trend-down": $fa-var-arrow-trend-down,
 "arrow-trend-up": $fa-var-arrow-trend-up,
 "arrow-turn-down": $fa-var-arrow-turn-down,
 "level-down": $fa-var-level-down,
 "arrow-turn-up": $fa-var-arrow-turn-up,
 "level-up": $fa-var-level-up,
 "arrow-up": $fa-var-arrow-up,
 "arrow-up-1-9": $fa-var-arrow-up-1-9,
 "sort-numeric-up": $fa-var-sort-numeric-up,
 "arrow-up-9-1": $fa-var-arrow-up-9-1,
 "sort-numeric-up-alt": $fa-var-sort-numeric-up-alt,
 "arrow-up-a-z": $fa-var-arrow-up-a-z,
 "sort-alpha-up": $fa-var-sort-alpha-up,
 "arrow-up-from-bracket": $fa-var-arrow-up-from-bracket,
 "arrow-up-from-ground-water": $fa-var-arrow-up-from-ground-water,
 "arrow-up-from-water-pump": $fa-var-arrow-up-from-water-pump,
 "arrow-up-long": $fa-var-arrow-up-long,
 "long-arrow-up": $fa-var-long-arrow-up,
 "arrow-up-right-dots": $fa-var-arrow-up-right-dots,
 "arrow-up-right-from-square": $fa-var-arrow-up-right-from-square,
 "external-link": $fa-var-external-link,
 "arrow-up-short-wide": $fa-var-arrow-up-short-wide,
 "sort-amount-up-alt": $fa-var-sort-amount-up-alt,
 "arrow-up-wide-short": $fa-var-arrow-up-wide-short,
 "sort-amount-up": $fa-var-sort-amount-up,
 "arrow-up-z-a": $fa-var-arrow-up-z-a,
 "sort-alpha-up-alt": $fa-var-sort-alpha-up-alt,
 "arrows-down-to-line": $fa-var-arrows-down-to-line,
 "arrows-down-to-people": $fa-var-arrows-down-to-people,
 "arrows-left-right": $fa-var-arrows-left-right,
 "arrows-h": $fa-var-arrows-h,
 "arrows-left-right-to-line": $fa-var-arrows-left-right-to-line,
 "arrows-rotate": $fa-var-arrows-rotate,
 "refresh": $fa-var-refresh,
 "sync": $fa-var-sync,
 "arrows-spin": $fa-var-arrows-spin,
 "arrows-split-up-and-left": $fa-var-arrows-split-up-and-left,
 "arrows-to-circle": $fa-var-arrows-to-circle,
 "arrows-to-dot": $fa-var-arrows-to-dot,
 "arrows-to-eye": $fa-var-arrows-to-eye,
 "arrows-turn-right": $fa-var-arrows-turn-right,
 "arrows-turn-to-dots": $fa-var-arrows-turn-to-dots,
 "arrows-up-down": $fa-var-arrows-up-down,
 "arrows-v": $fa-var-arrows-v,
 "arrows-up-down-left-right": $fa-var-arrows-up-down-left-right,
 "arrows": $fa-var-arrows,
 "arrows-up-to-line": $fa-var-arrows-up-to-line,
 "asterisk": $fa-var-asterisk,
 "at": $fa-var-at,
 "atom": $fa-var-atom,
 "audio-description": $fa-var-audio-description,
 "austral-sign": $fa-var-austral-sign,
 "award": $fa-var-award,
 "b": $fa-var-b,
 "baby": $fa-var-baby,
 "baby-carriage": $fa-var-baby-carriage,
 "carriage-baby": $fa-var-carriage-baby,
 "backward": $fa-var-backward,
 "backward-fast": $fa-var-backward-fast,
 "fast-backward": $fa-var-fast-backward,
 "backward-step": $fa-var-backward-step,
 "step-backward": $fa-var-step-backward,
 "bacon": $fa-var-bacon,
 "bacteria": $fa-var-bacteria,
 "bacterium": $fa-var-bacterium,
 "bag-shopping": $fa-var-bag-shopping,
 "shopping-bag": $fa-var-shopping-bag,
 "bahai": $fa-var-bahai,
 "baht-sign": $fa-var-baht-sign,
 "ban": $fa-var-ban,
 "cancel": $fa-var-cancel,
 "ban-smoking": $fa-var-ban-smoking,
 "smoking-ban": $fa-var-smoking-ban,
 "bandage": $fa-var-bandage,
 "band-aid": $fa-var-band-aid,
 "barcode": $fa-var-barcode,
 "bars": $fa-var-bars,
 "navicon": $fa-var-navicon,
 "bars-progress": $fa-var-bars-progress,
 "tasks-alt": $fa-var-tasks-alt,
 "bars-staggered": $fa-var-bars-staggered,
 "reorder": $fa-var-reorder,
 "stream": $fa-var-stream,
 "baseball": $fa-var-baseball,
 "baseball-ball": $fa-var-baseball-ball,
 "baseball-bat-ball": $fa-var-baseball-bat-ball,
 "basket-shopping": $fa-var-basket-shopping,
 "shopping-basket": $fa-var-shopping-basket,
 "basketball": $fa-var-basketball,
 "basketball-ball": $fa-var-basketball-ball,
 "bath": $fa-var-bath,
 "bathtub": $fa-var-bathtub,
 "battery-empty": $fa-var-battery-empty,
 "battery-0": $fa-var-battery-0,
 "battery-full": $fa-var-battery-full,
 "battery": $fa-var-battery,
 "battery-5": $fa-var-battery-5,
 "battery-half": $fa-var-battery-half,
 "battery-3": $fa-var-battery-3,
 "battery-quarter": $fa-var-battery-quarter,
 "battery-2": $fa-var-battery-2,
 "battery-three-quarters": $fa-var-battery-three-quarters,
 "battery-4": $fa-var-battery-4,
 "bed": $fa-var-bed,
 "bed-pulse": $fa-var-bed-pulse,
 "procedures": $fa-var-procedures,
 "beer-mug-empty": $fa-var-beer-mug-empty,
 "beer": $fa-var-beer,
 "bell": $fa-var-bell,
 "bell-concierge": $fa-var-bell-concierge,
 "concierge-bell": $fa-var-concierge-bell,
 "bell-slash": $fa-var-bell-slash,
 "bezier-curve": $fa-var-bezier-curve,
 "bicycle": $fa-var-bicycle,
 "binoculars": $fa-var-binoculars,
 "biohazard": $fa-var-biohazard,
 "bitcoin-sign": $fa-var-bitcoin-sign,
 "blender": $fa-var-blender,
 "blender-phone": $fa-var-blender-phone,
 "blog": $fa-var-blog,
 "bold": $fa-var-bold,
 "bolt": $fa-var-bolt,
 "zap": $fa-var-zap,
 "bolt-lightning": $fa-var-bolt-lightning,
 "bomb": $fa-var-bomb,
 "bone": $fa-var-bone,
 "bong": $fa-var-bong,
 "book": $fa-var-book,
 "book-atlas": $fa-var-book-atlas,
 "atlas": $fa-var-atlas,
 "book-bible": $fa-var-book-bible,
 "bible": $fa-var-bible,
 "book-bookmark": $fa-var-book-bookmark,
 "book-journal-whills": $fa-var-book-journal-whills,
 "journal-whills": $fa-var-journal-whills,
 "book-medical": $fa-var-book-medical,
 "book-open": $fa-var-book-open,
 "book-open-reader": $fa-var-book-open-reader,
 "book-reader": $fa-var-book-reader,
 "book-quran": $fa-var-book-quran,
 "quran": $fa-var-quran,
 "book-skull": $fa-var-book-skull,
 "book-dead": $fa-var-book-dead,
 "bookmark": $fa-var-bookmark,
 "border-all": $fa-var-border-all,
 "border-none": $fa-var-border-none,
 "border-top-left": $fa-var-border-top-left,
 "border-style": $fa-var-border-style,
 "bore-hole": $fa-var-bore-hole,
 "bottle-droplet": $fa-var-bottle-droplet,
 "bottle-water": $fa-var-bottle-water,
 "bowl-food": $fa-var-bowl-food,
 "bowl-rice": $fa-var-bowl-rice,
 "bowling-ball": $fa-var-bowling-ball,
 "box": $fa-var-box,
 "box-archive": $fa-var-box-archive,
 "archive": $fa-var-archive,
 "box-open": $fa-var-box-open,
 "box-tissue": $fa-var-box-tissue,
 "boxes-packing": $fa-var-boxes-packing,
 "boxes-stacked": $fa-var-boxes-stacked,
 "boxes": $fa-var-boxes,
 "boxes-alt": $fa-var-boxes-alt,
 "braille": $fa-var-braille,
 "brain": $fa-var-brain,
 "brazilian-real-sign": $fa-var-brazilian-real-sign,
 "bread-slice": $fa-var-bread-slice,
 "bridge": $fa-var-bridge,
 "bridge-circle-check": $fa-var-bridge-circle-check,
 "bridge-circle-exclamation": $fa-var-bridge-circle-exclamation,
 "bridge-circle-xmark": $fa-var-bridge-circle-xmark,
 "bridge-lock": $fa-var-bridge-lock,
 "bridge-water": $fa-var-bridge-water,
 "briefcase": $fa-var-briefcase,
 "briefcase-medical": $fa-var-briefcase-medical,
 "broom": $fa-var-broom,
 "broom-ball": $fa-var-broom-ball,
 "quidditch": $fa-var-quidditch,
 "quidditch-broom-ball": $fa-var-quidditch-broom-ball,
 "brush": $fa-var-brush,
 "bucket": $fa-var-bucket,
 "bug": $fa-var-bug,
 "bug-slash": $fa-var-bug-slash,
 "bugs": $fa-var-bugs,
 "building": $fa-var-building,
 "building-circle-arrow-right": $fa-var-building-circle-arrow-right,
 "building-circle-check": $fa-var-building-circle-check,
 "building-circle-exclamation": $fa-var-building-circle-exclamation,
 "building-circle-xmark": $fa-var-building-circle-xmark,
 "building-columns": $fa-var-building-columns,
 "bank": $fa-var-bank,
 "institution": $fa-var-institution,
 "museum": $fa-var-museum,
 "university": $fa-var-university,
 "building-flag": $fa-var-building-flag,
 "building-lock": $fa-var-building-lock,
 "building-ngo": $fa-var-building-ngo,
 "building-shield": $fa-var-building-shield,
 "building-un": $fa-var-building-un,
 "building-user": $fa-var-building-user,
 "building-wheat": $fa-var-building-wheat,
 "bullhorn": $fa-var-bullhorn,
 "bullseye": $fa-var-bullseye,
 "burger": $fa-var-burger,
 "hamburger": $fa-var-hamburger,
 "burst": $fa-var-burst,
 "bus": $fa-var-bus,
 "bus-simple": $fa-var-bus-simple,
 "bus-alt": $fa-var-bus-alt,
 "business-time": $fa-var-business-time,
 "briefcase-clock": $fa-var-briefcase-clock,
 "c": $fa-var-c,
 "cake-candles": $fa-var-cake-candles,
 "birthday-cake": $fa-var-birthday-cake,
 "cake": $fa-var-cake,
 "calculator": $fa-var-calculator,
 "calendar": $fa-var-calendar,
 "calendar-check": $fa-var-calendar-check,
 "calendar-day": $fa-var-calendar-day,
 "calendar-days": $fa-var-calendar-days,
 "calendar-alt": $fa-var-calendar-alt,
 "calendar-minus": $fa-var-calendar-minus,
 "calendar-plus": $fa-var-calendar-plus,
 "calendar-week": $fa-var-calendar-week,
 "calendar-xmark": $fa-var-calendar-xmark,
 "calendar-times": $fa-var-calendar-times,
 "camera": $fa-var-camera,
 "camera-alt": $fa-var-camera-alt,
 "camera-retro": $fa-var-camera-retro,
 "camera-rotate": $fa-var-camera-rotate,
 "campground": $fa-var-campground,
 "candy-cane": $fa-var-candy-cane,
 "cannabis": $fa-var-cannabis,
 "capsules": $fa-var-capsules,
 "car": $fa-var-car,
 "automobile": $fa-var-automobile,
 "car-battery": $fa-var-car-battery,
 "battery-car": $fa-var-battery-car,
 "car-burst": $fa-var-car-burst,
 "car-crash": $fa-var-car-crash,
 "car-on": $fa-var-car-on,
 "car-rear": $fa-var-car-rear,
 "car-alt": $fa-var-car-alt,
 "car-side": $fa-var-car-side,
 "car-tunnel": $fa-var-car-tunnel,
 "caravan": $fa-var-caravan,
 "caret-down": $fa-var-caret-down,
 "caret-left": $fa-var-caret-left,
 "caret-right": $fa-var-caret-right,
 "caret-up": $fa-var-caret-up,
 "carrot": $fa-var-carrot,
 "cart-arrow-down": $fa-var-cart-arrow-down,
 "cart-flatbed": $fa-var-cart-flatbed,
 "dolly-flatbed": $fa-var-dolly-flatbed,
 "cart-flatbed-suitcase": $fa-var-cart-flatbed-suitcase,
 "luggage-cart": $fa-var-luggage-cart,
 "cart-plus": $fa-var-cart-plus,
 "cart-shopping": $fa-var-cart-shopping,
 "shopping-cart": $fa-var-shopping-cart,
 "cash-register": $fa-var-cash-register,
 "cat": $fa-var-cat,
 "cedi-sign": $fa-var-cedi-sign,
 "cent-sign": $fa-var-cent-sign,
 "certificate": $fa-var-certificate,
 "chair": $fa-var-chair,
 "chalkboard": $fa-var-chalkboard,
 "blackboard": $fa-var-blackboard,
 "chalkboard-user": $fa-var-chalkboard-user,
 "chalkboard-teacher": $fa-var-chalkboard-teacher,
 "champagne-glasses": $fa-var-champagne-glasses,
 "glass-cheers": $fa-var-glass-cheers,
 "charging-station": $fa-var-charging-station,
 "chart-area": $fa-var-chart-area,
 "area-chart": $fa-var-area-chart,
 "chart-bar": $fa-var-chart-bar,
 "bar-chart": $fa-var-bar-chart,
 "chart-column": $fa-var-chart-column,
 "chart-gantt": $fa-var-chart-gantt,
 "chart-line": $fa-var-chart-line,
 "line-chart": $fa-var-line-chart,
 "chart-pie": $fa-var-chart-pie,
 "pie-chart": $fa-var-pie-chart,
 "chart-simple": $fa-var-chart-simple,
 "check": $fa-var-check,
 "check-double": $fa-var-check-double,
 "check-to-slot": $fa-var-check-to-slot,
 "vote-yea": $fa-var-vote-yea,
 "cheese": $fa-var-cheese,
 "chess": $fa-var-chess,
 "chess-bishop": $fa-var-chess-bishop,
 "chess-board": $fa-var-chess-board,
 "chess-king": $fa-var-chess-king,
 "chess-knight": $fa-var-chess-knight,
 "chess-pawn": $fa-var-chess-pawn,
 "chess-queen": $fa-var-chess-queen,
 "chess-rook": $fa-var-chess-rook,
 "chevron-down": $fa-var-chevron-down,
 "chevron-left": $fa-var-chevron-left,
 "chevron-right": $fa-var-chevron-right,
 "chevron-up": $fa-var-chevron-up,
 "child": $fa-var-child,
 "child-dress": $fa-var-child-dress,
 "child-reaching": $fa-var-child-reaching,
 "child-rifle": $fa-var-child-rifle,
 "children": $fa-var-children,
 "church": $fa-var-church,
 "circle": $fa-var-circle,
 "circle-arrow-down": $fa-var-circle-arrow-down,
 "arrow-circle-down": $fa-var-arrow-circle-down,
 "circle-arrow-left": $fa-var-circle-arrow-left,
 "arrow-circle-left": $fa-var-arrow-circle-left,
 "circle-arrow-right": $fa-var-circle-arrow-right,
 "arrow-circle-right": $fa-var-arrow-circle-right,
 "circle-arrow-up": $fa-var-circle-arrow-up,
 "arrow-circle-up": $fa-var-arrow-circle-up,
 "circle-check": $fa-var-circle-check,
 "check-circle": $fa-var-check-circle,
 "circle-chevron-down": $fa-var-circle-chevron-down,
 "chevron-circle-down": $fa-var-chevron-circle-down,
 "circle-chevron-left": $fa-var-circle-chevron-left,
 "chevron-circle-left": $fa-var-chevron-circle-left,
 "circle-chevron-right": $fa-var-circle-chevron-right,
 "chevron-circle-right": $fa-var-chevron-circle-right,
 "circle-chevron-up": $fa-var-circle-chevron-up,
 "chevron-circle-up": $fa-var-chevron-circle-up,
 "circle-dollar-to-slot": $fa-var-circle-dollar-to-slot,
 "donate": $fa-var-donate,
 "circle-dot": $fa-var-circle-dot,
 "dot-circle": $fa-var-dot-circle,
 "circle-down": $fa-var-circle-down,
 "arrow-alt-circle-down": $fa-var-arrow-alt-circle-down,
 "circle-exclamation": $fa-var-circle-exclamation,
 "exclamation-circle": $fa-var-exclamation-circle,
 "circle-h": $fa-var-circle-h,
 "hospital-symbol": $fa-var-hospital-symbol,
 "circle-half-stroke": $fa-var-circle-half-stroke,
 "adjust": $fa-var-adjust,
 "circle-info": $fa-var-circle-info,
 "info-circle": $fa-var-info-circle,
 "circle-left": $fa-var-circle-left,
 "arrow-alt-circle-left": $fa-var-arrow-alt-circle-left,
 "circle-minus": $fa-var-circle-minus,
 "minus-circle": $fa-var-minus-circle,
 "circle-nodes": $fa-var-circle-nodes,
 "circle-notch": $fa-var-circle-notch,
 "circle-pause": $fa-var-circle-pause,
 "pause-circle": $fa-var-pause-circle,
 "circle-play": $fa-var-circle-play,
 "play-circle": $fa-var-play-circle,
 "circle-plus": $fa-var-circle-plus,
 "plus-circle": $fa-var-plus-circle,
 "circle-question": $fa-var-circle-question,
 "question-circle": $fa-var-question-circle,
 "circle-radiation": $fa-var-circle-radiation,
 "radiation-alt": $fa-var-radiation-alt,
 "circle-right": $fa-var-circle-right,
 "arrow-alt-circle-right": $fa-var-arrow-alt-circle-right,
 "circle-stop": $fa-var-circle-stop,
 "stop-circle": $fa-var-stop-circle,
 "circle-up": $fa-var-circle-up,
 "arrow-alt-circle-up": $fa-var-arrow-alt-circle-up,
 "circle-user": $fa-var-circle-user,
 "user-circle": $fa-var-user-circle,
 "circle-xmark": $fa-var-circle-xmark,
 "times-circle": $fa-var-times-circle,
 "xmark-circle": $fa-var-xmark-circle,
 "city": $fa-var-city,
 "clapperboard": $fa-var-clapperboard,
 "clipboard": $fa-var-clipboard,
 "clipboard-check": $fa-var-clipboard-check,
 "clipboard-list": $fa-var-clipboard-list,
 "clipboard-question": $fa-var-clipboard-question,
 "clipboard-user": $fa-var-clipboard-user,
 "clock": $fa-var-clock,
 "clock-four": $fa-var-clock-four,
 "clock-rotate-left": $fa-var-clock-rotate-left,
 "history": $fa-var-history,
 "clone": $fa-var-clone,
 "closed-captioning": $fa-var-closed-captioning,
 "cloud": $fa-var-cloud,
 "cloud-arrow-down": $fa-var-cloud-arrow-down,
 "cloud-download": $fa-var-cloud-download,
 "cloud-download-alt": $fa-var-cloud-download-alt,
 "cloud-arrow-up": $fa-var-cloud-arrow-up,
 "cloud-upload": $fa-var-cloud-upload,
 "cloud-upload-alt": $fa-var-cloud-upload-alt,
 "cloud-bolt": $fa-var-cloud-bolt,
 "thunderstorm": $fa-var-thunderstorm,
 "cloud-meatball": $fa-var-cloud-meatball,
 "cloud-moon": $fa-var-cloud-moon,
 "cloud-moon-rain": $fa-var-cloud-moon-rain,
 "cloud-rain": $fa-var-cloud-rain,
 "cloud-showers-heavy": $fa-var-cloud-showers-heavy,
 "cloud-showers-water": $fa-var-cloud-showers-water,
 "cloud-sun": $fa-var-cloud-sun,
 "cloud-sun-rain": $fa-var-cloud-sun-rain,
 "clover": $fa-var-clover,
 "code": $fa-var-code,
 "code-branch": $fa-var-code-branch,
 "code-commit": $fa-var-code-commit,
 "code-compare": $fa-var-code-compare,
 "code-fork": $fa-var-code-fork,
 "code-merge": $fa-var-code-merge,
 "code-pull-request": $fa-var-code-pull-request,
 "coins": $fa-var-coins,
 "colon-sign": $fa-var-colon-sign,
 "comment": $fa-var-comment,
 "comment-dollar": $fa-var-comment-dollar,
 "comment-dots": $fa-var-comment-dots,
 "commenting": $fa-var-commenting,
 "comment-medical": $fa-var-comment-medical,
 "comment-slash": $fa-var-comment-slash,
 "comment-sms": $fa-var-comment-sms,
 "sms": $fa-var-sms,
 "comments": $fa-var-comments,
 "comments-dollar": $fa-var-comments-dollar,
 "compact-disc": $fa-var-compact-disc,
 "compass": $fa-var-compass,
 "compass-drafting": $fa-var-compass-drafting,
 "drafting-compass": $fa-var-drafting-compass,
 "compress": $fa-var-compress,
 "computer": $fa-var-computer,
 "computer-mouse": $fa-var-computer-mouse,
 "mouse": $fa-var-mouse,
 "cookie": $fa-var-cookie,
 "cookie-bite": $fa-var-cookie-bite,
 "copy": $fa-var-copy,
 "copyright": $fa-var-copyright,
 "couch": $fa-var-couch,
 "cow": $fa-var-cow,
 "credit-card": $fa-var-credit-card,
 "credit-card-alt": $fa-var-credit-card-alt,
 "crop": $fa-var-crop,
 "crop-simple": $fa-var-crop-simple,
 "crop-alt": $fa-var-crop-alt,
 "cross": $fa-var-cross,
 "crosshairs": $fa-var-crosshairs,
 "crow": $fa-var-crow,
 "crown": $fa-var-crown,
 "crutch": $fa-var-crutch,
 "cruzeiro-sign": $fa-var-cruzeiro-sign,
 "cube": $fa-var-cube,
 "cubes": $fa-var-cubes,
 "cubes-stacked": $fa-var-cubes-stacked,
 "d": $fa-var-d,
 "database": $fa-var-database,
 "delete-left": $fa-var-delete-left,
 "backspace": $fa-var-backspace,
 "democrat": $fa-var-democrat,
 "desktop": $fa-var-desktop,
 "desktop-alt": $fa-var-desktop-alt,
 "dharmachakra": $fa-var-dharmachakra,
 "diagram-next": $fa-var-diagram-next,
 "diagram-predecessor": $fa-var-diagram-predecessor,
 "diagram-project": $fa-var-diagram-project,
 "project-diagram": $fa-var-project-diagram,
 "diagram-successor": $fa-var-diagram-successor,
 "diamond": $fa-var-diamond,
 "diamond-turn-right": $fa-var-diamond-turn-right,
 "directions": $fa-var-directions,
 "dice": $fa-var-dice,
 "dice-d20": $fa-var-dice-d20,
 "dice-d6": $fa-var-dice-d6,
 "dice-five": $fa-var-dice-five,
 "dice-four": $fa-var-dice-four,
 "dice-one": $fa-var-dice-one,
 "dice-six": $fa-var-dice-six,
 "dice-three": $fa-var-dice-three,
 "dice-two": $fa-var-dice-two,
 "disease": $fa-var-disease,
 "display": $fa-var-display,
 "divide": $fa-var-divide,
 "dna": $fa-var-dna,
 "dog": $fa-var-dog,
 "dollar-sign": $fa-var-dollar-sign,
 "dollar": $fa-var-dollar,
 "usd": $fa-var-usd,
 "dolly": $fa-var-dolly,
 "dolly-box": $fa-var-dolly-box,
 "dong-sign": $fa-var-dong-sign,
 "door-closed": $fa-var-door-closed,
 "door-open": $fa-var-door-open,
 "dove": $fa-var-dove,
 "down-left-and-up-right-to-center": $fa-var-down-left-and-up-right-to-center,
 "compress-alt": $fa-var-compress-alt,
 "down-long": $fa-var-down-long,
 "long-arrow-alt-down": $fa-var-long-arrow-alt-down,
 "download": $fa-var-download,
 "dragon": $fa-var-dragon,
 "draw-polygon": $fa-var-draw-polygon,
 "droplet": $fa-var-droplet,
 "tint": $fa-var-tint,
 "droplet-slash": $fa-var-droplet-slash,
 "tint-slash": $fa-var-tint-slash,
 "drum": $fa-var-drum,
 "drum-steelpan": $fa-var-drum-steelpan,
 "drumstick-bite": $fa-var-drumstick-bite,
 "dumbbell": $fa-var-dumbbell,
 "dumpster": $fa-var-dumpster,
 "dumpster-fire": $fa-var-dumpster-fire,
 "dungeon": $fa-var-dungeon,
 "e": $fa-var-e,
 "ear-deaf": $fa-var-ear-deaf,
 "deaf": $fa-var-deaf,
 "deafness": $fa-var-deafness,
 "hard-of-hearing": $fa-var-hard-of-hearing,
 "ear-listen": $fa-var-ear-listen,
 "assistive-listening-systems": $fa-var-assistive-listening-systems,
 "earth-africa": $fa-var-earth-africa,
 "globe-africa": $fa-var-globe-africa,
 "earth-americas": $fa-var-earth-americas,
 "earth": $fa-var-earth,
 "earth-america": $fa-var-earth-america,
 "globe-americas": $fa-var-globe-americas,
 "earth-asia": $fa-var-earth-asia,
 "globe-asia": $fa-var-globe-asia,
 "earth-europe": $fa-var-earth-europe,
 "globe-europe": $fa-var-globe-europe,
 "earth-oceania": $fa-var-earth-oceania,
 "globe-oceania": $fa-var-globe-oceania,
 "egg": $fa-var-egg,
 "eject": $fa-var-eject,
 "elevator": $fa-var-elevator,
 "ellipsis": $fa-var-ellipsis,
 "ellipsis-h": $fa-var-ellipsis-h,
 "ellipsis-vertical": $fa-var-ellipsis-vertical,
 "ellipsis-v": $fa-var-ellipsis-v,
 "envelope": $fa-var-envelope,
 "envelope-circle-check": $fa-var-envelope-circle-check,
 "envelope-open": $fa-var-envelope-open,
 "envelope-open-text": $fa-var-envelope-open-text,
 "envelopes-bulk": $fa-var-envelopes-bulk,
 "mail-bulk": $fa-var-mail-bulk,
 "equals": $fa-var-equals,
 "eraser": $fa-var-eraser,
 "ethernet": $fa-var-ethernet,
 "euro-sign": $fa-var-euro-sign,
 "eur": $fa-var-eur,
 "euro": $fa-var-euro,
 "exclamation": $fa-var-exclamation,
 "expand": $fa-var-expand,
 "explosion": $fa-var-explosion,
 "eye": $fa-var-eye,
 "eye-dropper": $fa-var-eye-dropper,
 "eye-dropper-empty": $fa-var-eye-dropper-empty,
 "eyedropper": $fa-var-eyedropper,
 "eye-low-vision": $fa-var-eye-low-vision,
 "low-vision": $fa-var-low-vision,
 "eye-slash": $fa-var-eye-slash,
 "f": $fa-var-f,
 "face-angry": $fa-var-face-angry,
 "angry": $fa-var-angry,
 "face-dizzy": $fa-var-face-dizzy,
 "dizzy": $fa-var-dizzy,
 "face-flushed": $fa-var-face-flushed,
 "flushed": $fa-var-flushed,
 "face-frown": $fa-var-face-frown,
 "frown": $fa-var-frown,
 "face-frown-open": $fa-var-face-frown-open,
 "frown-open": $fa-var-frown-open,
 "face-grimace": $fa-var-face-grimace,
 "grimace": $fa-var-grimace,
 "face-grin": $fa-var-face-grin,
 "grin": $fa-var-grin,
 "face-grin-beam": $fa-var-face-grin-beam,
 "grin-beam": $fa-var-grin-beam,
 "face-grin-beam-sweat": $fa-var-face-grin-beam-sweat,
 "grin-beam-sweat": $fa-var-grin-beam-sweat,
 "face-grin-hearts": $fa-var-face-grin-hearts,
 "grin-hearts": $fa-var-grin-hearts,
 "face-grin-squint": $fa-var-face-grin-squint,
 "grin-squint": $fa-var-grin-squint,
 "face-grin-squint-tears": $fa-var-face-grin-squint-tears,
 "grin-squint-tears": $fa-var-grin-squint-tears,
 "face-grin-stars": $fa-var-face-grin-stars,
 "grin-stars": $fa-var-grin-stars,
 "face-grin-tears": $fa-var-face-grin-tears,
 "grin-tears": $fa-var-grin-tears,
 "face-grin-tongue": $fa-var-face-grin-tongue,
 "grin-tongue": $fa-var-grin-tongue,
 "face-grin-tongue-squint": $fa-var-face-grin-tongue-squint,
 "grin-tongue-squint": $fa-var-grin-tongue-squint,
 "face-grin-tongue-wink": $fa-var-face-grin-tongue-wink,
 "grin-tongue-wink": $fa-var-grin-tongue-wink,
 "face-grin-wide": $fa-var-face-grin-wide,
 "grin-alt": $fa-var-grin-alt,
 "face-grin-wink": $fa-var-face-grin-wink,
 "grin-wink": $fa-var-grin-wink,
 "face-kiss": $fa-var-face-kiss,
 "kiss": $fa-var-kiss,
 "face-kiss-beam": $fa-var-face-kiss-beam,
 "kiss-beam": $fa-var-kiss-beam,
 "face-kiss-wink-heart": $fa-var-face-kiss-wink-heart,
 "kiss-wink-heart": $fa-var-kiss-wink-heart,
 "face-laugh": $fa-var-face-laugh,
 "laugh": $fa-var-laugh,
 "face-laugh-beam": $fa-var-face-laugh-beam,
 "laugh-beam": $fa-var-laugh-beam,
 "face-laugh-squint": $fa-var-face-laugh-squint,
 "laugh-squint": $fa-var-laugh-squint,
 "face-laugh-wink": $fa-var-face-laugh-wink,
 "laugh-wink": $fa-var-laugh-wink,
 "face-meh": $fa-var-face-meh,
 "meh": $fa-var-meh,
 "face-meh-blank": $fa-var-face-meh-blank,
 "meh-blank": $fa-var-meh-blank,
 "face-rolling-eyes": $fa-var-face-rolling-eyes,
 "meh-rolling-eyes": $fa-var-meh-rolling-eyes,
 "face-sad-cry": $fa-var-face-sad-cry,
 "sad-cry": $fa-var-sad-cry,
 "face-sad-tear": $fa-var-face-sad-tear,
 "sad-tear": $fa-var-sad-tear,
 "face-smile": $fa-var-face-smile,
 "smile": $fa-var-smile,
 "face-smile-beam": $fa-var-face-smile-beam,
 "smile-beam": $fa-var-smile-beam,
 "face-smile-wink": $fa-var-face-smile-wink,
 "smile-wink": $fa-var-smile-wink,
 "face-surprise": $fa-var-face-surprise,
 "surprise": $fa-var-surprise,
 "face-tired": $fa-var-face-tired,
 "tired": $fa-var-tired,
 "fan": $fa-var-fan,
 "faucet": $fa-var-faucet,
 "faucet-drip": $fa-var-faucet-drip,
 "fax": $fa-var-fax,
 "feather": $fa-var-feather,
 "feather-pointed": $fa-var-feather-pointed,
 "feather-alt": $fa-var-feather-alt,
 "ferry": $fa-var-ferry,
 "file": $fa-var-file,
 "file-arrow-down": $fa-var-file-arrow-down,
 "file-download": $fa-var-file-download,
 "file-arrow-up": $fa-var-file-arrow-up,
 "file-upload": $fa-var-file-upload,
 "file-audio": $fa-var-file-audio,
 "file-circle-check": $fa-var-file-circle-check,
 "file-circle-exclamation": $fa-var-file-circle-exclamation,
 "file-circle-minus": $fa-var-file-circle-minus,
 "file-circle-plus": $fa-var-file-circle-plus,
 "file-circle-question": $fa-var-file-circle-question,
 "file-circle-xmark": $fa-var-file-circle-xmark,
 "file-code": $fa-var-file-code,
 "file-contract": $fa-var-file-contract,
 "file-csv": $fa-var-file-csv,
 "file-excel": $fa-var-file-excel,
 "file-export": $fa-var-file-export,
 "arrow-right-from-file": $fa-var-arrow-right-from-file,
 "file-image": $fa-var-file-image,
 "file-import": $fa-var-file-import,
 "arrow-right-to-file": $fa-var-arrow-right-to-file,
 "file-invoice": $fa-var-file-invoice,
 "file-invoice-dollar": $fa-var-file-invoice-dollar,
 "file-lines": $fa-var-file-lines,
 "file-alt": $fa-var-file-alt,
 "file-text": $fa-var-file-text,
 "file-medical": $fa-var-file-medical,
 "file-pdf": $fa-var-file-pdf,
 "file-pen": $fa-var-file-pen,
 "file-edit": $fa-var-file-edit,
 "file-powerpoint": $fa-var-file-powerpoint,
 "file-prescription": $fa-var-file-prescription,
 "file-shield": $fa-var-file-shield,
 "file-signature": $fa-var-file-signature,
 "file-video": $fa-var-file-video,
 "file-waveform": $fa-var-file-waveform,
 "file-medical-alt": $fa-var-file-medical-alt,
 "file-word": $fa-var-file-word,
 "file-zipper": $fa-var-file-zipper,
 "file-archive": $fa-var-file-archive,
 "fill": $fa-var-fill,
 "fill-drip": $fa-var-fill-drip,
 "film": $fa-var-film,
 "filter": $fa-var-filter,
 "filter-circle-dollar": $fa-var-filter-circle-dollar,
 "funnel-dollar": $fa-var-funnel-dollar,
 "filter-circle-xmark": $fa-var-filter-circle-xmark,
 "fingerprint": $fa-var-fingerprint,
 "fire": $fa-var-fire,
 "fire-burner": $fa-var-fire-burner,
 "fire-extinguisher": $fa-var-fire-extinguisher,
 "fire-flame-curved": $fa-var-fire-flame-curved,
 "fire-alt": $fa-var-fire-alt,
 "fire-flame-simple": $fa-var-fire-flame-simple,
 "burn": $fa-var-burn,
 "fish": $fa-var-fish,
 "fish-fins": $fa-var-fish-fins,
 "flag": $fa-var-flag,
 "flag-checkered": $fa-var-flag-checkered,
 "flag-usa": $fa-var-flag-usa,
 "flask": $fa-var-flask,
 "flask-vial": $fa-var-flask-vial,
 "floppy-disk": $fa-var-floppy-disk,
 "save": $fa-var-save,
 "florin-sign": $fa-var-florin-sign,
 "folder": $fa-var-folder,
 "folder-blank": $fa-var-folder-blank,
 "folder-closed": $fa-var-folder-closed,
 "folder-minus": $fa-var-folder-minus,
 "folder-open": $fa-var-folder-open,
 "folder-plus": $fa-var-folder-plus,
 "folder-tree": $fa-var-folder-tree,
 "font": $fa-var-font,
 "football": $fa-var-football,
 "football-ball": $fa-var-football-ball,
 "forward": $fa-var-forward,
 "forward-fast": $fa-var-forward-fast,
 "fast-forward": $fa-var-fast-forward,
 "forward-step": $fa-var-forward-step,
 "step-forward": $fa-var-step-forward,
 "franc-sign": $fa-var-franc-sign,
 "frog": $fa-var-frog,
 "futbol": $fa-var-futbol,
 "futbol-ball": $fa-var-futbol-ball,
 "soccer-ball": $fa-var-soccer-ball,
 "g": $fa-var-g,
 "gamepad": $fa-var-gamepad,
 "gas-pump": $fa-var-gas-pump,
 "gauge": $fa-var-gauge,
 "dashboard": $fa-var-dashboard,
 "gauge-med": $fa-var-gauge-med,
 "tachometer-alt-average": $fa-var-tachometer-alt-average,
 "gauge-high": $fa-var-gauge-high,
 "tachometer-alt": $fa-var-tachometer-alt,
 "tachometer-alt-fast": $fa-var-tachometer-alt-fast,
 "gauge-simple": $fa-var-gauge-simple,
 "gauge-simple-med": $fa-var-gauge-simple-med,
 "tachometer-average": $fa-var-tachometer-average,
 "gauge-simple-high": $fa-var-gauge-simple-high,
 "tachometer": $fa-var-tachometer,
 "tachometer-fast": $fa-var-tachometer-fast,
 "gavel": $fa-var-gavel,
 "legal": $fa-var-legal,
 "gear": $fa-var-gear,
 "cog": $fa-var-cog,
 "gears": $fa-var-gears,
 "cogs": $fa-var-cogs,
 "gem": $fa-var-gem,
 "genderless": $fa-var-genderless,
 "ghost": $fa-var-ghost,
 "gift": $fa-var-gift,
 "gifts": $fa-var-gifts,
 "glass-water": $fa-var-glass-water,
 "glass-water-droplet": $fa-var-glass-water-droplet,
 "glasses": $fa-var-glasses,
 "globe": $fa-var-globe,
 "golf-ball-tee": $fa-var-golf-ball-tee,
 "golf-ball": $fa-var-golf-ball,
 "gopuram": $fa-var-gopuram,
 "graduation-cap": $fa-var-graduation-cap,
 "mortar-board": $fa-var-mortar-board,
 "greater-than": $fa-var-greater-than,
 "greater-than-equal": $fa-var-greater-than-equal,
 "grip": $fa-var-grip,
 "grip-horizontal": $fa-var-grip-horizontal,
 "grip-lines": $fa-var-grip-lines,
 "grip-lines-vertical": $fa-var-grip-lines-vertical,
 "grip-vertical": $fa-var-grip-vertical,
 "group-arrows-rotate": $fa-var-group-arrows-rotate,
 "guarani-sign": $fa-var-guarani-sign,
 "guitar": $fa-var-guitar,
 "gun": $fa-var-gun,
 "h": $fa-var-h,
 "hammer": $fa-var-hammer,
 "hamsa": $fa-var-hamsa,
 "hand": $fa-var-hand,
 "hand-paper": $fa-var-hand-paper,
 "hand-back-fist": $fa-var-hand-back-fist,
 "hand-rock": $fa-var-hand-rock,
 "hand-dots": $fa-var-hand-dots,
 "allergies": $fa-var-allergies,
 "hand-fist": $fa-var-hand-fist,
 "fist-raised": $fa-var-fist-raised,
 "hand-holding": $fa-var-hand-holding,
 "hand-holding-dollar": $fa-var-hand-holding-dollar,
 "hand-holding-usd": $fa-var-hand-holding-usd,
 "hand-holding-droplet": $fa-var-hand-holding-droplet,
 "hand-holding-water": $fa-var-hand-holding-water,
 "hand-holding-hand": $fa-var-hand-holding-hand,
 "hand-holding-heart": $fa-var-hand-holding-heart,
 "hand-holding-medical": $fa-var-hand-holding-medical,
 "hand-lizard": $fa-var-hand-lizard,
 "hand-middle-finger": $fa-var-hand-middle-finger,
 "hand-peace": $fa-var-hand-peace,
 "hand-point-down": $fa-var-hand-point-down,
 "hand-point-left": $fa-var-hand-point-left,
 "hand-point-right": $fa-var-hand-point-right,
 "hand-point-up": $fa-var-hand-point-up,
 "hand-pointer": $fa-var-hand-pointer,
 "hand-scissors": $fa-var-hand-scissors,
 "hand-sparkles": $fa-var-hand-sparkles,
 "hand-spock": $fa-var-hand-spock,
 "handcuffs": $fa-var-handcuffs,
 "hands": $fa-var-hands,
 "sign-language": $fa-var-sign-language,
 "signing": $fa-var-signing,
 "hands-asl-interpreting": $fa-var-hands-asl-interpreting,
 "american-sign-language-interpreting": $fa-var-american-sign-language-interpreting,
 "asl-interpreting": $fa-var-asl-interpreting,
 "hands-american-sign-language-interpreting": $fa-var-hands-american-sign-language-interpreting,
 "hands-bound": $fa-var-hands-bound,
 "hands-bubbles": $fa-var-hands-bubbles,
 "hands-wash": $fa-var-hands-wash,
 "hands-clapping": $fa-var-hands-clapping,
 "hands-holding": $fa-var-hands-holding,
 "hands-holding-child": $fa-var-hands-holding-child,
 "hands-holding-circle": $fa-var-hands-holding-circle,
 "hands-praying": $fa-var-hands-praying,
 "praying-hands": $fa-var-praying-hands,
 "handshake": $fa-var-handshake,
 "handshake-angle": $fa-var-handshake-angle,
 "hands-helping": $fa-var-hands-helping,
 "handshake-simple": $fa-var-handshake-simple,
 "handshake-alt": $fa-var-handshake-alt,
 "handshake-simple-slash": $fa-var-handshake-simple-slash,
 "handshake-alt-slash": $fa-var-handshake-alt-slash,
 "handshake-slash": $fa-var-handshake-slash,
 "hanukiah": $fa-var-hanukiah,
 "hard-drive": $fa-var-hard-drive,
 "hdd": $fa-var-hdd,
 "hashtag": $fa-var-hashtag,
 "hat-cowboy": $fa-var-hat-cowboy,
 "hat-cowboy-side": $fa-var-hat-cowboy-side,
 "hat-wizard": $fa-var-hat-wizard,
 "head-side-cough": $fa-var-head-side-cough,
 "head-side-cough-slash": $fa-var-head-side-cough-slash,
 "head-side-mask": $fa-var-head-side-mask,
 "head-side-virus": $fa-var-head-side-virus,
 "heading": $fa-var-heading,
 "header": $fa-var-header,
 "headphones": $fa-var-headphones,
 "headphones-simple": $fa-var-headphones-simple,
 "headphones-alt": $fa-var-headphones-alt,
 "headset": $fa-var-headset,
 "heart": $fa-var-heart,
 "heart-circle-bolt": $fa-var-heart-circle-bolt,
 "heart-circle-check": $fa-var-heart-circle-check,
 "heart-circle-exclamation": $fa-var-heart-circle-exclamation,
 "heart-circle-minus": $fa-var-heart-circle-minus,
 "heart-circle-plus": $fa-var-heart-circle-plus,
 "heart-circle-xmark": $fa-var-heart-circle-xmark,
 "heart-crack": $fa-var-heart-crack,
 "heart-broken": $fa-var-heart-broken,
 "heart-pulse": $fa-var-heart-pulse,
 "heartbeat": $fa-var-heartbeat,
 "helicopter": $fa-var-helicopter,
 "helicopter-symbol": $fa-var-helicopter-symbol,
 "helmet-safety": $fa-var-helmet-safety,
 "hard-hat": $fa-var-hard-hat,
 "hat-hard": $fa-var-hat-hard,
 "helmet-un": $fa-var-helmet-un,
 "highlighter": $fa-var-highlighter,
 "hill-avalanche": $fa-var-hill-avalanche,
 "hill-rockslide": $fa-var-hill-rockslide,
 "hippo": $fa-var-hippo,
 "hockey-puck": $fa-var-hockey-puck,
 "holly-berry": $fa-var-holly-berry,
 "horse": $fa-var-horse,
 "horse-head": $fa-var-horse-head,
 "hospital": $fa-var-hospital,
 "hospital-alt": $fa-var-hospital-alt,
 "hospital-wide": $fa-var-hospital-wide,
 "hospital-user": $fa-var-hospital-user,
 "hot-tub-person": $fa-var-hot-tub-person,
 "hot-tub": $fa-var-hot-tub,
 "hotdog": $fa-var-hotdog,
 "hotel": $fa-var-hotel,
 "hourglass": $fa-var-hourglass,
 "hourglass-2": $fa-var-hourglass-2,
 "hourglass-half": $fa-var-hourglass-half,
 "hourglass-empty": $fa-var-hourglass-empty,
 "hourglass-end": $fa-var-hourglass-end,
 "hourglass-3": $fa-var-hourglass-3,
 "hourglass-start": $fa-var-hourglass-start,
 "hourglass-1": $fa-var-hourglass-1,
 "house": $fa-var-house,
 "home": $fa-var-home,
 "home-alt": $fa-var-home-alt,
 "home-lg-alt": $fa-var-home-lg-alt,
 "house-chimney": $fa-var-house-chimney,
 "home-lg": $fa-var-home-lg,
 "house-chimney-crack": $fa-var-house-chimney-crack,
 "house-damage": $fa-var-house-damage,
 "house-chimney-medical": $fa-var-house-chimney-medical,
 "clinic-medical": $fa-var-clinic-medical,
 "house-chimney-user": $fa-var-house-chimney-user,
 "house-chimney-window": $fa-var-house-chimney-window,
 "house-circle-check": $fa-var-house-circle-check,
 "house-circle-exclamation": $fa-var-house-circle-exclamation,
 "house-circle-xmark": $fa-var-house-circle-xmark,
 "house-crack": $fa-var-house-crack,
 "house-fire": $fa-var-house-fire,
 "house-flag": $fa-var-house-flag,
 "house-flood-water": $fa-var-house-flood-water,
 "house-flood-water-circle-arrow-right": $fa-var-house-flood-water-circle-arrow-right,
 "house-laptop": $fa-var-house-laptop,
 "laptop-house": $fa-var-laptop-house,
 "house-lock": $fa-var-house-lock,
 "house-medical": $fa-var-house-medical,
 "house-medical-circle-check": $fa-var-house-medical-circle-check,
 "house-medical-circle-exclamation": $fa-var-house-medical-circle-exclamation,
 "house-medical-circle-xmark": $fa-var-house-medical-circle-xmark,
 "house-medical-flag": $fa-var-house-medical-flag,
 "house-signal": $fa-var-house-signal,
 "house-tsunami": $fa-var-house-tsunami,
 "house-user": $fa-var-house-user,
 "home-user": $fa-var-home-user,
 "hryvnia-sign": $fa-var-hryvnia-sign,
 "hryvnia": $fa-var-hryvnia,
 "hurricane": $fa-var-hurricane,
 "i": $fa-var-i,
 "i-cursor": $fa-var-i-cursor,
 "ice-cream": $fa-var-ice-cream,
 "icicles": $fa-var-icicles,
 "icons": $fa-var-icons,
 "heart-music-camera-bolt": $fa-var-heart-music-camera-bolt,
 "id-badge": $fa-var-id-badge,
 "id-card": $fa-var-id-card,
 "drivers-license": $fa-var-drivers-license,
 "id-card-clip": $fa-var-id-card-clip,
 "id-card-alt": $fa-var-id-card-alt,
 "igloo": $fa-var-igloo,
 "image": $fa-var-image,
 "image-portrait": $fa-var-image-portrait,
 "portrait": $fa-var-portrait,
 "images": $fa-var-images,
 "inbox": $fa-var-inbox,
 "indent": $fa-var-indent,
 "indian-rupee-sign": $fa-var-indian-rupee-sign,
 "indian-rupee": $fa-var-indian-rupee,
 "inr": $fa-var-inr,
 "industry": $fa-var-industry,
 "infinity": $fa-var-infinity,
 "info": $fa-var-info,
 "italic": $fa-var-italic,
 "j": $fa-var-j,
 "jar": $fa-var-jar,
 "jar-wheat": $fa-var-jar-wheat,
 "jedi": $fa-var-jedi,
 "jet-fighter": $fa-var-jet-fighter,
 "fighter-jet": $fa-var-fighter-jet,
 "jet-fighter-up": $fa-var-jet-fighter-up,
 "joint": $fa-var-joint,
 "jug-detergent": $fa-var-jug-detergent,
 "k": $fa-var-k,
 "kaaba": $fa-var-kaaba,
 "key": $fa-var-key,
 "keyboard": $fa-var-keyboard,
 "khanda": $fa-var-khanda,
 "kip-sign": $fa-var-kip-sign,
 "kit-medical": $fa-var-kit-medical,
 "first-aid": $fa-var-first-aid,
 "kitchen-set": $fa-var-kitchen-set,
 "kiwi-bird": $fa-var-kiwi-bird,
 "l": $fa-var-l,
 "land-mine-on": $fa-var-land-mine-on,
 "landmark": $fa-var-landmark,
 "landmark-dome": $fa-var-landmark-dome,
 "landmark-alt": $fa-var-landmark-alt,
 "landmark-flag": $fa-var-landmark-flag,
 "language": $fa-var-language,
 "laptop": $fa-var-laptop,
 "laptop-code": $fa-var-laptop-code,
 "laptop-file": $fa-var-laptop-file,
 "laptop-medical": $fa-var-laptop-medical,
 "lari-sign": $fa-var-lari-sign,
 "layer-group": $fa-var-layer-group,
 "leaf": $fa-var-leaf,
 "left-long": $fa-var-left-long,
 "long-arrow-alt-left": $fa-var-long-arrow-alt-left,
 "left-right": $fa-var-left-right,
 "arrows-alt-h": $fa-var-arrows-alt-h,
 "lemon": $fa-var-lemon,
 "less-than": $fa-var-less-than,
 "less-than-equal": $fa-var-less-than-equal,
 "life-ring": $fa-var-life-ring,
 "lightbulb": $fa-var-lightbulb,
 "lines-leaning": $fa-var-lines-leaning,
 "link": $fa-var-link,
 "chain": $fa-var-chain,
 "link-slash": $fa-var-link-slash,
 "chain-broken": $fa-var-chain-broken,
 "chain-slash": $fa-var-chain-slash,
 "unlink": $fa-var-unlink,
 "lira-sign": $fa-var-lira-sign,
 "list": $fa-var-list,
 "list-squares": $fa-var-list-squares,
 "list-check": $fa-var-list-check,
 "tasks": $fa-var-tasks,
 "list-ol": $fa-var-list-ol,
 "list-1-2": $fa-var-list-1-2,
 "list-numeric": $fa-var-list-numeric,
 "list-ul": $fa-var-list-ul,
 "list-dots": $fa-var-list-dots,
 "litecoin-sign": $fa-var-litecoin-sign,
 "location-arrow": $fa-var-location-arrow,
 "location-crosshairs": $fa-var-location-crosshairs,
 "location": $fa-var-location,
 "location-dot": $fa-var-location-dot,
 "map-marker-alt": $fa-var-map-marker-alt,
 "location-pin": $fa-var-location-pin,
 "map-marker": $fa-var-map-marker,
 "location-pin-lock": $fa-var-location-pin-lock,
 "lock": $fa-var-lock,
 "lock-open": $fa-var-lock-open,
 "locust": $fa-var-locust,
 "lungs": $fa-var-lungs,
 "lungs-virus": $fa-var-lungs-virus,
 "m": $fa-var-m,
 "magnet": $fa-var-magnet,
 "magnifying-glass": $fa-var-magnifying-glass,
 "search": $fa-var-search,
 "magnifying-glass-arrow-right": $fa-var-magnifying-glass-arrow-right,
 "magnifying-glass-chart": $fa-var-magnifying-glass-chart,
 "magnifying-glass-dollar": $fa-var-magnifying-glass-dollar,
 "search-dollar": $fa-var-search-dollar,
 "magnifying-glass-location": $fa-var-magnifying-glass-location,
 "search-location": $fa-var-search-location,
 "magnifying-glass-minus": $fa-var-magnifying-glass-minus,
 "search-minus": $fa-var-search-minus,
 "magnifying-glass-plus": $fa-var-magnifying-glass-plus,
 "search-plus": $fa-var-search-plus,
 "manat-sign": $fa-var-manat-sign,
 "map": $fa-var-map,
 "map-location": $fa-var-map-location,
 "map-marked": $fa-var-map-marked,
 "map-location-dot": $fa-var-map-location-dot,
 "map-marked-alt": $fa-var-map-marked-alt,
 "map-pin": $fa-var-map-pin,
 "marker": $fa-var-marker,
 "mars": $fa-var-mars,
 "mars-and-venus": $fa-var-mars-and-venus,
 "mars-and-venus-burst": $fa-var-mars-and-venus-burst,
 "mars-double": $fa-var-mars-double,
 "mars-stroke": $fa-var-mars-stroke,
 "mars-stroke-right": $fa-var-mars-stroke-right,
 "mars-stroke-h": $fa-var-mars-stroke-h,
 "mars-stroke-up": $fa-var-mars-stroke-up,
 "mars-stroke-v": $fa-var-mars-stroke-v,
 "martini-glass": $fa-var-martini-glass,
 "glass-martini-alt": $fa-var-glass-martini-alt,
 "martini-glass-citrus": $fa-var-martini-glass-citrus,
 "cocktail": $fa-var-cocktail,
 "martini-glass-empty": $fa-var-martini-glass-empty,
 "glass-martini": $fa-var-glass-martini,
 "mask": $fa-var-mask,
 "mask-face": $fa-var-mask-face,
 "mask-ventilator": $fa-var-mask-ventilator,
 "masks-theater": $fa-var-masks-theater,
 "theater-masks": $fa-var-theater-masks,
 "mattress-pillow": $fa-var-mattress-pillow,
 "maximize": $fa-var-maximize,
 "expand-arrows-alt": $fa-var-expand-arrows-alt,
 "medal": $fa-var-medal,
 "memory": $fa-var-memory,
 "menorah": $fa-var-menorah,
 "mercury": $fa-var-mercury,
 "message": $fa-var-message,
 "comment-alt": $fa-var-comment-alt,
 "meteor": $fa-var-meteor,
 "microchip": $fa-var-microchip,
 "microphone": $fa-var-microphone,
 "microphone-lines": $fa-var-microphone-lines,
 "microphone-alt": $fa-var-microphone-alt,
 "microphone-lines-slash": $fa-var-microphone-lines-slash,
 "microphone-alt-slash": $fa-var-microphone-alt-slash,
 "microphone-slash": $fa-var-microphone-slash,
 "microscope": $fa-var-microscope,
 "mill-sign": $fa-var-mill-sign,
 "minimize": $fa-var-minimize,
 "compress-arrows-alt": $fa-var-compress-arrows-alt,
 "minus": $fa-var-minus,
 "subtract": $fa-var-subtract,
 "mitten": $fa-var-mitten,
 "mobile": $fa-var-mobile,
 "mobile-android": $fa-var-mobile-android,
 "mobile-phone": $fa-var-mobile-phone,
 "mobile-button": $fa-var-mobile-button,
 "mobile-retro": $fa-var-mobile-retro,
 "mobile-screen": $fa-var-mobile-screen,
 "mobile-android-alt": $fa-var-mobile-android-alt,
 "mobile-screen-button": $fa-var-mobile-screen-button,
 "mobile-alt": $fa-var-mobile-alt,
 "money-bill": $fa-var-money-bill,
 "money-bill-1": $fa-var-money-bill-1,
 "money-bill-alt": $fa-var-money-bill-alt,
 "money-bill-1-wave": $fa-var-money-bill-1-wave,
 "money-bill-wave-alt": $fa-var-money-bill-wave-alt,
 "money-bill-transfer": $fa-var-money-bill-transfer,
 "money-bill-trend-up": $fa-var-money-bill-trend-up,
 "money-bill-wave": $fa-var-money-bill-wave,
 "money-bill-wheat": $fa-var-money-bill-wheat,
 "money-bills": $fa-var-money-bills,
 "money-check": $fa-var-money-check,
 "money-check-dollar": $fa-var-money-check-dollar,
 "money-check-alt": $fa-var-money-check-alt,
 "monument": $fa-var-monument,
 "moon": $fa-var-moon,
 "mortar-pestle": $fa-var-mortar-pestle,
 "mosque": $fa-var-mosque,
 "mosquito": $fa-var-mosquito,
 "mosquito-net": $fa-var-mosquito-net,
 "motorcycle": $fa-var-motorcycle,
 "mound": $fa-var-mound,
 "mountain": $fa-var-mountain,
 "mountain-city": $fa-var-mountain-city,
 "mountain-sun": $fa-var-mountain-sun,
 "mug-hot": $fa-var-mug-hot,
 "mug-saucer": $fa-var-mug-saucer,
 "coffee": $fa-var-coffee,
 "music": $fa-var-music,
 "n": $fa-var-n,
 "naira-sign": $fa-var-naira-sign,
 "network-wired": $fa-var-network-wired,
 "neuter": $fa-var-neuter,
 "newspaper": $fa-var-newspaper,
 "not-equal": $fa-var-not-equal,
 "note-sticky": $fa-var-note-sticky,
 "sticky-note": $fa-var-sticky-note,
 "notes-medical": $fa-var-notes-medical,
 "o": $fa-var-o,
 "object-group": $fa-var-object-group,
 "object-ungroup": $fa-var-object-ungroup,
 "oil-can": $fa-var-oil-can,
 "oil-well": $fa-var-oil-well,
 "om": $fa-var-om,
 "otter": $fa-var-otter,
 "outdent": $fa-var-outdent,
 "dedent": $fa-var-dedent,
 "p": $fa-var-p,
 "pager": $fa-var-pager,
 "paint-roller": $fa-var-paint-roller,
 "paintbrush": $fa-var-paintbrush,
 "paint-brush": $fa-var-paint-brush,
 "palette": $fa-var-palette,
 "pallet": $fa-var-pallet,
 "panorama": $fa-var-panorama,
 "paper-plane": $fa-var-paper-plane,
 "paperclip": $fa-var-paperclip,
 "parachute-box": $fa-var-parachute-box,
 "paragraph": $fa-var-paragraph,
 "passport": $fa-var-passport,
 "paste": $fa-var-paste,
 "file-clipboard": $fa-var-file-clipboard,
 "pause": $fa-var-pause,
 "paw": $fa-var-paw,
 "peace": $fa-var-peace,
 "pen": $fa-var-pen,
 "pen-clip": $fa-var-pen-clip,
 "pen-alt": $fa-var-pen-alt,
 "pen-fancy": $fa-var-pen-fancy,
 "pen-nib": $fa-var-pen-nib,
 "pen-ruler": $fa-var-pen-ruler,
 "pencil-ruler": $fa-var-pencil-ruler,
 "pen-to-square": $fa-var-pen-to-square,
 "edit": $fa-var-edit,
 "pencil": $fa-var-pencil,
 "pencil-alt": $fa-var-pencil-alt,
 "people-arrows-left-right": $fa-var-people-arrows-left-right,
 "people-arrows": $fa-var-people-arrows,
 "people-carry-box": $fa-var-people-carry-box,
 "people-carry": $fa-var-people-carry,
 "people-group": $fa-var-people-group,
 "people-line": $fa-var-people-line,
 "people-pulling": $fa-var-people-pulling,
 "people-robbery": $fa-var-people-robbery,
 "people-roof": $fa-var-people-roof,
 "pepper-hot": $fa-var-pepper-hot,
 "percent": $fa-var-percent,
 "percentage": $fa-var-percentage,
 "person": $fa-var-person,
 "male": $fa-var-male,
 "person-arrow-down-to-line": $fa-var-person-arrow-down-to-line,
 "person-arrow-up-from-line": $fa-var-person-arrow-up-from-line,
 "person-biking": $fa-var-person-biking,
 "biking": $fa-var-biking,
 "person-booth": $fa-var-person-booth,
 "person-breastfeeding": $fa-var-person-breastfeeding,
 "person-burst": $fa-var-person-burst,
 "person-cane": $fa-var-person-cane,
 "person-chalkboard": $fa-var-person-chalkboard,
 "person-circle-check": $fa-var-person-circle-check,
 "person-circle-exclamation": $fa-var-person-circle-exclamation,
 "person-circle-minus": $fa-var-person-circle-minus,
 "person-circle-plus": $fa-var-person-circle-plus,
 "person-circle-question": $fa-var-person-circle-question,
 "person-circle-xmark": $fa-var-person-circle-xmark,
 "person-digging": $fa-var-person-digging,
 "digging": $fa-var-digging,
 "person-dots-from-line": $fa-var-person-dots-from-line,
 "diagnoses": $fa-var-diagnoses,
 "person-dress": $fa-var-person-dress,
 "female": $fa-var-female,
 "person-dress-burst": $fa-var-person-dress-burst,
 "person-drowning": $fa-var-person-drowning,
 "person-falling": $fa-var-person-falling,
 "person-falling-burst": $fa-var-person-falling-burst,
 "person-half-dress": $fa-var-person-half-dress,
 "person-harassing": $fa-var-person-harassing,
 "person-hiking": $fa-var-person-hiking,
 "hiking": $fa-var-hiking,
 "person-military-pointing": $fa-var-person-military-pointing,
 "person-military-rifle": $fa-var-person-military-rifle,
 "person-military-to-person": $fa-var-person-military-to-person,
 "person-praying": $fa-var-person-praying,
 "pray": $fa-var-pray,
 "person-pregnant": $fa-var-person-pregnant,
 "person-rays": $fa-var-person-rays,
 "person-rifle": $fa-var-person-rifle,
 "person-running": $fa-var-person-running,
 "running": $fa-var-running,
 "person-shelter": $fa-var-person-shelter,
 "person-skating": $fa-var-person-skating,
 "skating": $fa-var-skating,
 "person-skiing": $fa-var-person-skiing,
 "skiing": $fa-var-skiing,
 "person-skiing-nordic": $fa-var-person-skiing-nordic,
 "skiing-nordic": $fa-var-skiing-nordic,
 "person-snowboarding": $fa-var-person-snowboarding,
 "snowboarding": $fa-var-snowboarding,
 "person-swimming": $fa-var-person-swimming,
 "swimmer": $fa-var-swimmer,
 "person-through-window": $fa-var-person-through-window,
 "person-walking": $fa-var-person-walking,
 "walking": $fa-var-walking,
 "person-walking-arrow-loop-left": $fa-var-person-walking-arrow-loop-left,
 "person-walking-arrow-right": $fa-var-person-walking-arrow-right,
 "person-walking-dashed-line-arrow-right": $fa-var-person-walking-dashed-line-arrow-right,
 "person-walking-luggage": $fa-var-person-walking-luggage,
 "person-walking-with-cane": $fa-var-person-walking-with-cane,
 "blind": $fa-var-blind,
 "peseta-sign": $fa-var-peseta-sign,
 "peso-sign": $fa-var-peso-sign,
 "phone": $fa-var-phone,
 "phone-flip": $fa-var-phone-flip,
 "phone-alt": $fa-var-phone-alt,
 "phone-slash": $fa-var-phone-slash,
 "phone-volume": $fa-var-phone-volume,
 "volume-control-phone": $fa-var-volume-control-phone,
 "photo-film": $fa-var-photo-film,
 "photo-video": $fa-var-photo-video,
 "piggy-bank": $fa-var-piggy-bank,
 "pills": $fa-var-pills,
 "pizza-slice": $fa-var-pizza-slice,
 "place-of-worship": $fa-var-place-of-worship,
 "plane": $fa-var-plane,
 "plane-arrival": $fa-var-plane-arrival,
 "plane-circle-check": $fa-var-plane-circle-check,
 "plane-circle-exclamation": $fa-var-plane-circle-exclamation,
 "plane-circle-xmark": $fa-var-plane-circle-xmark,
 "plane-departure": $fa-var-plane-departure,
 "plane-lock": $fa-var-plane-lock,
 "plane-slash": $fa-var-plane-slash,
 "plane-up": $fa-var-plane-up,
 "plant-wilt": $fa-var-plant-wilt,
 "plate-wheat": $fa-var-plate-wheat,
 "play": $fa-var-play,
 "plug": $fa-var-plug,
 "plug-circle-bolt": $fa-var-plug-circle-bolt,
 "plug-circle-check": $fa-var-plug-circle-check,
 "plug-circle-exclamation": $fa-var-plug-circle-exclamation,
 "plug-circle-minus": $fa-var-plug-circle-minus,
 "plug-circle-plus": $fa-var-plug-circle-plus,
 "plug-circle-xmark": $fa-var-plug-circle-xmark,
 "plus": $fa-var-plus,
 "add": $fa-var-add,
 "plus-minus": $fa-var-plus-minus,
 "podcast": $fa-var-podcast,
 "poo": $fa-var-poo,
 "poo-storm": $fa-var-poo-storm,
 "poo-bolt": $fa-var-poo-bolt,
 "poop": $fa-var-poop,
 "power-off": $fa-var-power-off,
 "prescription": $fa-var-prescription,
 "prescription-bottle": $fa-var-prescription-bottle,
 "prescription-bottle-medical": $fa-var-prescription-bottle-medical,
 "prescription-bottle-alt": $fa-var-prescription-bottle-alt,
 "print": $fa-var-print,
 "pump-medical": $fa-var-pump-medical,
 "pump-soap": $fa-var-pump-soap,
 "puzzle-piece": $fa-var-puzzle-piece,
 "q": $fa-var-q,
 "qrcode": $fa-var-qrcode,
 "question": $fa-var-question,
 "quote-left": $fa-var-quote-left,
 "quote-left-alt": $fa-var-quote-left-alt,
 "quote-right": $fa-var-quote-right,
 "quote-right-alt": $fa-var-quote-right-alt,
 "r": $fa-var-r,
 "radiation": $fa-var-radiation,
 "radio": $fa-var-radio,
 "rainbow": $fa-var-rainbow,
 "ranking-star": $fa-var-ranking-star,
 "receipt": $fa-var-receipt,
 "record-vinyl": $fa-var-record-vinyl,
 "rectangle-ad": $fa-var-rectangle-ad,
 "ad": $fa-var-ad,
 "rectangle-list": $fa-var-rectangle-list,
 "list-alt": $fa-var-list-alt,
 "rectangle-xmark": $fa-var-rectangle-xmark,
 "rectangle-times": $fa-var-rectangle-times,
 "times-rectangle": $fa-var-times-rectangle,
 "window-close": $fa-var-window-close,
 "recycle": $fa-var-recycle,
 "registered": $fa-var-registered,
 "repeat": $fa-var-repeat,
 "reply": $fa-var-reply,
 "mail-reply": $fa-var-mail-reply,
 "reply-all": $fa-var-reply-all,
 "mail-reply-all": $fa-var-mail-reply-all,
 "republican": $fa-var-republican,
 "restroom": $fa-var-restroom,
 "retweet": $fa-var-retweet,
 "ribbon": $fa-var-ribbon,
 "right-from-bracket": $fa-var-right-from-bracket,
 "sign-out-alt": $fa-var-sign-out-alt,
 "right-left": $fa-var-right-left,
 "exchange-alt": $fa-var-exchange-alt,
 "right-long": $fa-var-right-long,
 "long-arrow-alt-right": $fa-var-long-arrow-alt-right,
 "right-to-bracket": $fa-var-right-to-bracket,
 "sign-in-alt": $fa-var-sign-in-alt,
 "ring": $fa-var-ring,
 "road": $fa-var-road,
 "road-barrier": $fa-var-road-barrier,
 "road-bridge": $fa-var-road-bridge,
 "road-circle-check": $fa-var-road-circle-check,
 "road-circle-exclamation": $fa-var-road-circle-exclamation,
 "road-circle-xmark": $fa-var-road-circle-xmark,
 "road-lock": $fa-var-road-lock,
 "road-spikes": $fa-var-road-spikes,
 "robot": $fa-var-robot,
 "rocket": $fa-var-rocket,
 "rotate": $fa-var-rotate,
 "sync-alt": $fa-var-sync-alt,
 "rotate-left": $fa-var-rotate-left,
 "rotate-back": $fa-var-rotate-back,
 "rotate-backward": $fa-var-rotate-backward,
 "undo-alt": $fa-var-undo-alt,
 "rotate-right": $fa-var-rotate-right,
 "redo-alt": $fa-var-redo-alt,
 "rotate-forward": $fa-var-rotate-forward,
 "route": $fa-var-route,
 "rss": $fa-var-rss,
 "feed": $fa-var-feed,
 "ruble-sign": $fa-var-ruble-sign,
 "rouble": $fa-var-rouble,
 "rub": $fa-var-rub,
 "ruble": $fa-var-ruble,
 "rug": $fa-var-rug,
 "ruler": $fa-var-ruler,
 "ruler-combined": $fa-var-ruler-combined,
 "ruler-horizontal": $fa-var-ruler-horizontal,
 "ruler-vertical": $fa-var-ruler-vertical,
 "rupee-sign": $fa-var-rupee-sign,
 "rupee": $fa-var-rupee,
 "rupiah-sign": $fa-var-rupiah-sign,
 "s": $fa-var-s,
 "sack-dollar": $fa-var-sack-dollar,
 "sack-xmark": $fa-var-sack-xmark,
 "sailboat": $fa-var-sailboat,
 "satellite": $fa-var-satellite,
 "satellite-dish": $fa-var-satellite-dish,
 "scale-balanced": $fa-var-scale-balanced,
 "balance-scale": $fa-var-balance-scale,
 "scale-unbalanced": $fa-var-scale-unbalanced,
 "balance-scale-left": $fa-var-balance-scale-left,
 "scale-unbalanced-flip": $fa-var-scale-unbalanced-flip,
 "balance-scale-right": $fa-var-balance-scale-right,
 "school": $fa-var-school,
 "school-circle-check": $fa-var-school-circle-check,
 "school-circle-exclamation": $fa-var-school-circle-exclamation,
 "school-circle-xmark": $fa-var-school-circle-xmark,
 "school-flag": $fa-var-school-flag,
 "school-lock": $fa-var-school-lock,
 "scissors": $fa-var-scissors,
 "cut": $fa-var-cut,
 "screwdriver": $fa-var-screwdriver,
 "screwdriver-wrench": $fa-var-screwdriver-wrench,
 "tools": $fa-var-tools,
 "scroll": $fa-var-scroll,
 "scroll-torah": $fa-var-scroll-torah,
 "torah": $fa-var-torah,
 "sd-card": $fa-var-sd-card,
 "section": $fa-var-section,
 "seedling": $fa-var-seedling,
 "sprout": $fa-var-sprout,
 "server": $fa-var-server,
 "shapes": $fa-var-shapes,
 "triangle-circle-square": $fa-var-triangle-circle-square,
 "share": $fa-var-share,
 "arrow-turn-right": $fa-var-arrow-turn-right,
 "mail-forward": $fa-var-mail-forward,
 "share-from-square": $fa-var-share-from-square,
 "share-square": $fa-var-share-square,
 "share-nodes": $fa-var-share-nodes,
 "share-alt": $fa-var-share-alt,
 "sheet-plastic": $fa-var-sheet-plastic,
 "shekel-sign": $fa-var-shekel-sign,
 "ils": $fa-var-ils,
 "shekel": $fa-var-shekel,
 "sheqel": $fa-var-sheqel,
 "sheqel-sign": $fa-var-sheqel-sign,
 "shield": $fa-var-shield,
 "shield-blank": $fa-var-shield-blank,
 "shield-cat": $fa-var-shield-cat,
 "shield-dog": $fa-var-shield-dog,
 "shield-halved": $fa-var-shield-halved,
 "shield-alt": $fa-var-shield-alt,
 "shield-heart": $fa-var-shield-heart,
 "shield-virus": $fa-var-shield-virus,
 "ship": $fa-var-ship,
 "shirt": $fa-var-shirt,
 "t-shirt": $fa-var-t-shirt,
 "tshirt": $fa-var-tshirt,
 "shoe-prints": $fa-var-shoe-prints,
 "shop": $fa-var-shop,
 "store-alt": $fa-var-store-alt,
 "shop-lock": $fa-var-shop-lock,
 "shop-slash": $fa-var-shop-slash,
 "store-alt-slash": $fa-var-store-alt-slash,
 "shower": $fa-var-shower,
 "shrimp": $fa-var-shrimp,
 "shuffle": $fa-var-shuffle,
 "random": $fa-var-random,
 "shuttle-space": $fa-var-shuttle-space,
 "space-shuttle": $fa-var-space-shuttle,
 "sign-hanging": $fa-var-sign-hanging,
 "sign": $fa-var-sign,
 "signal": $fa-var-signal,
 "signal-5": $fa-var-signal-5,
 "signal-perfect": $fa-var-signal-perfect,
 "signature": $fa-var-signature,
 "signs-post": $fa-var-signs-post,
 "map-signs": $fa-var-map-signs,
 "sim-card": $fa-var-sim-card,
 "sink": $fa-var-sink,
 "sitemap": $fa-var-sitemap,
 "skull": $fa-var-skull,
 "skull-crossbones": $fa-var-skull-crossbones,
 "slash": $fa-var-slash,
 "sleigh": $fa-var-sleigh,
 "sliders": $fa-var-sliders,
 "sliders-h": $fa-var-sliders-h,
 "smog": $fa-var-smog,
 "smoking": $fa-var-smoking,
 "snowflake": $fa-var-snowflake,
 "snowman": $fa-var-snowman,
 "snowplow": $fa-var-snowplow,
 "soap": $fa-var-soap,
 "socks": $fa-var-socks,
 "solar-panel": $fa-var-solar-panel,
 "sort": $fa-var-sort,
 "unsorted": $fa-var-unsorted,
 "sort-down": $fa-var-sort-down,
 "sort-desc": $fa-var-sort-desc,
 "sort-up": $fa-var-sort-up,
 "sort-asc": $fa-var-sort-asc,
 "spa": $fa-var-spa,
 "spaghetti-monster-flying": $fa-var-spaghetti-monster-flying,
 "pastafarianism": $fa-var-pastafarianism,
 "spell-check": $fa-var-spell-check,
 "spider": $fa-var-spider,
 "spinner": $fa-var-spinner,
 "splotch": $fa-var-splotch,
 "spoon": $fa-var-spoon,
 "utensil-spoon": $fa-var-utensil-spoon,
 "spray-can": $fa-var-spray-can,
 "spray-can-sparkles": $fa-var-spray-can-sparkles,
 "air-freshener": $fa-var-air-freshener,
 "square": $fa-var-square,
 "square-arrow-up-right": $fa-var-square-arrow-up-right,
 "external-link-square": $fa-var-external-link-square,
 "square-caret-down": $fa-var-square-caret-down,
 "caret-square-down": $fa-var-caret-square-down,
 "square-caret-left": $fa-var-square-caret-left,
 "caret-square-left": $fa-var-caret-square-left,
 "square-caret-right": $fa-var-square-caret-right,
 "caret-square-right": $fa-var-caret-square-right,
 "square-caret-up": $fa-var-square-caret-up,
 "caret-square-up": $fa-var-caret-square-up,
 "square-check": $fa-var-square-check,
 "check-square": $fa-var-check-square,
 "square-envelope": $fa-var-square-envelope,
 "envelope-square": $fa-var-envelope-square,
 "square-full": $fa-var-square-full,
 "square-h": $fa-var-square-h,
 "h-square": $fa-var-h-square,
 "square-minus": $fa-var-square-minus,
 "minus-square": $fa-var-minus-square,
 "square-nfi": $fa-var-square-nfi,
 "square-parking": $fa-var-square-parking,
 "parking": $fa-var-parking,
 "square-pen": $fa-var-square-pen,
 "pen-square": $fa-var-pen-square,
 "pencil-square": $fa-var-pencil-square,
 "square-person-confined": $fa-var-square-person-confined,
 "square-phone": $fa-var-square-phone,
 "phone-square": $fa-var-phone-square,
 "square-phone-flip": $fa-var-square-phone-flip,
 "phone-square-alt": $fa-var-phone-square-alt,
 "square-plus": $fa-var-square-plus,
 "plus-square": $fa-var-plus-square,
 "square-poll-horizontal": $fa-var-square-poll-horizontal,
 "poll-h": $fa-var-poll-h,
 "square-poll-vertical": $fa-var-square-poll-vertical,
 "poll": $fa-var-poll,
 "square-root-variable": $fa-var-square-root-variable,
 "square-root-alt": $fa-var-square-root-alt,
 "square-rss": $fa-var-square-rss,
 "rss-square": $fa-var-rss-square,
 "square-share-nodes": $fa-var-square-share-nodes,
 "share-alt-square": $fa-var-share-alt-square,
 "square-up-right": $fa-var-square-up-right,
 "external-link-square-alt": $fa-var-external-link-square-alt,
 "square-virus": $fa-var-square-virus,
 "square-xmark": $fa-var-square-xmark,
 "times-square": $fa-var-times-square,
 "xmark-square": $fa-var-xmark-square,
 "staff-aesculapius": $fa-var-staff-aesculapius,
 "rod-asclepius": $fa-var-rod-asclepius,
 "rod-snake": $fa-var-rod-snake,
 "staff-snake": $fa-var-staff-snake,
 "stairs": $fa-var-stairs,
 "stamp": $fa-var-stamp,
 "star": $fa-var-star,
 "star-and-crescent": $fa-var-star-and-crescent,
 "star-half": $fa-var-star-half,
 "star-half-stroke": $fa-var-star-half-stroke,
 "star-half-alt": $fa-var-star-half-alt,
 "star-of-david": $fa-var-star-of-david,
 "star-of-life": $fa-var-star-of-life,
 "sterling-sign": $fa-var-sterling-sign,
 "gbp": $fa-var-gbp,
 "pound-sign": $fa-var-pound-sign,
 "stethoscope": $fa-var-stethoscope,
 "stop": $fa-var-stop,
 "stopwatch": $fa-var-stopwatch,
 "stopwatch-20": $fa-var-stopwatch-20,
 "store": $fa-var-store,
 "store-slash": $fa-var-store-slash,
 "street-view": $fa-var-street-view,
 "strikethrough": $fa-var-strikethrough,
 "stroopwafel": $fa-var-stroopwafel,
 "subscript": $fa-var-subscript,
 "suitcase": $fa-var-suitcase,
 "suitcase-medical": $fa-var-suitcase-medical,
 "medkit": $fa-var-medkit,
 "suitcase-rolling": $fa-var-suitcase-rolling,
 "sun": $fa-var-sun,
 "sun-plant-wilt": $fa-var-sun-plant-wilt,
 "superscript": $fa-var-superscript,
 "swatchbook": $fa-var-swatchbook,
 "synagogue": $fa-var-synagogue,
 "syringe": $fa-var-syringe,
 "t": $fa-var-t,
 "table": $fa-var-table,
 "table-cells": $fa-var-table-cells,
 "th": $fa-var-th,
 "table-cells-large": $fa-var-table-cells-large,
 "th-large": $fa-var-th-large,
 "table-columns": $fa-var-table-columns,
 "columns": $fa-var-columns,
 "table-list": $fa-var-table-list,
 "th-list": $fa-var-th-list,
 "table-tennis-paddle-ball": $fa-var-table-tennis-paddle-ball,
 "ping-pong-paddle-ball": $fa-var-ping-pong-paddle-ball,
 "table-tennis": $fa-var-table-tennis,
 "tablet": $fa-var-tablet,
 "tablet-android": $fa-var-tablet-android,
 "tablet-button": $fa-var-tablet-button,
 "tablet-screen-button": $fa-var-tablet-screen-button,
 "tablet-alt": $fa-var-tablet-alt,
 "tablets": $fa-var-tablets,
 "tachograph-digital": $fa-var-tachograph-digital,
 "digital-tachograph": $fa-var-digital-tachograph,
 "tag": $fa-var-tag,
 "tags": $fa-var-tags,
 "tape": $fa-var-tape,
 "tarp": $fa-var-tarp,
 "tarp-droplet": $fa-var-tarp-droplet,
 "taxi": $fa-var-taxi,
 "cab": $fa-var-cab,
 "teeth": $fa-var-teeth,
 "teeth-open": $fa-var-teeth-open,
 "temperature-arrow-down": $fa-var-temperature-arrow-down,
 "temperature-down": $fa-var-temperature-down,
 "temperature-arrow-up": $fa-var-temperature-arrow-up,
 "temperature-up": $fa-var-temperature-up,
 "temperature-empty": $fa-var-temperature-empty,
 "temperature-0": $fa-var-temperature-0,
 "thermometer-0": $fa-var-thermometer-0,
 "thermometer-empty": $fa-var-thermometer-empty,
 "temperature-full": $fa-var-temperature-full,
 "temperature-4": $fa-var-temperature-4,
 "thermometer-4": $fa-var-thermometer-4,
 "thermometer-full": $fa-var-thermometer-full,
 "temperature-half": $fa-var-temperature-half,
 "temperature-2": $fa-var-temperature-2,
 "thermometer-2": $fa-var-thermometer-2,
 "thermometer-half": $fa-var-thermometer-half,
 "temperature-high": $fa-var-temperature-high,
 "temperature-low": $fa-var-temperature-low,
 "temperature-quarter": $fa-var-temperature-quarter,
 "temperature-1": $fa-var-temperature-1,
 "thermometer-1": $fa-var-thermometer-1,
 "thermometer-quarter": $fa-var-thermometer-quarter,
 "temperature-three-quarters": $fa-var-temperature-three-quarters,
 "temperature-3": $fa-var-temperature-3,
 "thermometer-3": $fa-var-thermometer-3,
 "thermometer-three-quarters": $fa-var-thermometer-three-quarters,
 "tenge-sign": $fa-var-tenge-sign,
 "tenge": $fa-var-tenge,
 "tent": $fa-var-tent,
 "tent-arrow-down-to-line": $fa-var-tent-arrow-down-to-line,
 "tent-arrow-left-right": $fa-var-tent-arrow-left-right,
 "tent-arrow-turn-left": $fa-var-tent-arrow-turn-left,
 "tent-arrows-down": $fa-var-tent-arrows-down,
 "tents": $fa-var-tents,
 "terminal": $fa-var-terminal,
 "text-height": $fa-var-text-height,
 "text-slash": $fa-var-text-slash,
 "remove-format": $fa-var-remove-format,
 "text-width": $fa-var-text-width,
 "thermometer": $fa-var-thermometer,
 "thumbs-down": $fa-var-thumbs-down,
 "thumbs-up": $fa-var-thumbs-up,
 "thumbtack": $fa-var-thumbtack,
 "thumb-tack": $fa-var-thumb-tack,
 "ticket": $fa-var-ticket,
 "ticket-simple": $fa-var-ticket-simple,
 "ticket-alt": $fa-var-ticket-alt,
 "timeline": $fa-var-timeline,
 "toggle-off": $fa-var-toggle-off,
 "toggle-on": $fa-var-toggle-on,
 "toilet": $fa-var-toilet,
 "toilet-paper": $fa-var-toilet-paper,
 "toilet-paper-slash": $fa-var-toilet-paper-slash,
 "toilet-portable": $fa-var-toilet-portable,
 "toilets-portable": $fa-var-toilets-portable,
 "toolbox": $fa-var-toolbox,
 "tooth": $fa-var-tooth,
 "torii-gate": $fa-var-torii-gate,
 "tornado": $fa-var-tornado,
 "tower-broadcast": $fa-var-tower-broadcast,
 "broadcast-tower": $fa-var-broadcast-tower,
 "tower-cell": $fa-var-tower-cell,
 "tower-observation": $fa-var-tower-observation,
 "tractor": $fa-var-tractor,
 "trademark": $fa-var-trademark,
 "traffic-light": $fa-var-traffic-light,
 "trailer": $fa-var-trailer,
 "train": $fa-var-train,
 "train-subway": $fa-var-train-subway,
 "subway": $fa-var-subway,
 "train-tram": $fa-var-train-tram,
 "tram": $fa-var-tram,
 "transgender": $fa-var-transgender,
 "transgender-alt": $fa-var-transgender-alt,
 "trash": $fa-var-trash,
 "trash-arrow-up": $fa-var-trash-arrow-up,
 "trash-restore": $fa-var-trash-restore,
 "trash-can": $fa-var-trash-can,
 "trash-alt": $fa-var-trash-alt,
 "trash-can-arrow-up": $fa-var-trash-can-arrow-up,
 "trash-restore-alt": $fa-var-trash-restore-alt,
 "tree": $fa-var-tree,
 "tree-city": $fa-var-tree-city,
 "triangle-exclamation": $fa-var-triangle-exclamation,
 "exclamation-triangle": $fa-var-exclamation-triangle,
 "warning": $fa-var-warning,
 "trophy": $fa-var-trophy,
 "trowel": $fa-var-trowel,
 "trowel-bricks": $fa-var-trowel-bricks,
 "truck": $fa-var-truck,
 "truck-arrow-right": $fa-var-truck-arrow-right,
 "truck-droplet": $fa-var-truck-droplet,
 "truck-fast": $fa-var-truck-fast,
 "shipping-fast": $fa-var-shipping-fast,
 "truck-field": $fa-var-truck-field,
 "truck-field-un": $fa-var-truck-field-un,
 "truck-front": $fa-var-truck-front,
 "truck-medical": $fa-var-truck-medical,
 "ambulance": $fa-var-ambulance,
 "truck-monster": $fa-var-truck-monster,
 "truck-moving": $fa-var-truck-moving,
 "truck-pickup": $fa-var-truck-pickup,
 "truck-plane": $fa-var-truck-plane,
 "truck-ramp-box": $fa-var-truck-ramp-box,
 "truck-loading": $fa-var-truck-loading,
 "tty": $fa-var-tty,
 "teletype": $fa-var-teletype,
 "turkish-lira-sign": $fa-var-turkish-lira-sign,
 "try": $fa-var-try,
 "turkish-lira": $fa-var-turkish-lira,
 "turn-down": $fa-var-turn-down,
 "level-down-alt": $fa-var-level-down-alt,
 "turn-up": $fa-var-turn-up,
 "level-up-alt": $fa-var-level-up-alt,
 "tv": $fa-var-tv,
 "television": $fa-var-television,
 "tv-alt": $fa-var-tv-alt,
 "u": $fa-var-u,
 "umbrella": $fa-var-umbrella,
 "umbrella-beach": $fa-var-umbrella-beach,
 "underline": $fa-var-underline,
 "universal-access": $fa-var-universal-access,
 "unlock": $fa-var-unlock,
 "unlock-keyhole": $fa-var-unlock-keyhole,
 "unlock-alt": $fa-var-unlock-alt,
 "up-down": $fa-var-up-down,
 "arrows-alt-v": $fa-var-arrows-alt-v,
 "up-down-left-right": $fa-var-up-down-left-right,
 "arrows-alt": $fa-var-arrows-alt,
 "up-long": $fa-var-up-long,
 "long-arrow-alt-up": $fa-var-long-arrow-alt-up,
 "up-right-and-down-left-from-center": $fa-var-up-right-and-down-left-from-center,
 "expand-alt": $fa-var-expand-alt,
 "up-right-from-square": $fa-var-up-right-from-square,
 "external-link-alt": $fa-var-external-link-alt,
 "upload": $fa-var-upload,
 "user": $fa-var-user,
 "user-astronaut": $fa-var-user-astronaut,
 "user-check": $fa-var-user-check,
 "user-clock": $fa-var-user-clock,
 "user-doctor": $fa-var-user-doctor,
 "user-md": $fa-var-user-md,
 "user-gear": $fa-var-user-gear,
 "user-cog": $fa-var-user-cog,
 "user-graduate": $fa-var-user-graduate,
 "user-group": $fa-var-user-group,
 "user-friends": $fa-var-user-friends,
 "user-injured": $fa-var-user-injured,
 "user-large": $fa-var-user-large,
 "user-alt": $fa-var-user-alt,
 "user-large-slash": $fa-var-user-large-slash,
 "user-alt-slash": $fa-var-user-alt-slash,
 "user-lock": $fa-var-user-lock,
 "user-minus": $fa-var-user-minus,
 "user-ninja": $fa-var-user-ninja,
 "user-nurse": $fa-var-user-nurse,
 "user-pen": $fa-var-user-pen,
 "user-edit": $fa-var-user-edit,
 "user-plus": $fa-var-user-plus,
 "user-secret": $fa-var-user-secret,
 "user-shield": $fa-var-user-shield,
 "user-slash": $fa-var-user-slash,
 "user-tag": $fa-var-user-tag,
 "user-tie": $fa-var-user-tie,
 "user-xmark": $fa-var-user-xmark,
 "user-times": $fa-var-user-times,
 "users": $fa-var-users,
 "users-between-lines": $fa-var-users-between-lines,
 "users-gear": $fa-var-users-gear,
 "users-cog": $fa-var-users-cog,
 "users-line": $fa-var-users-line,
 "users-rays": $fa-var-users-rays,
 "users-rectangle": $fa-var-users-rectangle,
 "users-slash": $fa-var-users-slash,
 "users-viewfinder": $fa-var-users-viewfinder,
 "utensils": $fa-var-utensils,
 "cutlery": $fa-var-cutlery,
 "v": $fa-var-v,
 "van-shuttle": $fa-var-van-shuttle,
 "shuttle-van": $fa-var-shuttle-van,
 "vault": $fa-var-vault,
 "vector-square": $fa-var-vector-square,
 "venus": $fa-var-venus,
 "venus-double": $fa-var-venus-double,
 "venus-mars": $fa-var-venus-mars,
 "vest": $fa-var-vest,
 "vest-patches": $fa-var-vest-patches,
 "vial": $fa-var-vial,
 "vial-circle-check": $fa-var-vial-circle-check,
 "vial-virus": $fa-var-vial-virus,
 "vials": $fa-var-vials,
 "video": $fa-var-video,
 "video-camera": $fa-var-video-camera,
 "video-slash": $fa-var-video-slash,
 "vihara": $fa-var-vihara,
 "virus": $fa-var-virus,
 "virus-covid": $fa-var-virus-covid,
 "virus-covid-slash": $fa-var-virus-covid-slash,
 "virus-slash": $fa-var-virus-slash,
 "viruses": $fa-var-viruses,
 "voicemail": $fa-var-voicemail,
 "volcano": $fa-var-volcano,
 "volleyball": $fa-var-volleyball,
 "volleyball-ball": $fa-var-volleyball-ball,
 "volume-high": $fa-var-volume-high,
 "volume-up": $fa-var-volume-up,
 "volume-low": $fa-var-volume-low,
 "volume-down": $fa-var-volume-down,
 "volume-off": $fa-var-volume-off,
 "volume-xmark": $fa-var-volume-xmark,
 "volume-mute": $fa-var-volume-mute,
 "volume-times": $fa-var-volume-times,
 "vr-cardboard": $fa-var-vr-cardboard,
 "w": $fa-var-w,
 "walkie-talkie": $fa-var-walkie-talkie,
 "wallet": $fa-var-wallet,
 "wand-magic": $fa-var-wand-magic,
 "magic": $fa-var-magic,
 "wand-magic-sparkles": $fa-var-wand-magic-sparkles,
 "magic-wand-sparkles": $fa-var-magic-wand-sparkles,
 "wand-sparkles": $fa-var-wand-sparkles,
 "warehouse": $fa-var-warehouse,
 "water": $fa-var-water,
 "water-ladder": $fa-var-water-ladder,
 "ladder-water": $fa-var-ladder-water,
 "swimming-pool": $fa-var-swimming-pool,
 "wave-square": $fa-var-wave-square,
 "weight-hanging": $fa-var-weight-hanging,
 "weight-scale": $fa-var-weight-scale,
 "weight": $fa-var-weight,
 "wheat-awn": $fa-var-wheat-awn,
 "wheat-alt": $fa-var-wheat-alt,
 "wheat-awn-circle-exclamation": $fa-var-wheat-awn-circle-exclamation,
 "wheelchair": $fa-var-wheelchair,
 "wheelchair-move": $fa-var-wheelchair-move,
 "wheelchair-alt": $fa-var-wheelchair-alt,
 "whiskey-glass": $fa-var-whiskey-glass,
 "glass-whiskey": $fa-var-glass-whiskey,
 "wifi": $fa-var-wifi,
 "wifi-3": $fa-var-wifi-3,
 "wifi-strong": $fa-var-wifi-strong,
 "wind": $fa-var-wind,
 "window-maximize": $fa-var-window-maximize,
 "window-minimize": $fa-var-window-minimize,
 "window-restore": $fa-var-window-restore,
 "wine-bottle": $fa-var-wine-bottle,
 "wine-glass": $fa-var-wine-glass,
 "wine-glass-empty": $fa-var-wine-glass-empty,
 "wine-glass-alt": $fa-var-wine-glass-alt,
 "won-sign": $fa-var-won-sign,
 "krw": $fa-var-krw,
 "won": $fa-var-won,
 "worm": $fa-var-worm,
 "wrench": $fa-var-wrench,
 "x": $fa-var-x,
 "x-ray": $fa-var-x-ray,
 "xmark": $fa-var-xmark,
 "close": $fa-var-close,
 "multiply": $fa-var-multiply,
 "remove": $fa-var-remove,
 "times": $fa-var-times,
 "xmarks-lines": $fa-var-xmarks-lines,
 "y": $fa-var-y,
 "yen-sign": $fa-var-yen-sign,
 "cny": $fa-var-cny,
 "jpy": $fa-var-jpy,
 "rmb": $fa-var-rmb,
 "yen": $fa-var-yen,
 "yin-yang": $fa-var-yin-yang,
 "z": $fa-var-z,
);

$fa-brand-icons: (
 "42-group": $fa-var-42-group,
 "innosoft": $fa-var-innosoft,
 "500px": $fa-var-500px,
 "accessible-icon": $fa-var-accessible-icon,
 "accusoft": $fa-var-accusoft,
 "adn": $fa-var-adn,
 "adversal": $fa-var-adversal,
 "affiliatetheme": $fa-var-affiliatetheme,
 "airbnb": $fa-var-airbnb,
 "algolia": $fa-var-algolia,
 "alipay": $fa-var-alipay,
 "amazon": $fa-var-amazon,
 "amazon-pay": $fa-var-amazon-pay,
 "amilia": $fa-var-amilia,
 "android": $fa-var-android,
 "angellist": $fa-var-angellist,
 "angrycreative": $fa-var-angrycreative,
 "angular": $fa-var-angular,
 "app-store": $fa-var-app-store,
 "app-store-ios": $fa-var-app-store-ios,
 "apper": $fa-var-apper,
 "apple": $fa-var-apple,
 "apple-pay": $fa-var-apple-pay,
 "artstation": $fa-var-artstation,
 "asymmetrik": $fa-var-asymmetrik,
 "atlassian": $fa-var-atlassian,
 "audible": $fa-var-audible,
 "autoprefixer": $fa-var-autoprefixer,
 "avianex": $fa-var-avianex,
 "aviato": $fa-var-aviato,
 "aws": $fa-var-aws,
 "bandcamp": $fa-var-bandcamp,
 "battle-net": $fa-var-battle-net,
 "behance": $fa-var-behance,
 "behance-square": $fa-var-behance-square,
 "bilibili": $fa-var-bilibili,
 "bimobject": $fa-var-bimobject,
 "bitbucket": $fa-var-bitbucket,
 "bitcoin": $fa-var-bitcoin,
 "bity": $fa-var-bity,
 "black-tie": $fa-var-black-tie,
 "blackberry": $fa-var-blackberry,
 "blogger": $fa-var-blogger,
 "blogger-b": $fa-var-blogger-b,
 "bluetooth": $fa-var-bluetooth,
 "bluetooth-b": $fa-var-bluetooth-b,
 "bootstrap": $fa-var-bootstrap,
 "bots": $fa-var-bots,
 "btc": $fa-var-btc,
 "buffer": $fa-var-buffer,
 "buromobelexperte": $fa-var-buromobelexperte,
 "buy-n-large": $fa-var-buy-n-large,
 "buysellads": $fa-var-buysellads,
 "canadian-maple-leaf": $fa-var-canadian-maple-leaf,
 "cc-amazon-pay": $fa-var-cc-amazon-pay,
 "cc-amex": $fa-var-cc-amex,
 "cc-apple-pay": $fa-var-cc-apple-pay,
 "cc-diners-club": $fa-var-cc-diners-club,
 "cc-discover": $fa-var-cc-discover,
 "cc-jcb": $fa-var-cc-jcb,
 "cc-mastercard": $fa-var-cc-mastercard,
 "cc-paypal": $fa-var-cc-paypal,
 "cc-stripe": $fa-var-cc-stripe,
 "cc-visa": $fa-var-cc-visa,
 "centercode": $fa-var-centercode,
 "centos": $fa-var-centos,
 "chrome": $fa-var-chrome,
 "chromecast": $fa-var-chromecast,
 "cloudflare": $fa-var-cloudflare,
 "cloudscale": $fa-var-cloudscale,
 "cloudsmith": $fa-var-cloudsmith,
 "cloudversify": $fa-var-cloudversify,
 "cmplid": $fa-var-cmplid,
 "codepen": $fa-var-codepen,
 "codiepie": $fa-var-codiepie,
 "confluence": $fa-var-confluence,
 "connectdevelop": $fa-var-connectdevelop,
 "contao": $fa-var-contao,
 "cotton-bureau": $fa-var-cotton-bureau,
 "cpanel": $fa-var-cpanel,
 "creative-commons": $fa-var-creative-commons,
 "creative-commons-by": $fa-var-creative-commons-by,
 "creative-commons-nc": $fa-var-creative-commons-nc,
 "creative-commons-nc-eu": $fa-var-creative-commons-nc-eu,
 "creative-commons-nc-jp": $fa-var-creative-commons-nc-jp,
 "creative-commons-nd": $fa-var-creative-commons-nd,
 "creative-commons-pd": $fa-var-creative-commons-pd,
 "creative-commons-pd-alt": $fa-var-creative-commons-pd-alt,
 "creative-commons-remix": $fa-var-creative-commons-remix,
 "creative-commons-sa": $fa-var-creative-commons-sa,
 "creative-commons-sampling": $fa-var-creative-commons-sampling,
 "creative-commons-sampling-plus": $fa-var-creative-commons-sampling-plus,
 "creative-commons-share": $fa-var-creative-commons-share,
 "creative-commons-zero": $fa-var-creative-commons-zero,
 "critical-role": $fa-var-critical-role,
 "css3": $fa-var-css3,
 "css3-alt": $fa-var-css3-alt,
 "cuttlefish": $fa-var-cuttlefish,
 "d-and-d": $fa-var-d-and-d,
 "d-and-d-beyond": $fa-var-d-and-d-beyond,
 "dailymotion": $fa-var-dailymotion,
 "dashcube": $fa-var-dashcube,
 "deezer": $fa-var-deezer,
 "delicious": $fa-var-delicious,
 "deploydog": $fa-var-deploydog,
 "deskpro": $fa-var-deskpro,
 "dev": $fa-var-dev,
 "deviantart": $fa-var-deviantart,
 "dhl": $fa-var-dhl,
 "diaspora": $fa-var-diaspora,
 "digg": $fa-var-digg,
 "digital-ocean": $fa-var-digital-ocean,
 "discord": $fa-var-discord,
 "discourse": $fa-var-discourse,
 "dochub": $fa-var-dochub,
 "docker": $fa-var-docker,
 "draft2digital": $fa-var-draft2digital,
 "dribbble": $fa-var-dribbble,
 "dribbble-square": $fa-var-dribbble-square,
 "dropbox": $fa-var-dropbox,
 "drupal": $fa-var-drupal,
 "dyalog": $fa-var-dyalog,
 "earlybirds": $fa-var-earlybirds,
 "ebay": $fa-var-ebay,
 "edge": $fa-var-edge,
 "edge-legacy": $fa-var-edge-legacy,
 "elementor": $fa-var-elementor,
 "ello": $fa-var-ello,
 "ember": $fa-var-ember,
 "empire": $fa-var-empire,
 "envira": $fa-var-envira,
 "erlang": $fa-var-erlang,
 "ethereum": $fa-var-ethereum,
 "etsy": $fa-var-etsy,
 "evernote": $fa-var-evernote,
 "expeditedssl": $fa-var-expeditedssl,
 "facebook": $fa-var-facebook,
 "facebook-f": $fa-var-facebook-f,
 "facebook-messenger": $fa-var-facebook-messenger,
 "facebook-square": $fa-var-facebook-square,
 "fantasy-flight-games": $fa-var-fantasy-flight-games,
 "fedex": $fa-var-fedex,
 "fedora": $fa-var-fedora,
 "figma": $fa-var-figma,
 "firefox": $fa-var-firefox,
 "firefox-browser": $fa-var-firefox-browser,
 "first-order": $fa-var-first-order,
 "first-order-alt": $fa-var-first-order-alt,
 "firstdraft": $fa-var-firstdraft,
 "flickr": $fa-var-flickr,
 "flipboard": $fa-var-flipboard,
 "fly": $fa-var-fly,
 "font-awesome": $fa-var-font-awesome,
 "font-awesome-flag": $fa-var-font-awesome-flag,
 "font-awesome-logo-full": $fa-var-font-awesome-logo-full,
 "fonticons": $fa-var-fonticons,
 "fonticons-fi": $fa-var-fonticons-fi,
 "fort-awesome": $fa-var-fort-awesome,
 "fort-awesome-alt": $fa-var-fort-awesome-alt,
 "forumbee": $fa-var-forumbee,
 "foursquare": $fa-var-foursquare,
 "free-code-camp": $fa-var-free-code-camp,
 "freebsd": $fa-var-freebsd,
 "fulcrum": $fa-var-fulcrum,
 "galactic-republic": $fa-var-galactic-republic,
 "galactic-senate": $fa-var-galactic-senate,
 "get-pocket": $fa-var-get-pocket,
 "gg": $fa-var-gg,
 "gg-circle": $fa-var-gg-circle,
 "git": $fa-var-git,
 "git-alt": $fa-var-git-alt,
 "git-square": $fa-var-git-square,
 "github": $fa-var-github,
 "github-alt": $fa-var-github-alt,
 "github-square": $fa-var-github-square,
 "gitkraken": $fa-var-gitkraken,
 "gitlab": $fa-var-gitlab,
 "gitter": $fa-var-gitter,
 "glide": $fa-var-glide,
 "glide-g": $fa-var-glide-g,
 "gofore": $fa-var-gofore,
 "golang": $fa-var-golang,
 "goodreads": $fa-var-goodreads,
 "goodreads-g": $fa-var-goodreads-g,
 "google": $fa-var-google,
 "google-drive": $fa-var-google-drive,
 "google-pay": $fa-var-google-pay,
 "google-play": $fa-var-google-play,
 "google-plus": $fa-var-google-plus,
 "google-plus-g": $fa-var-google-plus-g,
 "google-plus-square": $fa-var-google-plus-square,
 "google-wallet": $fa-var-google-wallet,
 "gratipay": $fa-var-gratipay,
 "grav": $fa-var-grav,
 "gripfire": $fa-var-gripfire,
 "grunt": $fa-var-grunt,
 "guilded": $fa-var-guilded,
 "gulp": $fa-var-gulp,
 "hacker-news": $fa-var-hacker-news,
 "hacker-news-square": $fa-var-hacker-news-square,
 "hackerrank": $fa-var-hackerrank,
 "hashnode": $fa-var-hashnode,
 "hips": $fa-var-hips,
 "hire-a-helper": $fa-var-hire-a-helper,
 "hive": $fa-var-hive,
 "hooli": $fa-var-hooli,
 "hornbill": $fa-var-hornbill,
 "hotjar": $fa-var-hotjar,
 "houzz": $fa-var-houzz,
 "html5": $fa-var-html5,
 "hubspot": $fa-var-hubspot,
 "ideal": $fa-var-ideal,
 "imdb": $fa-var-imdb,
 "instagram": $fa-var-instagram,
 "instagram-square": $fa-var-instagram-square,
 "instalod": $fa-var-instalod,
 "intercom": $fa-var-intercom,
 "internet-explorer": $fa-var-internet-explorer,
 "invision": $fa-var-invision,
 "ioxhost": $fa-var-ioxhost,
 "itch-io": $fa-var-itch-io,
 "itunes": $fa-var-itunes,
 "itunes-note": $fa-var-itunes-note,
 "java": $fa-var-java,
 "jedi-order": $fa-var-jedi-order,
 "jenkins": $fa-var-jenkins,
 "jira": $fa-var-jira,
 "joget": $fa-var-joget,
 "joomla": $fa-var-joomla,
 "js": $fa-var-js,
 "js-square": $fa-var-js-square,
 "jsfiddle": $fa-var-jsfiddle,
 "kaggle": $fa-var-kaggle,
 "keybase": $fa-var-keybase,
 "keycdn": $fa-var-keycdn,
 "kickstarter": $fa-var-kickstarter,
 "kickstarter-k": $fa-var-kickstarter-k,
 "korvue": $fa-var-korvue,
 "laravel": $fa-var-laravel,
 "lastfm": $fa-var-lastfm,
 "lastfm-square": $fa-var-lastfm-square,
 "leanpub": $fa-var-leanpub,
 "less": $fa-var-less,
 "line": $fa-var-line,
 "linkedin": $fa-var-linkedin,
 "linkedin-in": $fa-var-linkedin-in,
 "linode": $fa-var-linode,
 "linux": $fa-var-linux,
 "lyft": $fa-var-lyft,
 "magento": $fa-var-magento,
 "mailchimp": $fa-var-mailchimp,
 "mandalorian": $fa-var-mandalorian,
 "markdown": $fa-var-markdown,
 "mastodon": $fa-var-mastodon,
 "maxcdn": $fa-var-maxcdn,
 "mdb": $fa-var-mdb,
 "medapps": $fa-var-medapps,
 "medium": $fa-var-medium,
 "medium-m": $fa-var-medium-m,
 "medrt": $fa-var-medrt,
 "meetup": $fa-var-meetup,
 "megaport": $fa-var-megaport,
 "mendeley": $fa-var-mendeley,
 "microblog": $fa-var-microblog,
 "microsoft": $fa-var-microsoft,
 "mix": $fa-var-mix,
 "mixcloud": $fa-var-mixcloud,
 "mixer": $fa-var-mixer,
 "mizuni": $fa-var-mizuni,
 "modx": $fa-var-modx,
 "monero": $fa-var-monero,
 "napster": $fa-var-napster,
 "neos": $fa-var-neos,
 "nfc-directional": $fa-var-nfc-directional,
 "nfc-symbol": $fa-var-nfc-symbol,
 "nimblr": $fa-var-nimblr,
 "node": $fa-var-node,
 "node-js": $fa-var-node-js,
 "npm": $fa-var-npm,
 "ns8": $fa-var-ns8,
 "nutritionix": $fa-var-nutritionix,
 "octopus-deploy": $fa-var-octopus-deploy,
 "odnoklassniki": $fa-var-odnoklassniki,
 "odnoklassniki-square": $fa-var-odnoklassniki-square,
 "old-republic": $fa-var-old-republic,
 "opencart": $fa-var-opencart,
 "openid": $fa-var-openid,
 "opera": $fa-var-opera,
 "optin-monster": $fa-var-optin-monster,
 "orcid": $fa-var-orcid,
 "osi": $fa-var-osi,
 "padlet": $fa-var-padlet,
 "page4": $fa-var-page4,
 "pagelines": $fa-var-pagelines,
 "palfed": $fa-var-palfed,
 "patreon": $fa-var-patreon,
 "paypal": $fa-var-paypal,
 "perbyte": $fa-var-perbyte,
 "periscope": $fa-var-periscope,
 "phabricator": $fa-var-phabricator,
 "phoenix-framework": $fa-var-phoenix-framework,
 "phoenix-squadron": $fa-var-phoenix-squadron,
 "php": $fa-var-php,
 "pied-piper": $fa-var-pied-piper,
 "pied-piper-alt": $fa-var-pied-piper-alt,
 "pied-piper-hat": $fa-var-pied-piper-hat,
 "pied-piper-pp": $fa-var-pied-piper-pp,
 "pied-piper-square": $fa-var-pied-piper-square,
 "pinterest": $fa-var-pinterest,
 "pinterest-p": $fa-var-pinterest-p,
 "pinterest-square": $fa-var-pinterest-square,
 "pix": $fa-var-pix,
 "playstation": $fa-var-playstation,
 "product-hunt": $fa-var-product-hunt,
 "pushed": $fa-var-pushed,
 "python": $fa-var-python,
 "qq": $fa-var-qq,
 "quinscape": $fa-var-quinscape,
 "quora": $fa-var-quora,
 "r-project": $fa-var-r-project,
 "raspberry-pi": $fa-var-raspberry-pi,
 "ravelry": $fa-var-ravelry,
 "react": $fa-var-react,
 "reacteurope": $fa-var-reacteurope,
 "readme": $fa-var-readme,
 "rebel": $fa-var-rebel,
 "red-river": $fa-var-red-river,
 "reddit": $fa-var-reddit,
 "reddit-alien": $fa-var-reddit-alien,
 "reddit-square": $fa-var-reddit-square,
 "redhat": $fa-var-redhat,
 "renren": $fa-var-renren,
 "replyd": $fa-var-replyd,
 "researchgate": $fa-var-researchgate,
 "resolving": $fa-var-resolving,
 "rev": $fa-var-rev,
 "rocketchat": $fa-var-rocketchat,
 "rockrms": $fa-var-rockrms,
 "rust": $fa-var-rust,
 "safari": $fa-var-safari,
 "salesforce": $fa-var-salesforce,
 "sass": $fa-var-sass,
 "schlix": $fa-var-schlix,
 "screenpal": $fa-var-screenpal,
 "scribd": $fa-var-scribd,
 "searchengin": $fa-var-searchengin,
 "sellcast": $fa-var-sellcast,
 "sellsy": $fa-var-sellsy,
 "servicestack": $fa-var-servicestack,
 "shirtsinbulk": $fa-var-shirtsinbulk,
 "shopify": $fa-var-shopify,
 "shopware": $fa-var-shopware,
 "simplybuilt": $fa-var-simplybuilt,
 "sistrix": $fa-var-sistrix,
 "sith": $fa-var-sith,
 "sitrox": $fa-var-sitrox,
 "sketch": $fa-var-sketch,
 "skyatlas": $fa-var-skyatlas,
 "skype": $fa-var-skype,
 "slack": $fa-var-slack,
 "slack-hash": $fa-var-slack-hash,
 "slideshare": $fa-var-slideshare,
 "snapchat": $fa-var-snapchat,
 "snapchat-ghost": $fa-var-snapchat-ghost,
 "snapchat-square": $fa-var-snapchat-square,
 "soundcloud": $fa-var-soundcloud,
 "sourcetree": $fa-var-sourcetree,
 "speakap": $fa-var-speakap,
 "speaker-deck": $fa-var-speaker-deck,
 "spotify": $fa-var-spotify,
 "square-font-awesome": $fa-var-square-font-awesome,
 "square-font-awesome-stroke": $fa-var-square-font-awesome-stroke,
 "font-awesome-alt": $fa-var-font-awesome-alt,
 "squarespace": $fa-var-squarespace,
 "stack-exchange": $fa-var-stack-exchange,
 "stack-overflow": $fa-var-stack-overflow,
 "stackpath": $fa-var-stackpath,
 "staylinked": $fa-var-staylinked,
 "steam": $fa-var-steam,
 "steam-square": $fa-var-steam-square,
 "steam-symbol": $fa-var-steam-symbol,
 "sticker-mule": $fa-var-sticker-mule,
 "strava": $fa-var-strava,
 "stripe": $fa-var-stripe,
 "stripe-s": $fa-var-stripe-s,
 "studiovinari": $fa-var-studiovinari,
 "stumbleupon": $fa-var-stumbleupon,
 "stumbleupon-circle": $fa-var-stumbleupon-circle,
 "superpowers": $fa-var-superpowers,
 "supple": $fa-var-supple,
 "suse": $fa-var-suse,
 "swift": $fa-var-swift,
 "symfony": $fa-var-symfony,
 "teamspeak": $fa-var-teamspeak,
 "telegram": $fa-var-telegram,
 "telegram-plane": $fa-var-telegram-plane,
 "tencent-weibo": $fa-var-tencent-weibo,
 "the-red-yeti": $fa-var-the-red-yeti,
 "themeco": $fa-var-themeco,
 "themeisle": $fa-var-themeisle,
 "think-peaks": $fa-var-think-peaks,
 "tiktok": $fa-var-tiktok,
 "trade-federation": $fa-var-trade-federation,
 "trello": $fa-var-trello,
 "tumblr": $fa-var-tumblr,
 "tumblr-square": $fa-var-tumblr-square,
 "twitch": $fa-var-twitch,
 "twitter": $fa-var-twitter,
 "twitter-square": $fa-var-twitter-square,
 "typo3": $fa-var-typo3,
 "uber": $fa-var-uber,
 "ubuntu": $fa-var-ubuntu,
 "uikit": $fa-var-uikit,
 "umbraco": $fa-var-umbraco,
 "uncharted": $fa-var-uncharted,
 "uniregistry": $fa-var-uniregistry,
 "unity": $fa-var-unity,
 "unsplash": $fa-var-unsplash,
 "untappd": $fa-var-untappd,
 "ups": $fa-var-ups,
 "usb": $fa-var-usb,
 "usps": $fa-var-usps,
 "ussunnah": $fa-var-ussunnah,
 "vaadin": $fa-var-vaadin,
 "viacoin": $fa-var-viacoin,
 "viadeo": $fa-var-viadeo,
 "viadeo-square": $fa-var-viadeo-square,
 "viber": $fa-var-viber,
 "vimeo": $fa-var-vimeo,
 "vimeo-square": $fa-var-vimeo-square,
 "vimeo-v": $fa-var-vimeo-v,
 "vine": $fa-var-vine,
 "vk": $fa-var-vk,
 "vnv": $fa-var-vnv,
 "vuejs": $fa-var-vuejs,
 "watchman-monitoring": $fa-var-watchman-monitoring,
 "waze": $fa-var-waze,
 "weebly": $fa-var-weebly,
 "weibo": $fa-var-weibo,
 "weixin": $fa-var-weixin,
 "whatsapp": $fa-var-whatsapp,
 "whatsapp-square": $fa-var-whatsapp-square,
 "whmcs": $fa-var-whmcs,
 "wikipedia-w": $fa-var-wikipedia-w,
 "windows": $fa-var-windows,
 "wirsindhandwerk": $fa-var-wirsindhandwerk,
 "wsh": $fa-var-wsh,
 "wix": $fa-var-wix,
 "wizards-of-the-coast": $fa-var-wizards-of-the-coast,
 "wodu": $fa-var-wodu,
 "wolf-pack-battalion": $fa-var-wolf-pack-battalion,
 "wordpress": $fa-var-wordpress,
 "wordpress-simple": $fa-var-wordpress-simple,
 "wpbeginner": $fa-var-wpbeginner,
 "wpexplorer": $fa-var-wpexplorer,
 "wpforms": $fa-var-wpforms,
 "wpressr": $fa-var-wpressr,
 "xbox": $fa-var-xbox,
 "xing": $fa-var-xing,
 "xing-square": $fa-var-xing-square,
 "y-combinator": $fa-var-y-combinator,
 "yahoo": $fa-var-yahoo,
 "yammer": $fa-var-yammer,
 "yandex": $fa-var-yandex,
 "yandex-international": $fa-var-yandex-international,
 "yarn": $fa-var-yarn,
 "yelp": $fa-var-yelp,
 "yoast": $fa-var-yoast,
 "youtube": $fa-var-youtube,
 "youtube-square": $fa-var-youtube-square,
 "zhihu": $fa-var-zhihu,
);
