.card-panels {
 &.card-panels-portrait {
 padding-top: var(--section-padding);
 padding-bottom: var(--section-padding);

 @include media-breakpoint-up(md) {
 padding-top: calc(var(--section-padding) * 2);
 padding-bottom: calc(var(--section-padding) * 2);
 }
 }

 .container {
 padding-left: 0;
 padding-right: 0;
 }
}

.card {
 &.card-panel {
 .card-thumbnail {
 img {
 aspect-ratio: auto;
 margin-top: auto;
 margin-bottom: auto;
 }
 }
 }
}